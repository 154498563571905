import React, { useState, useEffect } from "react";
import * as moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import UserCard from "components/Card/UserCard";
import Card from "components/Card/Card.jsx";
import { css } from "@emotion/core";
import BarLoader from "react-spinners/BarLoader";
import { NotificationManager } from "react-notifications";
import PerfilUsuarioStyle from "assets/css/views/PerfilUsuario";
import { Redirect } from "react-router-dom";
import {
  Grid,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Row,
  Modal,
  Nav,
  MenuItem,
  NavDropdown,
  ModalBody,
} from "react-bootstrap";
import AsyncSelect from "react-select/async";
import Switch from "react-bootstrap-switch";
import Select from "react-select";
import Button from "components/CustomButton/CustomButton.jsx";
import ButtonD from "./Components/ButtonDefault";
import { Editor } from "@tinymce/tinymce-react";
import tinymce from "tinymce";
import history from "../history";
import {
  optionsUserFunction,
  optionsUserCharge,
  optionsUserRemuneration,
  optionsUserHiring,
  defaultPermissions,
  optionStatus,
} from "./OptionsData";

import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";
import "firebase/functions";

const styles = {
  permSwitch: {
    marginLeft: "3px",
    display: "inline-block",
  },
  gridAlignC: {
    display: "grid",
    alignItems: "center",
    gridTemplateColumns: "89% 25%",
  },
  campoDiv: {
    display: "flex",
    alignItems: "baseline",
  },
  campoReal: {
    paddingRight: "10px",
  },
  campoSelectStatus: {
    padding: "10px",
  },
};

const PerfilUsuario = (props) => {
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  const dispatch = useDispatch();

  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [editForm, setEditForm] = useState(false);
  const [userPass, setUserPass] = useState();
  const [userPassError, setPassError] = useState();
  const [cfPass, setCfPass] = useState();
  const [cfpasswordError, setCfpasswordError] = useState();
  const [userEmail, setUserEmail] = useState();
  const [userName, setUserName] = useState();
  const [userLastName, setLastName] = useState();
  const [userSignature, setUserSignature] = useState();
  const [showEditPass, setShowEditPass] = useState(false);
  const [saveChanges, setSaveChanges] = useState(false);
  const [showUserPermissions, setShowUserPermissions] = useState(false);
  const [userNameError, setUserNameError] = useState();
  const [redirectList, setRedirectList] = useState(false);
  const [anotherUserFunctionType, setAnotherUserFunctionType] = useState(false);
  const [newUserFunctionType, setNewUserFunctionType] = useState();
  const [anotherUserCharge, setAnotherUserCharge] = useState(false);
  const [newUserCharge, setNewUserCharge] = useState();
  const [anotherRemuneration, setAnotherRemuneration] = useState(false);
  const [newUserRemuneration, setNewUserRemuneration] = useState();
  const [viewUserRemuneration, setViewUserRemuneration] = useState(false);
  const [anotherHiring, setAnotherHiring] = useState(false);
  const [newUserHiring, setNewUserHiring] = useState();
  const [viewUserHiring, setViewUserHiring] = useState(false);
  const [defaultSiteActing, setDefaultSiteActing] = useState(false);
  const [anotherSiteActing, setAnotherSiteActing] = useState(false);
  const [newUserSiteActing, setNewUserSiteActing] = useState();
  const [newUserPermissions, setNewUserPermissions] = useState();
  const [changeConfigs, setChangeConfigs] = useState(false);
  const [typeLocutor, setTypeLocutor] = useState(false);
  const [valorMoeda, setValorMoeda] = useState();
  const [showInputNewStatus, setShowInputNewStatus] = useState(false);
  const [newUserStatus, setNewUserStatus] = useState();
  const [showUserStatus, setShowUserStatus] = useState(false);
  const [changeUserStatus, setChangeUserStatus] = useState(false);

  const userTypeStore = useSelector((state) => state.userType);
  const userChargeStore = useSelector((state) => state.userCharge);
  const userPermissionsStore = useSelector((state) => state.userPermissions);

  const userRef = firebase.firestore().collection("users");
  const avatarRef = firebase.storage().ref();

  const handleShowUserPermissions = () => {
    setShowUserPermissions(true);
  };
  const handleCloseUserPermissions = () => setShowUserPermissions(false);

  const exibirCampoPersonalizado = (event) => {
    if (event.value === "Personalizado") {
      setShowInputNewStatus(true);
    } else {
      setNewUserStatus(event.value);
      setShowInputNewStatus(false);
    }
  };

  const handleShowUserStatus = () => {
    setShowUserStatus(true);
  };
  const handleCloseUserStatus = () => setShowUserStatus(false);

  useEffect(() => {
    if (!userTypeStore) return;
    if (typeof userTypeStore === "object") {
      if (userTypeStore.hasOwnProperty("another")) {
        if (userTypeStore.another.value === "Administrador") {
          setChangeConfigs(true);
        }
      }
      if (userTypeStore.hasOwnProperty("default")) {
        if (userTypeStore.default.value === "Administrador") {
          setChangeConfigs(true);
        }
      }
    }
  }, [userTypeStore]);

  useEffect(() => {
    if (!userChargeStore) return;
    if (typeof userChargeStore === "object") {
      if (userChargeStore.hasOwnProperty("another")) {
        if (userChargeStore.another.value === "Locutor") {
          setChangeUserStatus(true);
        }
      }
      if (userChargeStore.hasOwnProperty("default")) {
        if (userChargeStore.default.value === "Locutor") {
          setChangeUserStatus(true);
        }
      }
    }
  }, [userTypeStore]);

  useEffect(() => {
    userRef.doc(props.match.params.id).onSnapshot(
      (doc) => {
        if (doc.exists) {
          setLoading(false);
          setUserData({ ...doc.data(), id: doc.id });
        } else {
          setRedirectList(true);
        }
      },
      () => {
        setLoading(true);
        NotificationManager.error("Não foi possível encontrar o usuário!");
      },
      () => {
        setRedirectList(true);
      }
    );
  }, [firebase, props.match.params.id]);

  useEffect(() => {
    if (!userData) return;
    if (typeof userData.funcao === "object") {
      if (userData.funcao.hasOwnProperty("another")) {
        setAnotherUserFunctionType(true);
        setNewUserFunctionType(userData.funcao.another);
      }
      if (userData.funcao.hasOwnProperty("default")) {
        setNewUserFunctionType(userData.funcao.default);
      }
    }
    if (typeof userData.cargo === "object") {
      if (userData.cargo.hasOwnProperty("another")) {
        setAnotherUserCharge(true);
        setNewUserCharge(userData.cargo.another);
      }
      if (userData.cargo.hasOwnProperty("default")) {
        setNewUserCharge(userData.cargo.default);
      }
    }
    if (typeof userData.tipo_remuneracao === "object") {
      if (userData.tipo_remuneracao.hasOwnProperty("another")) {
        setAnotherRemuneration(true);
        setNewUserRemuneration(userData.tipo_remuneracao.another);
      }
      if (userData.tipo_remuneracao.hasOwnProperty("default")) {
        setNewUserRemuneration(userData.tipo_remuneracao.default);
      }
    }
    if (typeof userData.tipo_contratacao === "object") {
      if (userData.tipo_contratacao.hasOwnProperty("another")) {
        setAnotherHiring(true);
        setNewUserHiring(userData.tipo_contratacao.another);
      }
      if (userData.tipo_contratacao.hasOwnProperty("default")) {
        setNewUserHiring(userData.tipo_contratacao.default);
      }
    }
    if (typeof userData.site_atuacao === "object") {
      if (userData.site_atuacao.hasOwnProperty("another")) {
        setAnotherSiteActing(true);
      }
      if (userData.site_atuacao.hasOwnProperty("default")) {
        setDefaultSiteActing(true);
      }
    }
    if (userData) {
      if (userData.valor_pago_credito) {
        setValorMoeda(userData.valor_pago_credito);
        setTypeLocutor(true);
      }
    }
  }, [userData]);

  const verifyPermissions = (perm) => {
    if (perm.hasOwnProperty("VerTipoRemuneracao")) {
      if (perm.VerTipoRemuneracao.active) {
        setViewUserRemuneration(true);
      }
    }
    if (perm.hasOwnProperty("VerTipoContratacao")) {
      if (perm.VerTipoContratacao.active) {
        setViewUserHiring(true);
      }
    }
  };

  useEffect(() => {
    if (!userPermissionsStore.length) return;
    userPermissionsStore.filter(verifyPermissions);
  }, [userPermissionsStore]);

  const handleCloseEditPass = () => setShowEditPass(false);
  const handleShowEditPass = () => setShowEditPass(true);

  const filePickerCallback = (cb, value, meta) => {
    var input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.onchange = function () {
      var file = this.files[0];
      var reader = new FileReader();
      reader.onload = function () {
        var id = "blobid" + new Date().getTime();
        var blobCache = tinymce.activeEditor.editorUpload.blobCache;
        // var base64 = reader.result.split(',')[1];
        const dataUrlFile = reader.result;

        let uploadTask = avatarRef
          .child(file.name)
          .putString(dataUrlFile, "data_url");
        NotificationManager.info("Aguarde!", "Anexando imagem!");
        uploadTask.on(
          "state_changed",
          function (snapshot) {},
          function (error) {
            // Handle unsuccessful uploads
          },
          function () {
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then(function (downloadURL) {
                NotificationManager.success("Imagem anexada!");
                var blobInfo = blobCache.create(id, file, downloadURL);
                blobCache.add(blobInfo);
                cb(blobInfo.blobUri(), { title: file.name });
              });
          }
        );
      };
      reader.readAsDataURL(file);
    };

    input.click();
  };

  const changeEditor = (content) => {
    var newContent;
    if (String(content).indexOf("png") > -1) {
      newContent = content.replace("data:image/png;base64,", "");
      dispatch({
        type: "SET_SIGNATURE",
        userSignature: newContent,
      });
      setUserSignature(newContent);
      return;
    } else if (String(content).indexOf("jpg") > -1) {
      newContent = content.replace("data:image/jpg;base64,", "");
      dispatch({
        type: "SET_SIGNATURE",
        userSignature: newContent,
      });
      setUserSignature(newContent);
      return;
    } else if (String(content).indexOf("jpeg") > -1) {
      newContent = content.replace("data:image/jpeg;base64,", "");
      dispatch({
        type: "SET_SIGNATURE",
        userSignature: newContent,
      });
      setUserSignature(newContent);
      return;
    } else {
      dispatch({
        type: "SET_SIGNATURE",
        userSignature: content,
      });
      setUserSignature(content);
      return;
    }
  };

  const handlePasswordChange = (event) => {
    setUserPass(event.target.value);
    event.target.value.length < 6
      ? setPassError(
          <small className="text-danger">Pelo menos 6 caracteres.</small>
        )
      : setPassError(null);
  };

  const handleCfPasswordChange = (event) => {
    setCfPass(event.target.value);
    event.target.value !== userPass
      ? setCfpasswordError(
          <small className="text-danger">Senhas divergem.</small>
        )
      : setCfpasswordError(null);
  };

  const handleNameChange = (event) => {
    setUserName(event.target.value);
    if (!event.target.value.length) {
      setSaveChanges(true);
      setUserNameError(
        <small className="text-danger">Campo sobrenome obrigatório.</small>
      );
    } else {
      setSaveChanges(false);
      setUserNameError(null);
    }
  };

  const updateUserPass = () => {
    var updatePass = firebase.functions().httpsCallable("updateUserPass");
    updatePass({ email: userData.email, newPass: userPass })
      .then(function (result) {
        if (result.data.update) {
          setShowEditPass(false);
          NotificationManager.success("Senha alterada com successo!");
        } else {
          setShowEditPass(false);
          NotificationManager.error("Não foi possível alterar a senha!");
        }
      })
      .catch(() => {
        setShowEditPass(false);
        NotificationManager.error("Erro desconhecido contate o suporte!");
      });
  };

  const updateUserEmail = () => {
    NotificationManager.info("Aguarde!");
    var updateEmail = firebase.functions().httpsCallable("updateUserEmail");
    updateEmail({ email: userData.email, newEmail: userEmail, id: userData.id })
      .then(function (result) {
        if (result.data.update) {
          setShowEditPass(false);
          setDisabled(true);
          NotificationManager.success("E-mail alterado com successo!");
        } else {
          console.log(result);
          setShowEditPass(false);
          NotificationManager.error("Não foi possível alterar o e-mail!");
        }
      })
      .catch(() => {
        setShowEditPass(false);
        NotificationManager.error("Erro desconhecido contate o suporte!");
      });
  };

  const updateUser = () => {
    userRef
      .doc(userData.id)
      .update({
        nome: userName ? userName : userData.nome,
        valor_pago_credito: valorMoeda
          ? valorMoeda
          : userData.valor_pago_credito ? userData.valor_pago_credito : 0,
        sobrenome:
          userData.sobrenome && !userLastName
            ? ""
            : userLastName
            ? userLastName
            : userData.sobrenome
            ? userData.sobrenome
            : "",
        assinatura:
          userData.assinatura && !userSignature
            ? ""
            : userSignature
            ? userSignature
            : userData.assinatura
            ? userData.assinatura
            : "",
        funcao:
          typeof newUserFunctionType === "object" &&
          newUserFunctionType.hasOwnProperty("value")
            ? { default: newUserFunctionType }
            : newUserFunctionType
            ? { another: newUserFunctionType }
            : userData.funcao,
        site_atuacao: defaultSiteActing
          ? { default: "Grupo Locutores Comerciais" }
          : newUserSiteActing
          ? { another: newUserSiteActing }
          : defaultSiteActing
          ? { default: "Grupo Locutores Comerciais" }
          : userData.site_atuacao
          ? userData.site_atuacao
          : "",
        cargo:
          typeof newUserCharge === "object" &&
          newUserCharge.hasOwnProperty("value")
            ? { default: newUserCharge }
            : newUserCharge
            ? { another: newUserCharge }
            : userData.cargo,
        tipo_remuneracao:
          typeof newUserRemuneration === "object" &&
          newUserRemuneration.hasOwnProperty("value")
            ? { default: newUserRemuneration }
            : newUserRemuneration
            ? { another: newUserRemuneration }
            : userData.tipo_remuneracao,
        tipo_contratacao:
          typeof newUserHiring === "object" &&
          newUserHiring.hasOwnProperty("value")
            ? { default: newUserHiring }
            : newUserHiring
            ? { another: newUserHiring }
            : userData.tipo_contratacao,
      })
      .then(() => {
        NotificationManager.success("Usuário atualizado!", "Wow!");
      })
      .catch(() => {
        NotificationManager.error(
          "Não foi possível atualizar o usuário!",
          "Verifique os dados digitados!"
        );
      });
  };

  const updateUserStatus = () => {
    userRef
      .doc(userData.id)
      .update({
        status: {
          icon_color: "#FFF",
          nome: newUserStatus,
        },
      })
      .then(() => {
        NotificationManager.success("Status atualizado", "Wow!");
        setShowUserStatus(false);
      })
      .catch(() => {
        NotificationManager.error("Não foi possível atualizar o status");
      });
  };

  const uploadUserPhotos = (e, type) => {
    const file = e.target.files[0];
    var childInfo;
    if (type === "avatar") {
      childInfo = `avatares/${userData.id}/${file.name}`;
    } else {
      childInfo = `backgrounds/${userData.id}/${file.name}`;
    }
    let uploadTask = avatarRef.child(childInfo).put(file);
    NotificationManager.info("Aguarde!", "Anexando foto!");
    uploadTask.on(
      "state_changed",
      function (snapshot) {
        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      },
      function (error) {
        NotificationManager.error(
          `Não foi possível alterar a foto de ${
            type === "avatar" ? "perfil" : "fundo"
          }!`
        );
      },
      function () {
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          NotificationManager.success("Foto anexada!");
          if (type === "avatar") {
            userRef.doc(userData.id).update({
              foto_perfil: downloadURL,
            });
          } else {
            userRef.doc(userData.id).update({
              foto_fundo: downloadURL,
            });
          }
        });
      }
    );
  };

  const removeBgPhoto = () => {
    userRef
      .doc(userData.id)
      .update({
        foto_fundo: "",
      })
      .then(() => {
        NotificationManager.success("Imagem removida!");
      });
  };

  const removeAvatarPhoto = () => {
    userRef
      .doc(userData.id)
      .update({
        foto_perfil: "",
      })
      .then(() => {
        NotificationManager.success("Imagem removida!");
      });
  };

  const setUserDisable = (disabled) => {
    var disableUser = firebase.functions().httpsCallable("setUserDisabled");
    disableUser({ email: userData.email, id: userData.id, disabled: disabled })
      .then(function (result) {
        if (result.data.update) {
          if (disabled) {
            NotificationManager.success("Conta desativada!");
          } else {
            NotificationManager.success("Conta reativada!");
          }
        } else {
          if (disabled) {
            NotificationManager.error("Não foi possível desativar a conta!");
          } else {
            NotificationManager.error("Não foi possível reativar a conta!");
          }
        }
      })
      .catch(() => {
        NotificationManager.error("Erro desconhecido contate o suporte!");
      });
  };

  const deleteUserData = () => {
    var removeUser = firebase.functions().httpsCallable("deleteUser");
    removeUser({ email: userData.email, id: userData.id })
      .then(function (result) {
        if (result.data.delete) {
          NotificationManager.success("Conta excluída!");
          setTimeout(() => {
            setRedirectList(true);
          }, 100);
        } else {
          NotificationManager.error("Não foi possível excluir a conta!");
        }
      })
      .catch(() => {
        NotificationManager.error("Erro desconhecido contate o suporte!");
      });
  };

  const generateKey = (pre) => {
    return `${pre}_${
      new moment().unix() + "" + Math.floor(Math.random() * 500)
    }`;
  };

  const handleSetUserPermissions = (name, active) => {
    var newArr = userData.permissoes;
    newArr.forEach((value, index) => {
      if (value.hasOwnProperty(name)) {
        newArr[index][name].active = active;
        setNewUserPermissions(newArr);
      }
    });
  };

  const savePermissions = () => {
    userRef
      .doc(props.match.params.id)
      .update({
        permissoes: newUserPermissions
          ? newUserPermissions.length > 0
            ? newUserPermissions
            : defaultPermissions
          : userData.permissoes.length > 0
          ? userData.permissoes
          : defaultPermissions,
      })
      .then(() => {
        NotificationManager.success("Permissões atualizadas!");
        setShowUserPermissions(false);
      })
      .catch(() => {
        NotificationManager.error("Não foi possível atualizar as permissões!");
        setShowUserPermissions(false);
      });
  };

  const exibirCampoValor = (event) => {
    if (event.value === "Locutor") {
      setTypeLocutor(true);
    } else {
      setTypeLocutor(false);
    }
    setNewUserCharge(event);
  };
  const formataMoeda = (valor) => {
    var v = valor.replace(/\D/g, "");
    v = (v / 100).toFixed(2) + "";
    v = v.replace(".", ",");
    v = v.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
    v = v.replace(/(\d)(\d{3}),/g, "$1.$2,");
    setValorMoeda(v);
  };

  return (
    <div className="main-content">
      {redirectList && <Redirect to="/pedidos/lista-usuarios" />}
      <Grid fluid>
        <Row>
          <Col md={12}>
            <ButtonD
              onClick={() => history.goBack()}
              style={{ marginBottom: "1rem" }}
            >
              Voltar
            </ButtonD>
            <Card
              ctTableFullWidth
              ctTableResponsive
              content={
                loading ? (
                  <BarLoader
                    css={override}
                    size={150}
                    color={"#FFB800"}
                    loading={loading}
                  />
                ) : (
                  <PerfilUsuarioStyle>
                    <UserCard
                      bgImage={
                        userData && userData.foto_fundo
                          ? userData.foto_fundo
                          : "https://firebasestorage.googleapis.com/v0/b/locutores-comerciais.appspot.com/o/macos-mojave-default-wallpaper-light.jpg?alt=media&token=f7f14e31-24af-466d-bd19-0f747b2ecf5c"
                      }
                      avatar={
                        userData && userData.foto_perfil
                          ? userData.foto_perfil
                          : "https://firebasestorage.googleapis.com/v0/b/locutores-comerciais.appspot.com/o/avatar-default.png?alt=media&token=77a4bd84-0ca8-420c-bd2f-d6e6e6a20d8c"
                      }
                      name={userData ? userData.nome && userData.nome : ""}
                      lastName={
                        userData ? userData.sobrenome && userData.sobrenome : ""
                      }
                      email={userData ? userData.email && userData.email : ""}
                      onChangePhotoAvatar={(e) => {
                        uploadUserPhotos(e, "avatar");
                      }}
                      onChangePhotoBg={(e) => uploadUserPhotos(e, "background")}
                      removeBgFunc={removeBgPhoto}
                      removeAvatarFunc={removeAvatarPhoto}
                    >
                      <div className="footer-card">
                        <div className="group-buttons">
                          {changeConfigs && (
                            <>
                              <Nav pullRight>
                                <NavDropdown
                                  eventKey={3}
                                  title={<i className="pe-7s-config" />}
                                  noCaret
                                  id="disable-account"
                                >
                                  {userData && userData.desativado ? (
                                    <MenuItem
                                      eventKey={3.1}
                                      onClick={() => setUserDisable(false)}
                                    >
                                      Reativar conta
                                    </MenuItem>
                                  ) : (
                                    <MenuItem
                                      eventKey={3.1}
                                      onClick={() => setUserDisable(true)}
                                    >
                                      Desativar conta
                                    </MenuItem>
                                  )}
                                  <MenuItem
                                    eventKey={3.2}
                                    onClick={deleteUserData}
                                  >
                                    Excluir conta
                                  </MenuItem>
                                </NavDropdown>
                              </Nav>
                              <button
                                onClick={handleShowUserPermissions}
                                className="btn btn-perm-edit"
                                type="button"
                              >
                                Permissões
                              </button>
                            </>
                          )}
                          {/* {changeUserStatus && (
                            <button
                              onClick={handleShowUserStatus}
                              className="btn btn-edit-status"
                              type="button"
                            >
                              Alterar Status
                            </button>
                          )} */}
                          <button
                            onClick={handleShowEditPass}
                            className="btn btn-pass-edit"
                            type="button"
                          >
                            Alterar senha
                          </button>
                          <button
                            onClick={() => setEditForm(!editForm)}
                            className="btn btn-edit"
                            type="button"
                          >
                            Editar dados básicos
                          </button>
                        </div>
                        <div className="clear" />
                      </div>
                      <div
                        className={`dados ${
                          editForm ? "active-edit" : "disable-edit"
                        }`}
                      >
                        <form>
                          <Row>
                            <FormGroup className="col-md-5">
                              <ControlLabel>Empresa</ControlLabel>
                              <FormControl
                                type="text"
                                disabled
                                className="form-control"
                                placeholder="Empresa"
                                defaultValue="Grupo Locutores Comerciais."
                              />
                            </FormGroup>
                            <FormGroup className="col-md-6">
                              <ControlLabel>E-mail: </ControlLabel>
                              <small
                                id="emailHelp"
                                className="form-text text-muted"
                              >
                                {disabled ? (
                                  <span
                                    className="text-primary"
                                    role="button"
                                    style={{ margin: "0 5px" }}
                                    onClick={() => {
                                      setDisabled(false);
                                    }}
                                  >
                                    Alterar
                                  </span>
                                ) : (
                                  <React.Fragment>
                                    <span
                                      className="text-primary"
                                      role="button"
                                      style={{ margin: "0 5px" }}
                                      onClick={updateUserEmail}
                                    >
                                      Salvar
                                    </span>
                                    <span
                                      className="text-primary"
                                      role="button"
                                      onClick={() => {
                                        setDisabled(true);
                                      }}
                                    >
                                      Cancelar
                                    </span>
                                  </React.Fragment>
                                )}
                              </small>
                              <FormControl
                                type="email"
                                disabled={disabled}
                                className="form-control"
                                placeholder="E-mail"
                                defaultValue={
                                  userData
                                    ? userData.email && userData.email
                                    : ""
                                }
                                onChange={(e) => setUserEmail(e.target.value)}
                              />
                            </FormGroup>
                          </Row>
                          <Row>
                            <FormGroup className="col-md-6">
                              <ControlLabel>Nome</ControlLabel>
                              <FormControl
                                type="text"
                                className="form-control"
                                placeholder="Nome"
                                defaultValue={
                                  userData ? userData.nome && userData.nome : ""
                                }
                                onChange={(e) => handleNameChange(e)}
                              />
                              {userNameError}
                            </FormGroup>
                            <FormGroup className="col-md-6">
                              <ControlLabel>Sobrenome</ControlLabel>
                              <FormControl
                                type="text"
                                className="form-control"
                                placeholder="Sobrenome"
                                defaultValue={
                                  userData
                                    ? userData.sobrenome && userData.sobrenome
                                    : ""
                                }
                                onChange={(e) => setLastName(e.target.value)}
                              />
                            </FormGroup>
                          </Row>
                          <Row>
                            { changeConfigs &&
                            <FormGroup className="col-md-4">
                              <ControlLabel
                                className="form-text"
                                style={{
                                  fontWeight: 400,
                                  textTransform: "uppercase",
                                  fontSize: "12px",
                                }}
                              >
                                Função
                              </ControlLabel>
                              {userData ? (
                                <Select
                                  className="react-select primary"
                                  classNamePrefix="react-select"
                                  name="singleSelect"
                                  isDisabled={!changeConfigs}
                                  value={newUserFunctionType}
                                  onChange={(value) =>
                                    setNewUserFunctionType(value)
                                  }
                                  options={optionsUserFunction}
                                  placeholder="Selecione uma função"
                                />
                              ) : (
                                <AsyncSelect
                                  className="react-select primary"
                                  classNamePrefix="react-select"
                                  isDisabled={!changeConfigs}
                                  name="singleSelect"
                                  value={newUserFunctionType}
                                  onChange={(value) =>
                                    setNewUserFunctionType(value)
                                  }
                                  defaultOptions={optionsUserFunction}
                                  placeholder="Selecione uma função"
                                />
                              )}
                              {changeConfigs && (
                                <div className="checkbox">
                                  <FormControl
                                    id="anotherUserFunctionType"
                                    type="checkbox"
                                    disabled={!changeConfigs}
                                    checked={anotherUserFunctionType}
                                    defaultChecked={anotherUserFunctionType}
                                    onChange={() =>
                                      setAnotherUserFunctionType(
                                        !anotherUserFunctionType
                                      )
                                    }
                                  />
                                  <label htmlFor="anotherUserFunctionType">
                                    Outro
                                  </label>
                                </div>
                              )}
                              {anotherUserFunctionType && (
                                <FormControl
                                  type="text"
                                  placeholder="Digite aqui"
                                  disabled={!changeConfigs}
                                  defaultValue={
                                    userData &&
                                    userData.funcao.hasOwnProperty("another")
                                      ? userData.funcao.another
                                      : ""
                                  }
                                  onChange={(e) =>
                                    setNewUserFunctionType(e.target.value)
                                  }
                                />
                              )}
                            </FormGroup>
                            }
                            <FormGroup className="col-md-4">
                              <ControlLabel
                                className="form-text"
                                style={{
                                  fontWeight: 400,
                                  textTransform: "uppercase",
                                  fontSize: "12px",
                                }}
                              >
                                Cargo
                              </ControlLabel>
                              {userData ? (
                                <Select
                                  className="react-select primary"
                                  classNamePrefix="react-select"
                                  name="singleSelect"
                                  isDisabled={!changeConfigs}
                                  value={newUserCharge}
                                  onChange={(value) => exibirCampoValor(value)}
                                  options={optionsUserCharge}
                                  placeholder="Selecione um cargo"
                                />
                              ) : (
                                <AsyncSelect
                                  className="react-select primary"
                                  classNamePrefix="react-select"
                                  name="singleSelect"
                                  disabled={!changeConfigs}
                                  value={newUserCharge}
                                  onChange={(value) => setNewUserCharge(value)}
                                  defaultOptions={optionsUserCharge}
                                  placeholder="Selecione um cargo"
                                />
                              )}
                              {typeLocutor && changeConfigs && (
                                <>
                                  <ControlLabel
                                    className="form-text"
                                    style={{
                                      fontWeight: 400,
                                      textTransform: "uppercase",
                                      fontSize: "12px",
                                      marginTop: "10px",
                                    }}
                                  >
                                    Valor pago por credito
                                  </ControlLabel>
                                  <div style={styles.campoDiv}>
                                    <p style={styles.campoReal}>R$</p>
                                    <FormControl
                                      style={styles.campoMoeda}
                                      type="text"
                                      disabled={!changeConfigs}
                                      value={valorMoeda}
                                      placeholder="Valor pago por crédito"
                                      onChange={(e) =>
                                        formataMoeda(e.target.value)
                                      }
                                    />
                                  </div>
                                </>
                              )}
                              {changeConfigs && (
                                <div className="checkbox">
                                  <FormControl
                                    id="anotherUserCharge"
                                    type="checkbox"
                                    checked={anotherUserCharge}
                                    defaultChecked={anotherUserCharge}
                                    disabled={!changeConfigs}
                                    onChange={() =>
                                      setAnotherUserCharge(!anotherUserCharge)
                                    }
                                  />
                                  <label htmlFor="anotherUserCharge">
                                    Outro
                                  </label>
                                </div>
                              )}
                              {anotherUserCharge && (
                                <FormControl
                                  type="text"
                                  placeholder="Digite aqui"
                                  disabled={!changeConfigs}
                                  defaultValue={
                                    userData &&
                                    userData.cargo.hasOwnProperty("another")
                                      ? userData.cargo.another
                                      : ""
                                  }
                                  onChange={(e) =>
                                    setNewUserCharge(e.target.value)
                                  }
                                />
                              )}
                            </FormGroup>
                            <FormGroup className="col-md-4">
                              <ControlLabel
                                className="form-text col-md-12"
                                style={{
                                  fontWeight: 400,
                                  textTransform: "uppercase",
                                  fontSize: "12px",
                                  paddingLeft: 0,
                                }}
                              >
                                Onde irá atuar?
                              </ControlLabel>
                              <Col>
                                <div className="checkbox">
                                  <FormControl
                                    type="checkbox"
                                    id="defaultActing"
                                    type="checkbox"
                                    disabled={!changeConfigs}
                                    checked={defaultSiteActing}
                                    defaultChecked={defaultSiteActing}
                                    onChange={() =>
                                      setDefaultSiteActing(!defaultSiteActing)
                                    }
                                  />
                                  <label htmlFor="defaultActing">
                                    Grupo Locutores Comerciais
                                  </label>
                                </div>
                                {changeConfigs && (
                                  <div className="checkbox">
                                    <FormControl
                                      id="anotherSiteActing"
                                      type="checkbox"
                                      checked={anotherSiteActing}
                                      disabled={!changeConfigs}
                                      defaultChecked={anotherSiteActing}
                                      onChange={() =>
                                        setAnotherSiteActing(!anotherSiteActing)
                                      }
                                    />
                                    <label htmlFor="anotherSiteActing">
                                      Outro
                                    </label>
                                  </div>
                                )}
                                {anotherSiteActing && (
                                  <FormControl
                                    type="text"
                                    placeholder="Digite aqui"
                                    disabled={!changeConfigs}
                                    defaultValue={
                                      userData &&
                                      userData.site_atuacao.hasOwnProperty(
                                        "another"
                                      )
                                        ? userData.site_atuacao.another
                                        : ""
                                    }
                                    value={newUserSiteActing}
                                    onChange={(e) =>
                                      setNewUserSiteActing(e.target.value)
                                    }
                                  />
                                )}
                              </Col>
                            </FormGroup>
                          </Row>
                          <Row>
                            {changeConfigs || viewUserRemuneration ? (
                              <FormGroup className="col-md-6">
                                <ControlLabel
                                  className="form-text"
                                  style={{
                                    fontWeight: 400,
                                    textTransform: "uppercase",
                                    fontSize: "12px",
                                  }}
                                >
                                  Tipo de remuneração
                                </ControlLabel>
                                {userData ? (
                                  <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    name="singleSelect"
                                    isDisabled={!changeConfigs}
                                    value={newUserRemuneration}
                                    onChange={(value) =>
                                      setNewUserRemuneration(value)
                                    }
                                    options={optionsUserRemuneration}
                                    placeholder="Selecione um tipo de remuneração"
                                  />
                                ) : (
                                  <AsyncSelect
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    name="singleSelect"
                                    value={newUserRemuneration}
                                    onChange={(value) =>
                                      setNewUserRemuneration(value)
                                    }
                                    defaultOptions={optionsUserRemuneration}
                                    placeholder="Selecione um tipo de remuneração"
                                  />
                                )}
                                {changeConfigs && (
                                  <div className="checkbox">
                                    <FormControl
                                      id="anotherRemuneration"
                                      type="checkbox"
                                      checked={anotherRemuneration}
                                      defaultChecked={anotherRemuneration}
                                      onChange={() =>
                                        setAnotherRemuneration(
                                          !anotherRemuneration
                                        )
                                      }
                                    />
                                    <label htmlFor="anotherRemuneration">
                                      Outro
                                    </label>
                                  </div>
                                )}
                                {anotherRemuneration && (
                                  <FormControl
                                    type="text"
                                    placeholder="Digite aqui"
                                    defaultValue={
                                      userData &&
                                      userData.tipo_remuneracao.hasOwnProperty(
                                        "another"
                                      )
                                        ? userData.tipo_remuneracao.another
                                        : ""
                                    }
                                    onChange={(e) =>
                                      setNewUserRemuneration(e.target.value)
                                    }
                                  />
                                )}
                              </FormGroup>
                            ) : (
                              ""
                            )}
                            {changeConfigs || viewUserHiring ? (
                              <FormGroup className="col-md-6">
                                <ControlLabel
                                  className="form-text"
                                  style={{
                                    fontWeight: 400,
                                    textTransform: "uppercase",
                                    fontSize: "12px",
                                  }}
                                >
                                  Tipo de contratação
                                </ControlLabel>
                                {userData ? (
                                  <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    name="singleSelect"
                                    isDisabled={!changeConfigs}
                                    value={newUserHiring}
                                    onChange={(value) =>
                                      setNewUserHiring(value)
                                    }
                                    options={optionsUserHiring}
                                    placeholder="Selecione um tipo de contratação"
                                  />
                                ) : (
                                  <AsyncSelect
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    name="singleSelect"
                                    value={newUserHiring}
                                    onChange={(value) =>
                                      setNewUserHiring(value)
                                    }
                                    defaultOptions={optionsUserHiring}
                                    placeholder="Selecione um tipo de contratação"
                                  />
                                )}
                                <div className="checkbox">
                                  <FormControl
                                    id="anotherHiring"
                                    type="checkbox"
                                    checked={anotherHiring}
                                    defaultChecked={anotherHiring}
                                    onChange={() =>
                                      setAnotherHiring(!anotherHiring)
                                    }
                                  />
                                  <label htmlFor="anotherHiring">Outro</label>
                                </div>
                                {anotherHiring && (
                                  <FormControl
                                    type="text"
                                    placeholder="Digite aqui"
                                    defaultValue={
                                      userData &&
                                      userData.tipo_contratacao.hasOwnProperty(
                                        "another"
                                      )
                                        ? userData.tipo_contratacao.another
                                        : ""
                                    }
                                    onChange={(e) =>
                                      setNewUserHiring(e.target.value)
                                    }
                                  />
                                )}
                              </FormGroup>
                            ) : (
                              ""
                            )}
                          </Row>
                          <Row>
                            <div className="col-md-8">
                              <FormGroup controlId="formControlsTextarea">
                                <ControlLabel className="signature">
                                  Assinatura:{" "}
                                </ControlLabel>
                                <small
                                  id="emailHelp"
                                  className="form-text text-muted"
                                >
                                  OBS: Após inserir uma assinatura em formato de
                                  imagem, ajuste-a para o tamanho desejado.
                                  Lembrando que o tamanho definido aqui, será
                                  visto pelos seus destinatários.
                                </small>
                                <Editor
                                  initialValue={userData?.assinatura}
                                  apiKey="3b5lzvgi3vfq7vlq7nfvub3pmonp9g382q1pz6j1o0cqztqb"
                                  init={{
                                    language: "pt_BR",
                                    height: 250,
                                    selector:
                                      "textarea#full-featured-non-premium",
                                    plugins:
                                      "print image preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen link template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount textpattern noneditable help charmap emoticons",
                                    menubar:
                                      "file edit view insert format tools table help",
                                    toolbar:
                                      "image | undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile template link anchor codesample | ltr rtl",
                                    toolbar_sticky: true,
                                    automatic_uploads: true,
                                    image_title: true,
                                    file_picker_types: "image",
                                    file_picker_callback: filePickerCallback,
                                  }}
                                  onEditorChange={(content) =>
                                    changeEditor(content)
                                  }
                                />
                              </FormGroup>
                            </div>
                          </Row>
                          <Button
                            bsStyle="success"
                            pullRight
                            fill
                            onClick={updateUser}
                            disabled={saveChanges}
                          >
                            Salvar alterações
                          </Button>
                          <div className="clearfix" />
                        </form>
                      </div>
                    </UserCard>
                  </PerfilUsuarioStyle>
                )
              }
            />
            <Modal show={showEditPass} onHide={handleCloseEditPass}>
              <Modal.Header closeButton>
                <Modal.Title>Alterar senha</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <FormGroup className="col-md-12">
                    <ControlLabel
                      className="form-text"
                      style={{
                        fontWeight: 400,
                        textTransform: "uppercase",
                        fontSize: "12px",
                      }}
                    >
                      Nova senha <span className="star">*</span>
                    </ControlLabel>
                    <FormControl
                      type="password"
                      placeholder="Senha"
                      className="form-control"
                      onChange={(event) => {
                        handlePasswordChange(event);
                      }}
                    />
                    {userPassError}
                  </FormGroup>
                  <FormGroup className="col-md-12">
                    <ControlLabel
                      className="form-text"
                      style={{
                        fontWeight: 400,
                        textTransform: "uppercase",
                        fontSize: "12px",
                      }}
                    >
                      Confirmar nova senha: <span className="star">*</span>
                    </ControlLabel>
                    <FormControl
                      type="password"
                      placeholder="Confirmar senha"
                      value={cfPass}
                      onChange={(event) => handleCfPasswordChange(event)}
                    />
                    {cfpasswordError}
                  </FormGroup>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  bsStyle="danger"
                  fill
                  type="button"
                  onClick={handleCloseEditPass}
                >
                  Fechar
                </Button>
                <Button
                  bsStyle="success"
                  pullRight
                  fill
                  type="button"
                  onClick={updateUserPass}
                >
                  Salvar
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal
              show={showUserPermissions}
              onHide={handleCloseUserPermissions}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  Permissões <i className="pe-7s-key" />
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {userData &&
                  userData.permissoes.length &&
                  userData.permissoes.map((value, index) => {
                    return (
                      <Row key={generateKey(value + index)}>
                        <FormGroup
                          className="col-md-12"
                          style={styles.gridAlignC}
                        >
                          <ControlLabel
                            className="form-text"
                            style={{
                              fontWeight: 400,
                              textTransform: "uppercase",
                              fontSize: "12px",
                            }}
                          >
                            {Object.entries(value)[0][1].text}
                          </ControlLabel>
                          <div style={styles.permSwitch}>
                            <Switch
                              ey={generateKey(value + index)}
                              onChange={(e) =>
                                handleSetUserPermissions(
                                  e.props.name,
                                  e.state.value
                                )
                              }
                              onText="✔"
                              offText="✘"
                              name={Object.entries(value)[0][0]}
                              defaultValue={Object.entries(value)[0][1].active}
                            />{" "}
                          </div>
                        </FormGroup>
                      </Row>
                    );
                  })}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  bsStyle="danger"
                  fill
                  type="button"
                  onClick={handleCloseUserPermissions}
                >
                  Fechar
                </Button>
                <Button
                  bsStyle="success"
                  pullRight
                  fill
                  type="button"
                  onClick={savePermissions}
                >
                  Finalizar
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={showUserStatus} onHide={handleCloseUserStatus}>
              <Modal.Header closeButton>
                <Modal.Title>Definir status</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {userData && (
                  <Row>
                    <FormGroup className="col-md-12">
                      <p>Status atual: {userData.status.nome}</p>
                    </FormGroup>
                  </Row>
                )}
                <Row>
                  <FormGroup className="col-md-12">
                    <ControlLabel
                      className="form-text"
                      style={{
                        fontWeight: 400,
                        textTransform: "uppercase",
                        fontSize: "12px",
                      }}
                    >
                      Status
                    </ControlLabel>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      onChange={(value) => exibirCampoPersonalizado(value)}
                      options={optionStatus}
                      placeholder="Selecione um status"
                    />
                  </FormGroup>
                </Row>
                <Row>
                  <FormGroup className="col-md-12">
                    {showInputNewStatus && (
                      <FormControl
                        onChange={(e) => setNewUserStatus(e.target.value)}
                        placeholder="Status personalizado"
                      />
                    )}
                  </FormGroup>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  bsStyle="danger"
                  fill
                  type="button"
                  onClick={handleCloseUserStatus}
                >
                  Fechar
                </Button>
                <Button
                  bsStyle="success"
                  pullRight
                  fill
                  type="button"
                  onClick={updateUserStatus}
                >
                  Salvar
                </Button>
              </Modal.Footer>
            </Modal>

            {/* <Modal
              show={showUserPermissions}
              onHide={handleCloseUserPermissions}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  Comissões <i className="pe-7s-key" />
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>

              </Modal.Body>
              <Modal.Footer>
                <Button
                  bsStyle="danger"
                  fill
                  type="button"
                  onClick={handleCloseUserPermissions}
                >
                  Fechar
                </Button>
                <Button
                  bsStyle="success"
                  pullRight
                  fill
                  type="button"
                  onClick={savePermissions}
                >
                  Finalizar
                </Button>
              </Modal.Footer>
            </Modal> */}
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default PerfilUsuario;
