import styled from 'styled-components';

const ButtonDefault = styled.div`
button {
  background: #FFB800;
  border: 1px solid #FFB800;
  color: #fff;
  opacity: 1;
  transition: .4s;
  float: ${props => props.fRight ? 'right' : props.fLeft ? props.fLeft : 'none'};
  &:hover {
    background: #ffa000;
    border: 1px solid #ffa000;
    color: #fff;
  }
  &:active {
    background: #ffa000;
    border: 1px solid #FFB800;
    color: #fff;
  }
  &:focus {
    background: #ffa000;
    border: 1px solid #FFB800;
    color: #fff;
  }
}
`
export default ButtonDefault;