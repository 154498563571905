import { createStore } from 'redux';
import userReducer from './userReducer';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
    key: 'locutores-comerciais',
    storage
}

const persisted = persistReducer(persistConfig, userReducer);

const store = createStore(persisted);
const persistor = persistStore(store)

export { store, persistor };