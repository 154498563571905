import React, {useState, useEffect, useRef} from "react";
import {useSelector, useDispatch} from "react-redux";
import {Grid, Col, Row, ListGroup, ListGroupItem} from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import {Lines} from "react-preloaders";
import RecentMessages from "./Components/RecentMessages";
import {DashStyle} from "assets/css/views/Dashboard";

import firebase from "../config/firebase";
import "firebase/firestore";

const styles = {
  noData: {
    textAlign: 'center', color: '#7d7b7b', fontSize: 15
  },
  trNoData: {
    height: '100px',
    background: 'none',
  },
  tdNoData: {
    transform: 'translateX(50%)',
    position: 'absolute',
  },
  liStyle: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}

const Dashboard = () => {
  const dispatch = useDispatch();

  const userEmailStore = useSelector((state) => state.userEmail);

  const [userData, setUserData] = useState();
  const [recentMessages, setRecentMessages] = useState([]);
  const [loading, setLoading] = useState(true);

  const usersRef = firebase.firestore().collection("users");
  const interacoesRef = firebase.firestore().collection("interacoes");

  const getRecentMessages = () => {
    interacoesRef
      .where("ativos", "array-contains", userEmailStore)
      .orderBy("data", "desc")
      .limit(5)
      .get()
      .then((snapshot) => {
        if (!snapshot.empty) {
          let messages = snapshot.docs.map((message) => {
            return {
              ...message.data(),
              id: message.id
            };
          });
          let newArr = Array(messages.filter(x => !x.visualizacoes.includes(userEmailStore)));
          setRecentMessages(...newArr);
        }
      });
  };

  useEffect(() => {
    usersRef
      .where("email", "==", userEmailStore)
      .get()
      .then((snapshot) => {
        if (!snapshot.empty) {
          setLoading(false);
          setUserData(snapshot.docs[0].data());
          dispatch({
            type: "LOGGED",
            userName: snapshot.docs[0].data().nome,
            userLastName: snapshot.docs[0].data().sobrenome,
            userEmail: snapshot.docs[0].data().email,
            userCharge: snapshot.docs[0].data().cargo,
            userType: snapshot.docs[0].data().funcao,
            userId: snapshot.docs[0].id,
            userPermissions: snapshot.docs[0].data().permissoes,
            userRemetentes: snapshot.docs[0].data().remetentes,
          });
          dispatch({
            type: 'SET_SIGNATURE',
            userSignature: snapshot.docs[0].data().assinatura,
          });
          dispatch({
            type: "SET_AVATAR",
            userAvatar: snapshot.docs[0].data().foto_perfil,
          });
        }
      });
    getRecentMessages();
  }, [firebase]);

  return (
    <div className="main-content">
      {/* <Lines customLoading={loading} time={500} background="#ffb800"/> */}
      <DashStyle>
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title={`Olá ${userData && userData.nome}, seja bem vindo!`}
                content={
                  <div>
                    {userData ? (
                      userData.funcao === "Funcionário" || userData.funcao === 'Administrador' ? (
                        <p>
                          Você tem{" "}
                          {userData && userData.creditos_disponiveis < 0
                            ? 0
                            : userData.creditos_disponiveis}{" "}
                          créditos <i className="pe-7s-wallet text-success"/>{" "}
                          disponíveis!
                        </p>
                      ) : (
                        userData.funcao === "Locutor" && (
                          <p>
                            Nós do Grupo Locutores Comerciais temos{" "}
                            {userData.creditos_disponiveis < 0
                              ? 0
                              : userData.creditos_disponiveis}{" "}
                            créditos <i className="pe-7s-wallet text-success"/>{" "}
                            com você!
                          </p>
                        )
                      )
                    ) : null}
                  </div>
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <Card
                title="Últimas mensagens recebidas"
                content={
                  recentMessages.length >= 1 ?
                    <RecentMessages messages={recentMessages}/>
                    :
                    <h5 style={styles.noData}>Nenhuma mensagem recente</h5>
                }
                stats={
                  <div>
                    <i className="fa fa-history"/> Atualizado agora
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </DashStyle>
    </div>
  );
};

export default Dashboard;


