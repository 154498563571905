import React, { useState } from "react";
import {
  useTable,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table";
import {
  Table,
  Col,
  Row,
  Button,
  FormControl,
  NavDropdown,
  Nav,
  MenuItem,
} from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import SweetAlert from "react-bootstrap-sweetalert";
import { Redirect, Link } from "react-router-dom";
import { Filter, DefaultColumnFilter } from "./Filters";
import { TableUsersStyle } from "assets/css/components/TableUsers";

import firebase from "firebase/app";
import styled from "styled-components";

const TableUser = ({ columns, data }) => {
  const styles = {
    trStyle: {
      cursor: "pointer",
    },
    tdStyle: {
      padding: 0,
    },
    linkRedirect: {
      width: "100%",
      display: "inline-block",
      color: "#000",
      padding: "12px 8px",
    },
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: 20,
        sortBy: [
          {
            id: "data",
            desc: true,
          },
        ],
      },
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );

  const [linkRedirectToMessage, getIdMessage] = useState();
  const [removeDefinitiveAlert, setRemoveDefinitiveAlert] = useState();
  const [disableAlert, setDisableAlert] = useState();

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " 🔻" : " 🔺") : "";
  };

  const disableUserFunc = (user, disabled) => {
    const messsage = disabled ? "Desativando aguarde!" : "Reativando aguarde!";
    NotificationManager.info(messsage);
    setDisableAlert(null);
    var disableUser = firebase.functions().httpsCallable("setUserDisabled");
    disableUser({ email: user.email, id: user.id, disabled: disabled })
      .then(function (result) {
        if (result.data.update) {
          if (disabled) {
            NotificationManager.success("Conta desativada!");
          } else {
            NotificationManager.success("Conta reativada!");
          }
        } else {
          if (disabled) {
            NotificationManager.error("Não foi possível desativar a conta!");
          } else {
            NotificationManager.error("Não foi possível reativar a conta!");
          }
        }
      })
      .catch(() => {
        NotificationManager.error("Erro desconhecido contate o suporte!");
      });
  };

  const removeDefinitiveUserFunc = (user) => {
    NotificationManager.info("Excluindo aguarde!");
    setRemoveDefinitiveAlert(null);
    var removeUser = firebase.functions().httpsCallable("deleteUser");
    removeUser({ email: user.email, id: user.id })
      .then(function (result) {
        if (result.data.delete) {
          NotificationManager.success("Conta excluída!");
        } else {
          console.log(result);
          NotificationManager.error("Não foi possível excluir a conta!");
        }
      })
      .catch(() => {
        NotificationManager.error("Erro desconhecido contate o suporte!");
      });
  };

  const hideAlert = (type_alert) => {
    switch (type_alert) {
      case "removeDef":
        setRemoveDefinitiveAlert(null);
        break;
      case "disable":
        setDisableAlert(null);
        break;
      default:
        break;
    }
  };

  const disabledUser = (user, disabled) => {
    setDisableAlert(
      <SweetAlert
        style={{ display: "block" }}
        title={`${
          user.desativado ? "Reavitar o usuário " : "Desativar o usuário "
        } ${user.nome + " " + user.sobrenome} ?`}
        onConfirm={() => disableUserFunc(user, disabled)}
        onCancel={() => hideAlert("disable")}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText={user.desativado ? "Sim, reativar!" : "Sim, desativar!"}
        cancelBtnText="Cancelar"
        showCancel
      ></SweetAlert>
    );
  };

  const removeDefinitiveUser = (user) => {
    setRemoveDefinitiveAlert(
      <SweetAlert
        warning
        style={{ display: "block" }}
        title={`Tem que deseja exluir permanentemente o usuário ${
          user.nome + " " + user.sobrenome
        } ?`}
        onConfirm={() => removeDefinitiveUserFunc(user)}
        onCancel={() => hideAlert("removeDef")}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Sim, remover!"
        cancelBtnText="Cancelar"
        showCancel
      ></SweetAlert>
    );
  };

  return (
    <TableUsersStyle>
      {linkRedirectToMessage && (
        <Redirect to={`/admin/perfil-usuario/${linkRedirectToMessage}`} />
      )}
      <Row style={{ maxWidth: 1000, margin: "0 auto", textAlign: "center" }}>
        <Col md={3}>
          <Button
            color="primary"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {"<<"}
          </Button>
          <Button
            color="primary"
            onClick={previousPage}
            disabled={!canPreviousPage}
          >
            {"<"}
          </Button>
        </Col>
        <Col md={2} style={{ marginTop: 7 }}>
          Página{" "}
          <strong>
            {pageIndex + 1} de {pageOptions.length}
          </strong>
        </Col>
        <Col md={2}>
          <FormControl
            type="number"
            min={1}
            style={{ width: 70 }}
            max={pageOptions.length}
            defaultValue={pageIndex + 1}
            onChange={onChangeInInput}
          />
        </Col>
        <Col md={2}>
          <select
            type="select"
            className="form-control"
            value={pageSize}
            onChange={onChangeInSelect}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Mostrar {pageSize}
              </option>
            ))}
          </select>
        </Col>
        <Col md={3}>
          <Button color="primary" onClick={nextPage} disabled={!canNextPage}>
            {">"}
          </Button>
          <Button
            color="primary"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </Button>
        </Col>
      </Row>
      <Table responsive hover {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              <th></th>
              {headerGroup.headers.map((column) => {
                return (
                  <th {...column.getHeaderProps()}>
                    <div {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                    <Filter column={column} />
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                <td style={{ width: "48px" }} className={row.original.desativado ? "opacity_dsb" : ""}>
                  <img
                    src={
                      row.original.foto_perfil
                        ? row.original.foto_perfil
                        : "https://firebasestorage.googleapis.com/v0/b/locutores-comerciais.appspot.com/o/avatar-default.png?alt=media&token=77a4bd84-0ca8-420c-bd2f-d6e6e6a20d8c"
                    }
                    alt={"Foto de " + row.original.nome}
                    style={{
                      width: "3.5rem",
                      height: "3.5rem",
                      objectFit: "cover",
                      borderRadius: "66px",
                    }}
                  />
                </td>
                {row.cells.map((cell) => {
                  return (
                    <td style={styles.tdStyle} {...cell.getCellProps()} className={row.original.desativado ? "opacity_dsb" : ""}>
                      <Link
                        to={`/admin/perfil-usuario/${row.original.id}`}
                        exact
                        {...cell.getCellProps()}
                        style={styles.linkRedirect}
                      >
                        {cell.render("Cell")}
                      </Link>
                    </td>
                  );
                })}
                <td>
                  <Nav pullRight>
                    <NavDropdown
                      eventKey={3}
                      title={
                        <div>
                          <i
                            className="pe-7s-more"
                          />
                        </div>
                      }
                      noCaret
                      id="basic-nav-dropdown-2"
                    >
                      {row.original.desativado ? (
                        <MenuItem
                          eventKey={3.1}
                          onClick={() => disabledUser(row.original, false)}
                        >
                          Reativar usuário
                        </MenuItem>
                      ) : (
                        <MenuItem
                          eventKey={3.1}
                          onClick={() => disabledUser(row.original, true)}
                        >
                          Desativar usuário
                        </MenuItem>
                      )}

                      <MenuItem
                        eventKey={3.2}
                        onClick={() => removeDefinitiveUser(row.original)}
                      >
                        Excluir usuário
                      </MenuItem>
                    </NavDropdown>
                  </Nav>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      {removeDefinitiveAlert}
      {disableAlert}
    </TableUsersStyle>
  );
};

export default TableUser;
