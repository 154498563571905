import React, { useEffect, useState, useMemo } from "react";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
} from "react-bootstrap";
import { css } from "@emotion/core";
import BarLoader from "react-spinners/BarLoader";

import TableContainer from "./Components/TableContainer";
import Checkbox from "components/CustomCheckbox/CustomCheckbox.jsx";

import Card from "components/Card/Card.jsx";

import firebase from "../config/firebase";
import "firebase/firestore";
import "firebase/storage";

import NovaMensagem from './Components/NovaMensagem';

const NoResult = ({ text }) => {
  const style = {
    noResultDiv: {
      height: "150px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "1.5em",
      color: "#ccc",
      userSelect: "none",
      fontWeight: "lighter",
    },
  };
  return <div style={style.noResultDiv}>{text}</div>;
};

const CaixaEntrada = (props) => {
  let isMounted = false;

  let monName = [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ];

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  const userEmailStore = useSelector((state) => state.userEmail);

  const columns = useMemo(
    () => [
      {
        Header: "Nome",
        accessor: (values) => {
          const qtd_msg = values.qtd_msg ? ` (${values.qtd_msg})` : '';
          if (values.tipo_mensagem === "resp") {
            if (values.anfitriao_email === userEmailStore) {
              return (
                "eu, " +
                values.envolvidos_info[1].nome +
                " " +
                values.envolvidos_info[1].sobrenome + qtd_msg
              );
            } else if (values.de.email === userEmailStore) {
              return values.anfitriao_nome + ", eu" + qtd_msg;
            } else {
              return values.anfitriao_nome + ", eu" + qtd_msg;
            }
          } else {
            return values.anfitriao_nome + qtd_msg;
          }
        },
      },
      {
        Header: "Assunto",
        accessor: (values) => {
          if (values.tipo_mensagem === "resp") {
            return "Re: " + values.assunto;
          } else return values.assunto;
        },
      },
      {
        Header: "Email",
        accessor: (values) => {
          if (values.tipo_mensagem === "resp") {
            if (values.de.email === userEmailStore) {
              return values.para;
            } else if (values.anfitriao_email === userEmailStore) {
              return values.de.email;
            } else {
              return values.de.email;
            }
          } else {
            return values.de.email;
          }
        },
      },
      {
        Header: "Data",
        accessor: (values) => {
          let dateFormated =
            values.data_auxiliar.toDate().getDate() +
            " de " +
            monName[values.data_auxiliar.toDate().getMonth()] +
            " de " +
            values.data_auxiliar.toDate().getFullYear() +
            " - " +
            values.data_auxiliar.toDate().toLocaleTimeString("pt-br");
          return dateFormated.replace(
            dateFormated.substr(-3, dateFormated.lenght),
            ""
          );
        },
      },
    ],
    []
  );
  const [messagesReceived, getMessages] = useState([]);
  const [loading, setLoading] = useState(true);

  const interacoesRef = firebase.firestore().collection("interacoes");

  useEffect(() => {
    isMounted = true;
    interacoesRef
      .where("ativos", "array-contains", userEmailStore)
      .orderBy("data", "desc")
      .limit(20)
      .onSnapshot(
        (snapshot) => {
          if (isMounted) {
            if (snapshot.empty) {
              setLoading(false);
            }
            snapshot.docChanges().map((doc) => {
              setLoading(false);
              if (doc.type === "added") {
                let dataMessages = [];
                snapshot.forEach((message) => {
                  dataMessages.push({ ...message.data(), id: message.id });
                });
                let newMessages = dataMessages.filter(
                  (x) =>
                    (x.anfitriao_email === userEmailStore &&
                      x.tipo_mensagem === "resp") ||
                    x.para === userEmailStore ||
                    (x.de.email === userEmailStore &&
                      x.tipo_mensagem === "resp")
                );
                getMessages(newMessages);
              }
              if (doc.type === "modified") {
                let dataMessages = [];
                snapshot.forEach((message) => {
                  dataMessages.push({ ...message.data(), id: message.id });
                });
                let newMessages = dataMessages.filter(
                  (x) =>
                    (x.anfitriao_email === userEmailStore &&
                      x.tipo_mensagem === "resp") ||
                    x.para === userEmailStore ||
                    (x.de.email === userEmailStore &&
                      x.tipo_mensagem === "resp")
                );
                getMessages(newMessages);
              }
              if (doc.type === "removed") {
                let dataMessages = [];
                snapshot.forEach((message) => {
                  dataMessages.push({ ...message.data(), id: message.id });
                });
                let newMessages = dataMessages.filter(
                  (x) =>
                    (x.anfitriao_email === userEmailStore &&
                      x.tipo_mensagem === "resp") ||
                    x.para === userEmailStore ||
                    (x.de.email === userEmailStore &&
                      x.tipo_mensagem === "resp")
                );
                getMessages(newMessages);
              }
            });
          }
        },
        (err) => {
          setLoading(false);
          console.log(err);
        }
      );
    return () => (isMounted = false);
  }, [firebase]);

  return (
    <div className="main-content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              title="Caixa de entrada"
              category="Todas as últimas mensagens recebidas"
              ctTableFullWidth
              ctTableResponsive
              content={
                loading ? (
                  <BarLoader
                    css={override}
                    size={150}
                    color={"#123abc"}
                    loading={loading}
                  />
                ) : messagesReceived.length > 0 ? (
                  <TableContainer columns={columns} data={messagesReceived} />
                ) : (
                  <NoResult text="Caixa de entrada vazia" />
                )
              }
            />
          </Col>
        </Row>
      </Grid>
      <NovaMensagem/>
    </div>
  );
};

export default withRouter(CaixaEntrada);
