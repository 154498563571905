import React, { useState, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import tinymce from "tinymce";
import { useSelector, useDispatch } from "react-redux";

import firebase from "../../config/firebase";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Modal,
} from "react-bootstrap";
import { css } from "@emotion/core";
import UserProfileStyle from "../../assets/css/views/UserProfile";

import BarLoader from "react-spinners/BarLoader";
import { NotificationManager } from "react-notifications";
import Card from "components/Card/Card";
import UserCard from "components/Card/UserCard";
import Button from "components/CustomButton/CustomButton";
import ButtonD from "../Components/ButtonDefault";
import CustomFileStyle from "assets/css/components/CustomFile";

const UserProfile = () => {
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();

  const [user, setUser] = useState();
  const [userName, setUserName] = useState();
  const [userLastName, setUserLastName] = useState();
  const [userMail, setUserMail] = useState();
  const [userPass, setUserPass] = useState();
  const [userPassError, setPassError] = useState();
  const [cfPass, setCfPass] = useState();
  const [cfpasswordError, setCfpasswordError] = useState();
  const [userAvatar, setUserAvatar] = useState();
  const [userFileAvatar, getUserFileAvatar] = useState();
  const [userBg, setUserBg] = useState();
  const [userFileBg, getUserFileBg] = useState();
  const [userSignature, setUserSignature] = useState();
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState();

  const userEmailStore = useSelector((state) => state.userEmail);
  const userRef = firebase.firestore().collection("users");
  const avatarRef = firebase.storage().ref();

  const updateUser = () => {
    dispatch({
      type: "LOGGED",
      userName: userName ? userName : user.data.nome,
      userLastName: userLastName ? userLastName : user.data.sobrenome,
      userEmail: userEmailStore,
      userType: user.data.tipo_usuario,
      userId: user.id,
      userAvatar: user.data.foto_perfil,
    });
    userRef
      .doc(user.id)
      .update({
        nome: userName ? userName : user.data.nome,
        sobrenome: userLastName ? userLastName : user.data.sobrenome,
        assinatura: userSignature ? userSignature : ''
      })
      .then(() => {
        NotificationManager.success("Usuário atualizado!", "Wow!");
      })
      .catch(() => {
        NotificationManager.error(
          "Não foi possível atualizar o usuário!",
          "Verifique os dados!"
        );
      });
  };

  const uploadUserBg = () => {
    let uploadTask = avatarRef
      .child(`backgrounds/${user.id}/${userFileBg.name}`)
      .put(userFileBg);
    NotificationManager.info("Aguarde!", "Anexando foto!");
    uploadTask.on(
      "state_changed",
      function (snapshot) {
        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      },
      function (error) {
        // Handle unsuccessful uploads
      },
      function () {
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          NotificationManager.success(
            "Veja o sua nova foto de fundo!",
            "Foto anexada!"
          );
          getUserFileBg(null);
          setUserBg(downloadURL);
          userRef.doc(user.id).update({
            foto_fundo: downloadURL,
          });
        });
      }
    );
  };

  const uploadUserAvatar = () => {
    let uploadTask = avatarRef
      .child(`avatares/${user.id}/${userFileAvatar.name}`)
      .put(userFileAvatar);
    NotificationManager.info("Aguarde!", "Anexando foto!");
    uploadTask.on(
      "state_changed",
      function (snapshot) {
        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      },
      function (error) {
        // Handle unsuccessful uploads
      },
      function () {
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          NotificationManager.success(
            "Veja o sua nova foto de perfil!",
            "Foto anexada!"
          );
          getUserFileAvatar(null);
          setUserAvatar(downloadURL);
          dispatch({
            type: "SET_AVATAR",
            userAvatar: downloadURL,
          });
          userRef.doc(user.id).update({
            foto_perfil: downloadURL,
          });
        });
      }
    );
  };

  const handlePasswordChange = (event) => {
    setUserPass(event.target.value);
    event.target.value.length < 6
      ? setPassError(
          <small className="text-danger">Pelo menos 6 caracteres.</small>
        )
      : setPassError(null);
  };

  const handleCfPasswordChange = (event) => {
    setCfPass(event.target.value);
    event.target.value !== userPass
      ? setCfpasswordError(
          <small className="text-danger">Senhas divergem.</small>
        )
      : setCfpasswordError(null);
  };

  const userAuth = firebase.auth().currentUser;

  const updateEmailUser = () => {
    userAuth
      .updateEmail(userMail)
      .then(() => {
        NotificationManager.info(
          "Redirecionando...",
          "E-mail atualizado, faça login novamente!"
        );
        userRef.doc(user.id).update({
          email: userMail ? userMail : user.data.email,
        });
        setTimeout(() => {
          dispatch({ type: "LOG_OUT" });
        }, 2000);
      })
      .catch(() => {
        NotificationManager.error(
          "Para atualizar o e-mail saia e entre novamente do sistema!",
          "Ops!"
        );
      });
  };
  const updatePassUser = () => {
    setShow(false);
    userAuth
      .updatePassword(userPass)
      .then(() => {
        NotificationManager.info(
          "Redirecionando...",
          "Senha atualizada, faça login novamente!"
        );
        setTimeout(() => {
          dispatch({ type: "LOG_OUT" });
        }, 2000);
      })
      .catch(() => {
        NotificationManager.error(
          "Para atualizar a senha é necessário ter feito login recentemente!",
          "Ops!"
        );
      });
  };

  useEffect(() => {
    setLoading(true);
    userRef
      .where("email", "==", userEmailStore)
      .get()
      .then((snapshot) => {
        if (!snapshot.empty) {
          setUser({ data: snapshot.docs[0].data(), id: snapshot.docs[0].id });
          setLoading(false);
        }
      });
  }, [firebase]);

  const filePickerCallback = (cb, value, meta) => {
    var input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.onchange = function () {
      var file = this.files[0];
      var reader = new FileReader();
      reader.onload = function () {
        var id = "blobid" + new Date().getTime();
        var blobCache = tinymce.activeEditor.editorUpload.blobCache;
        // var base64 = reader.result.split(',')[1];
        const dataUrlFile = reader.result;

        let uploadTask = avatarRef
          .child(file.name)
          .putString(dataUrlFile, "data_url");
        NotificationManager.info("Aguarde!", "Anexando imagem!");
        uploadTask.on(
          "state_changed",
          function (snapshot) {
            let progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          function (error) {
            // Handle unsuccessful uploads
          },
          function () {
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then(function (downloadURL) {
                NotificationManager.success(
                  "Imagem anexada!"
                );
                var blobInfo = blobCache.create(id, file, downloadURL);
                blobCache.add(blobInfo);
                cb(blobInfo.blobUri(), { title: file.name });
              });
          }
        );
      };
      reader.readAsDataURL(file);
    };

    input.click();
  };

  const changeEditor = (content) => {
    var newContent;
    if (String(content).indexOf('png') > -1) {
      newContent = content.replace('data:image/png;base64,','');
      dispatch({
        type: 'SET_SIGNATURE',
        userSignature: newContent
      });
      setUserSignature(newContent);
      return;
    } else if (String(content).indexOf('jpg') > -1) {
      newContent = content.replace('data:image/jpg;base64,','');
      dispatch({
        type: 'SET_SIGNATURE',
        userSignature: newContent
      })
      setUserSignature(newContent);
      return;
    } else if (String(content).indexOf('jpeg') > -1) {
      newContent = content.replace('data:image/jpeg;base64,','');
      dispatch({
        type: 'SET_SIGNATURE',
        userSignature: newContent
      })
      setUserSignature(newContent);
      return;
    } else {
      dispatch({
        type: 'SET_SIGNATURE',
        userSignature: content
      })
      setUserSignature(content);
      return;
    }
  }

  return (
    <UserProfileStyle>
      <div className="main-content">
        {loading ? (
          <BarLoader
            css={override}
            size={150}
            color={"#123abc"}
            loading={loading}
          />
        ) : (
          <Grid fluid>
            <Row>
              <Col md={8}>
                <Card
                  title="Editar Perfil"
                  content={
                    <form>
                      <Row>
                        <FormGroup className="col-md-5">
                          <ControlLabel>Empresa</ControlLabel>
                          <FormControl
                            type="text"
                            disabled
                            className="form-control"
                            placeholder="Empresa"
                            defaultValue="Grupo Locutores Comerciais "
                          />
                        </FormGroup>
                        <FormGroup className="col-md-6">
                          <ControlLabel>Email</ControlLabel>
                          <FormControl
                            type="email"
                            disabled={disabled}
                            placeholder="Email"
                            className="form-control"
                            defaultValue={user && user.data.email}
                            onChange={(e) => {
                              setUserMail(e.target.value);
                            }}
                          />
                          <small
                            id="emailHelp"
                            className="form-text text-muted"
                          >
                            Necessário logar novamente
                            {disabled ? (
                              <span
                                className="text-primary"
                                role="button"
                                style={{ margin: "0 5px" }}
                                onClick={() => {
                                  setDisabled(false);
                                }}
                              >
                                Alterar
                              </span>
                            ) : (
                              <React.Fragment>
                                <span
                                  className="text-primary"
                                  role="button"
                                  style={{ margin: "0 5px" }}
                                  onClick={updateEmailUser}
                                >
                                  Salvar
                                </span>
                                <span
                                  className="text-primary"
                                  role="button"
                                  onClick={() => {
                                    setDisabled(true);
                                  }}
                                >
                                  Cancelar
                                </span>
                              </React.Fragment>
                            )}
                          </small>
                        </FormGroup>
                      </Row>
                      <Row>
                        <FormGroup className="col-md-5">
                          <ControlLabel>Primeiro nome</ControlLabel>
                          <FormControl
                            type="text"
                            className="form-control"
                            placeholder="Primeiro Nome"
                            defaultValue={user && user.data.nome}
                            onChange={(e) => {
                              setUserName(e.target.value);
                            }}
                          />
                        </FormGroup>
                        <FormGroup className="col-md-6">
                          <ControlLabel>Sobrenome</ControlLabel>
                          <FormControl
                            type="text"
                            placeholder="Sobrenome"
                            className="form-control"
                            defaultValue={user && user.data.sobrenome}
                            onChange={(e) => {
                              setUserLastName(e.target.value);
                            }}
                          />
                        </FormGroup>
                      </Row>
                      <Row>
                        <FormGroup className="col-md-10">
                          <ControlLabel style={{width: '100%'}}>Assinatura: </ControlLabel>
                          <small
                            id="emailHelp"
                            className="form-text text-muted"
                          >OBS: Após inserir uma assinatura em formato de imagem, ajuste-a para o tamanho desejado. Lembrando que o tamanho definido aqui, será visto pelos seus destinatários.</small>
                          <Editor
                            initialValue={user && user.data.assinatura}
                            apiKey="3b5lzvgi3vfq7vlq7nfvub3pmonp9g382q1pz6j1o0cqztqb"
                            init={{
                              language: "pt_BR",
                              height: 350,
                              selector: "textarea#full-featured-non-premium",
                              plugins:
                                "print image preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen link template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount textpattern noneditable help charmap emoticons",
                              menubar:
                                "file edit view insert format tools table help",
                              toolbar:
                                "image | undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile template link anchor codesample | ltr rtl",
                              toolbar_sticky: true,
                              automatic_uploads: true,
                              image_title: true,
                              file_picker_types: "image",
                              file_picker_callback: filePickerCallback,
                            }}
                            onEditorChange={(content) => changeEditor(content) }
                          />
                        </FormGroup>
                      </Row>
                      <Row>
                        <FormGroup className="col-md-5">
                          <Button variant="primary" onClick={handleShow}>
                            Alterar senha
                          </Button>
                          <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                              <Modal.Title>Alterar senha</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <Row>
                                <FormGroup className="col-md-12">
                                  <ControlLabel
                                    className="form-text"
                                    style={{
                                      fontWeight: 400,
                                      textTransform: "uppercase",
                                      fontSize: "12px",
                                    }}
                                  >
                                    Nova senha <span className="star">*</span>
                                  </ControlLabel>
                                  <FormControl
                                    type="password"
                                    placeholder="Senha"
                                    className="form-control"
                                    onChange={(event) => {
                                      handlePasswordChange(event);
                                    }}
                                  />
                                  {userPassError}
                                </FormGroup>
                                <FormGroup className="col-md-12">
                                  <ControlLabel
                                    className="form-text"
                                    style={{
                                      fontWeight: 400,
                                      textTransform: "uppercase",
                                      fontSize: "12px",
                                    }}
                                  >
                                    Confirmar nova senha:{" "}
                                    <span className="star">*</span>
                                  </ControlLabel>
                                  <FormControl
                                    type="password"
                                    placeholder="Confirmar senha"
                                    onChange={(event) =>
                                      handleCfPasswordChange(event)
                                    }
                                  />
                                  {cfpasswordError}
                                </FormGroup>
                              </Row>
                            </Modal.Body>
                            <Modal.Footer>
                              <Button
                                bsStyle="danger"
                                fill
                                type="button"
                                onClick={handleClose}
                              >
                                Fechar
                              </Button>
                              <Button
                                bsStyle="success"
                                pullRight
                                fill
                                type="button"
                                onClick={updatePassUser}
                              >
                                Salvar
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        </FormGroup>
                      </Row>
                      <ButtonD
                        className="btn"
                        fRight
                        onClick={updateUser}
                      >
                        Salvar alterações
                      </ButtonD>
                      <div className="clearfix" />
                      <hr />
                      <Row>
                        <FormGroup className="col-md-5">
                          <ControlLabel>Foto de perfil</ControlLabel>
                          <CustomFileStyle>
                            <label htmlFor="customFile">
                              Selecionar uma foto de perfil &#187;
                            </label>
                            <input
                              id="customFile"
                              type="file"
                              onChange={(e) => {
                                getUserFileAvatar(e.target.files[0]);
                              }}
                            />
                            {userFileAvatar && (
                              <p
                                style={{
                                  fontWeight: 400,
                                  textTransform: "uppercase",
                                  fontSize: "12px",
                                }}
                              >
                                {userFileAvatar.name}
                              </p>
                            )}
                          </CustomFileStyle>
                          <Button
                            bsStyle="default"
                            bsSize="sm"
                            type="button"
                            style={{ marginTop: "1em" }}
                            onClick={uploadUserAvatar}
                          >
                            Salvar
                          </Button>
                        </FormGroup>
                        <FormGroup className="col-md-6">
                          <ControlLabel>Foto de fundo</ControlLabel>
                          <CustomFileStyle>
                            <label htmlFor="customFileBg">
                              Selecionar uma foto de fundo &#187;
                            </label>
                            <input
                              id="customFileBg"
                              type="file"
                              onChange={(e) => {
                                getUserFileBg(e.target.files[0]);
                              }}
                            />
                            {userFileBg && (
                              <p
                                style={{
                                  fontWeight: 400,
                                  textTransform: "uppercase",
                                  fontSize: "12px",
                                }}
                              >
                                {userFileBg.name}
                              </p>
                            )}
                          </CustomFileStyle>
                          <Button
                            bsStyle="default"
                            bsSize="sm"
                            type="button"
                            style={{ marginTop: "1em" }}
                            onClick={uploadUserBg}
                          >
                            Salvar
                          </Button>
                        </FormGroup>
                      </Row>
                    </form>
                  }
                />
              </Col>
              <Col md={4}>
                <UserCard
                  bgImage={
                    userBg
                      ? userBg
                      : user && user.data.foto_fundo
                      ? user.data.foto_fundo
                      : "https://firebasestorage.googleapis.com/v0/b/locutores-comerciais.appspot.com/o/macos-mojave-default-wallpaper-light.jpg?alt=media&token=f7f14e31-24af-466d-bd19-0f747b2ecf5c"
                  }
                  avatar={
                    userAvatar
                      ? userAvatar
                      : user && user.data.foto_perfil
                      ? user.data.foto_perfil
                      : "https://firebasestorage.googleapis.com/v0/b/locutores-comerciais.appspot.com/o/avatar-default.png?alt=media&token=77a4bd84-0ca8-420c-bd2f-d6e6e6a20d8c"
                  }
                  name={userName ? userName : user && user.data.nome}
                  lastName={
                    userLastName ? userLastName : user && user.data.sobrenome
                  }
                  email={userMail ? userMail : user && user.data.email}
                />
              </Col>
            </Row>
          </Grid>
        )}
      </div>
    </UserProfileStyle>
  );
};

export default UserProfile;
