const InitialState = {
  userEmail: null,
  userLogged: false,
  userName: null,
  userLastName: null,
  userCharge: null,
  userType: null,
  userId: null,
  userAvatar: null,
  userSignature: null,
  userNickName: null,
  userPermissions: [],
  userRemetentes: [],
};

const userReducer = (state = InitialState, action) => {
  switch (action.type) {
    case "LOG_IN":
      return {
        ...state,
        userLogged: true,
        userEmail: action.userEmail
      };
    case "LOG_OUT":
      return {
        ...state,
        userLogged: false,
        userEmail: null,
        userName: null,
        userLastName: null,
        userCharge: null,
        userType: null,
        userId: null,
        userAvatar: null,
        userNickName: null,
        userPermissions: []
      };
    case "LOGGED":
      return {
        ...state,
        userLogged: true,
        userEmail: action.userEmail,
        userName: action.userName,
        userLastName: action.userLastName,
        userCharge: action.userCharge,
        userType: action.userType,
        userId: action.userId,
        userPermissions: action.userPermissions,
        userRemetentes: action.userRemetentes,
      }
    case "SET_AVATAR":
      return {
        ...state,
        userAvatar: action.userAvatar
      }
    case "SET_REMETENTES":
      return {
        ...state,
        userRemetentes: action.userRemetentes
      }
    case "SET_SIGNATURE":
      return {
        ...state,
        userSignature: action.userSignature
      }
    default:
      return state;
  }
};

export default userReducer;
