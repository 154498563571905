import React from 'react';
import Style from 'assets/css/components/ButtonDefault';

const Button = (props) => {
  const { children, fRight } = props;
  return (
    <Style fRight={fRight}>
      <button className="btn default" {...props} type="button">
        {children}
      </button>
    </Style>
  )
}

export default Button;