import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  useTable,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table";
import {
  Table,
  Col,
  Row,
  Button,
  FormControl,
  NavDropdown,
  Nav,
  MenuItem,
} from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import SweetAlert from "react-bootstrap-sweetalert";
import Card from "components/Card/Card.jsx";
import ButtonC from "components/CustomButton/CustomButton.jsx";
import { Redirect, Link } from "react-router-dom";
import { Filter, DefaultColumnFilter } from "./Filters";
import TableStyle from "../../assets/css/components/TableContainer";
import Checkbox from "components/CustomCheckbox/CustomCheckbox.jsx";
import firebase from "firebase/app";
import "firebase/firestore";

const TableContainer = ({ columns, data, view }) => {
  const styles = {
    trStyle: {
      cursor: "pointer"
    },
    tdStyle: {
      padding: 0
    },
    linkRedirect: {
      width: "100%",
      display: "inline-block",
      color: "#000",
      padding: "12px 8px"
    }
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: { pageIndex: 0, pageSize: 20 }
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );

  const userEmailStore = useSelector((state) => state.userEmail);

  const [linkRedirectToMessage, getIdMessage] = useState();
  const [deleteAlert, setRemoveAlert] = useState();
  const [recoveryAlert, setRecoveryAlert] = useState();

  const interacoesRef = firebase.firestore().collection("interacoes");

  const markUnread = (id_message, mark) => {
    if (mark) {
      interacoesRef.doc(id_message).update({
        visualizacoes: firebase.firestore.FieldValue.arrayRemove(
          userEmailStore
        ),
      });
    } else {
      interacoesRef.doc(id_message).update({
        visualizacoes: firebase.firestore.FieldValue.arrayUnion(userEmailStore),
      });
    }
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " 🔻" : " 🔺") : "";
  };

  const recoveryMsgAlert = (id_message) => {
    setRecoveryAlert(
      <SweetAlert
        style={{ display: "block" }}
        title="Você tem certeza que deseja restaurar esta mensagem?"
        onConfirm={() => recoveryMsgFunc(id_message)}
        onCancel={() => hideAlert("recovery")}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Sim, restaurar!"
        cancelBtnText="Cancelar"
        showCancel
      ></SweetAlert>
    );
  };

  const removeMsgAlert = (id_message) => {
    setRemoveAlert(
      <SweetAlert
        warning
        style={{ display: "block" }}
        title={
          view === "lixeira"
            ? "Tem certeza que deseja excluir definitivamente?"
            : "Você tem certeza que deseja mover este item para a lixeira?"
        }
        onConfirm={() =>
          removeMsgFunc(
            id_message,
            view === "lixeira" ? "definitive" : "invoke"
          )
        }
        onCancel={() => hideAlert("remove")}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Sim, remover!"
        cancelBtnText="Cancelar"
        showCancel
      ></SweetAlert>
    );
  };

  const recoveryMsgFunc = (id_message) => {
    interacoesRef
      .doc(id_message)
      .update({
        lixeira: firebase.firestore.FieldValue.arrayRemove(userEmailStore),
        ativos: firebase.firestore.FieldValue.arrayUnion(userEmailStore),
      })
      .then(() => {
        setRecoveryAlert(null);
        NotificationManager.success("Restaurado!");
      })
      .catch(() => {
        setRecoveryAlert(null);
        NotificationManager.error("Não foi possível restaurar", "Ops!");
      });
  };

  const removeMsgFunc = (id_message, type_delete) => {
    setRemoveAlert(null);
    switch (type_delete) {
      case "invoke":
        interacoesRef
          .doc(id_message)
          .update({
            lixeira: firebase.firestore.FieldValue.arrayUnion(userEmailStore),
            ativos: firebase.firestore.FieldValue.arrayRemove(userEmailStore),
          })
          .then(() => {
            NotificationManager.info("Movido para a lixeira");
          })
          .catch(() => {
            NotificationManager.error("Não foi possível mover para a lixeira");
          });
        break;
      case "definitive":
        interacoesRef
          .doc(id_message)
          .update({
            excluido: firebase.firestore.FieldValue.arrayUnion(userEmailStore),
            ativos: firebase.firestore.FieldValue.arrayRemove(userEmailStore),
          })
          .then(() => {
            NotificationManager.info("Removido definitivamente");
          })
          .catch(() => {
            NotificationManager.error("Não foi possível remover");
          });
        break;
      default:
        break;
    }
  };

  const hideAlert = (type_alert) => {
    switch (type_alert) {
      case "recovery":
        setRecoveryAlert(null);
        break;
      case "remove":
        setRemoveAlert(null);
        break;
      default:
        break;
    }
  };

  return (
    <TableStyle>
      {linkRedirectToMessage && (
        <Redirect
          to={`/admin/enviar-mensagem/${linkRedirectToMessage}/resp`}
          exact
        />
      )}
      <Row style={{ maxWidth: 1000, margin: "0 auto", textAlign: "center" }}>
        <Col md={3}>
          <Button
            color="primary"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {"<<"}
          </Button>
          <Button
            color="primary"
            onClick={previousPage}
            disabled={!canPreviousPage}
          >
            {"<"}
          </Button>
        </Col>
        <Col md={2} style={{ marginTop: 7 }}>
          Página{" "}
          <strong>
            {pageIndex + 1} de {pageOptions.length}
          </strong>
        </Col>
        <Col md={2}>
          <FormControl
            type="number"
            min={1}
            style={{ width: 70 }}
            max={pageOptions.length}
            defaultValue={pageIndex + 1}
            onChange={onChangeInInput}
          />
        </Col>
        <Col md={2}>
          <select
            type="select"
            className="form-control"
            value={pageSize}
            onChange={onChangeInSelect}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Mostrar {pageSize}
              </option>
            ))}
          </select>
        </Col>
        <Col md={3}>
          <Button color="primary" onClick={nextPage} disabled={!canNextPage}>
            {">"}
          </Button>
          <Button
            color="primary"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </Button>
        </Col>
      </Row>
      <Table responsive hover {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                return (
                  <th {...column.getHeaderProps()}>
                    <div {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                    <Filter column={column} />
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} style={styles.trStyle}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()} style={styles.tdStyle}>
                      <Link
                        to={`/admin/enviar-mensagem/${row.original.id}/resp`}
                        exact
                        {...cell.getCellProps()}
                        className={
                          row.original.visualizacoes.includes(userEmailStore)
                            ? ""
                            : "not-read"
                        }
                        style={styles.linkRedirect}
                      >
                        {cell.render("Cell")}
                      </Link>
                    </td>
                  );
                })}
                <td style={{ display: "flex", alignItems: "center" }}>
                  {row.original.anexos.length > 0 && (
                    <i
                      className="pe-7s-paperclip"
                      style={{ color: "#000", fontWeight: "bold" }}
                    />
                  )}

                  <Nav pullRight>
                    <NavDropdown
                      eventKey={3}
                      title={
                        <div>
                          <i
                            className="pe-7s-more"
                            style={{ color: "#000", fontWeight: "bold" }}
                          />
                        </div>
                      }
                      noCaret
                      id="basic-nav-dropdown-2"
                    >
                      <MenuItem
                        eventKey={3.1}
                        onClick={() => removeMsgAlert(row.original.id)}
                      >
                        <i
                          className="pe-7s-trash"
                          style={{
                            fontWeight: "bold",
                            fontSize: "18px",
                            marginRight: "5px",
                          }}
                        />{" "}
                        {view === "lixeira"
                          ? "Remover definitivamente"
                          : "Mover para lixeira"}
                      </MenuItem>
                      <MenuItem
                        eventKey={3.1}
                        onClick={() =>
                          markUnread(
                            row.original.id,
                            row.original.visualizacoes.includes(userEmailStore)
                          )
                        }
                      >
                        <i
                          className={
                            row.original.visualizacoes.includes(userEmailStore)
                              ? "fa fa-envelope-o"
                              : "fa fa-envelope-open-o"
                          }
                          style={{
                            fontWeight: "bold",
                            fontSize: "18px",
                            marginRight: "5px",
                          }}
                        />{" "}
                        {row.original.visualizacoes.includes(userEmailStore)
                          ? "Marcar como não lido"
                          : "Marcar como lido"}
                      </MenuItem>
                      {view === "lixeira" ? (
                        <MenuItem
                          eventKey={3.2}
                          onClick={() => recoveryMsgAlert(row.original.id)}
                        >
                          <i
                            className="pe-7s-refresh"
                            style={{
                              fontWeight: "bold",
                              fontSize: "18px",
                              marginRight: "5px",
                            }}
                          />{" "}
                          Restaurar
                        </MenuItem>
                      ) : (
                        ""
                      )}
                    </NavDropdown>
                  </Nav>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      {recoveryAlert}
      {deleteAlert}
    </TableStyle>
  );
};

export default TableContainer;
