import firebase from "firebase/app";

var firebaseConfig = {
  apiKey: "AIzaSyANjXvj63V-hxVfewfar20IA-rRi8QEQlY",
  authDomain: "locutores-comerciais.firebaseapp.com",
  databaseURL: "https://locutores-comerciais.firebaseio.com/",
  projectId: "locutores-comerciais",
  storageBucket: "locutores-comerciais.appspot.com",
  messagingSenderId: "1039258285910",
  appId: "1:1039258285910:web:d524cd442cdbb9292888f7",
  measurementId: "G-R9TBC8D2KW",
};

let firebaseDb = firebase.initializeApp(firebaseConfig);
// firebase.analytics();
// Initialize Firebase
export default firebaseDb;
