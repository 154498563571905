import React, { useState, useEffect, useCallback } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import MicBtn from "./MicBtn";
import PreviewRecord from "./PreviewRecord";
import Scrollbar from "perfect-scrollbar-react";
import "perfect-scrollbar-react/dist/style.min.css";
import AutosuggestStyle from "assets/css/components/AutoSuggest";
import VerifyMessageStyle from "assets/css/components/VerifyMessage";
import TextEditorStyle from "assets/css/components/TextEditor";
import { NewMessageStyle } from "assets/css/components/NewMessage";
import Autosuggest from "react-autosuggest";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  ProgressBar,
  Table,
  Tooltip,
  OverlayTrigger,
  Modal,
} from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import { NotificationManager } from "react-notifications";

import firebase from "firebase/app";
import "firebase/firestore";

const avatarDefault =
  "https://firebasestorage.googleapis.com/v0/b/locutores-comerciais.appspot.com/o/avatar-default.png?alt=media&token=77a4bd84-0ca8-420c-bd2f-d6e6e6a20d8c";

const NovaMensagem = () => {
  let isMounted = false;

  const [active, setActive] = useState(false);
  const [dataMessage, setDataMessage] = useState();
  const [messageUser, setMessageUser] = useState();
  const [subjectUser, setSubjectUser] = useState();
  const [addressOf, setAddressOf] = useState();
  const [progress, setProgress] = useState();
  const [files, setFiles] = useState([]);
  const [nameFiles, setName] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [show, setShow] = useState(false);
  const [showVerify, setShowVerify] = useState(false);
  const [redirectMailBox, setRedirectMailBox] = useState(false);
  const [fileNameModal, setFileNameModal] = useState();
  const [pathFileModal, setPathFileModal] = useState();
  const [infoFileModal, setInfoFileModal] = useState();
  const [emailUsers, setEmailUsers] = useState([]);
  const [valueSugg, setStateValue] = useState();
  const [suggestions, setStateSugges] = useState([]);
  const [creditsUser, setCreditsUser] = useState();
  const [verifyCredits, setVerifyCredits] = useState(false);
  const [loading, setLoading] = useState(true);
  const [verifyMessage, setVerifyMessage] = useState(false);
  const [sendSuccessful, setSendSuccessful] = useState(false);
  const [idTimeOut, setIdTimeOut] = useState(false);
  const [recording, setRecording] = React.useState(false);
  const [audioSavedUrl, setAudioSavedUrl] = React.useState();
  const [changeNamePage, setChangeNamePage] = React.useState(false);
  const [infoRemetentes, setInfoRemetentes] = React.useState([]);

  const interacoesRef = firebase.firestore().collection("interacoes");
  const usersRef = firebase.firestore().collection("users");
  const storageRef = firebase.storage().ref("anexos-enviados");

  const userEmailStore = useSelector((state) => state.userEmail);
  const userChargeStore = useSelector((state) => state.userCharge);
  const userIdStore = useSelector((state) => state.userId);
  const userNameStore = useSelector((state) => state.userName);
  const userAvatarStore = useSelector((state) => state.userAvatar);
  const userLastNameStore = useSelector((state) => state.userLastName);
  const userSignatureStore = useSelector((state) => state.userSignature);
  const userPermissionsStore = useSelector((state) => state.userPermissions);
  const userRemetentesStore = useSelector((state) => state.userRemetentes);

  const remove = <Tooltip id="remove">Remover</Tooltip>;
  const download = <Tooltip id="baixar">Baixar</Tooltip>;
  const play = <Tooltip id="play">Escutar</Tooltip>;

  function formatFileSize(bytes, decimalPoint) {
    if (bytes == 0) return "0 Bytes";
    var k = 1000,
      dm = decimalPoint || 2,
      sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        const dataUrlFile = reader.result;
        setName((prevState) => [
          ...prevState,
          { name: file.name, size: formatFileSize(file.size) },
        ]);
        let fileUpload = storageRef
          .child(file.name)
          .putString(dataUrlFile, "data_url");
        fileUpload.on(
          "state_changed",
          function (snapshot) {
            var progressTransfer =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgress(progressTransfer);
          },
          function (error) {
            // Tratamento para erros de upload
          },
          function () {
            fileUpload.snapshot.ref
              .getDownloadURL()
              .then(function (downloadURL) {
                let fileSend = {
                  filename: file.name,
                  path: downloadURL,
                  size: formatFileSize(file.size),
                };
                setFiles((prevState) => [...prevState, fileSend]);
              });
          }
        );
      };
      reader.readAsDataURL(file);
    });
  });

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const getUrl = (type) => {
    setAudioSavedUrl(type);
  };

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : emailUsers.filter(
          (email) =>
            email.email.toLowerCase().slice(0, inputLength) === inputValue
        );
  };

  const getSuggestionValue = (suggestion) => suggestion.email;

  const renderSuggestion = (suggestion) => (
    <div>
      {suggestion.nome + " " + suggestion?.sobrenome + " - " + suggestion.email}
    </div>
  );
  const onChange = (event, { newValue }) => {
    setStateValue(newValue);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setStateSugges(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setStateSugges([]);
  };

  const inputProps = {
    value: typeof valueSugg !== "undefined" ? valueSugg : "",
    onChange: onChange,
  };

  const verifyPermissions = (perm) => {
    if (perm.hasOwnProperty("SugesTodosEmails")) {
      if (perm.SugesTodosEmails.active) {
        usersRef.get().then((snapshot) => {
          let result = [];
          snapshot.docs.forEach((doc) => {
            result.push({
              email: doc.data().email,
              id: doc.id,
              nome: doc.data().nome,
              sobrenome: doc.data().sobrenome,
            });
          });
          setEmailUsers(result);
        });
        return;
      } else {
        setEmailUsers([]);
      }
    }
    if (perm.hasOwnProperty("SugesRemetentes")) {
      if (perm.SugesRemetentes.active) {
        usersRef
          .doc(userIdStore)
          .get()
          .then((doc) => {
            setInfoRemetentes(doc.data().remetentes);
          })
          .catch(() => {
            setInfoRemetentes([]);
          });
        return;
      } else {
        setEmailUsers([]);
      }
    }
    if (
      perm.hasOwnProperty("SugesTodosEmails") &&
      perm.hasOwnProperty("SugesRemetentes")
    ) {
      if (perm.SugesRemetentes.active && perm.SugesTodosEmails.active) {
        usersRef.get().then((snapshot) => {
          let result = [];
          snapshot.docs.forEach((doc) => {
            result.push({
              email: doc.data().email,
              id: doc.id,
              nome: doc.data().nome,
              sobrenome: doc.data().sobrenome,
            });
          });
          setEmailUsers(result);
        });
        return;
      }
    }
  };

  useEffect(() => {
    if (!infoRemetentes) return;
    if (!infoRemetentes.length) return;
    let result = [];
    infoRemetentes.forEach((info) => {
      usersRef
        .doc(info.id)
        .get()
        .then((data) => {
          result.push({
            email: data.data().email,
            id: data.id,
            nome: data.data().nome,
            sobrenome: data.data().sobrenome,
          });
        });
    });
    setEmailUsers(result);
  }, [infoRemetentes]);

  useEffect(() => {
    isMounted = true;
    if (isMounted) {
      userPermissionsStore.filter(verifyPermissions);
      setDisabled(false);
      setDataMessage(null);
    }
    return () => (isMounted = false);
  }, [firebase]);

  const removeFile = (index, name) => () => {
    let item = nameFiles.findIndex((x) => x === name);
    let listNames = [...nameFiles];
    listNames.splice(item, 1);
    let listFiles = [...files];
    listFiles.splice(index, 1);
    setFiles(listFiles);
    setName(listNames);
  };

  const increment = firebase.firestore.FieldValue.increment(1);

  const [existRemetente, setExisteRemetente] = useState(false);

  const sendFirstMessage = async () => {
    var envolvido_info = [];
    const sender = valueSugg ? valueSugg : addressOf ? addressOf : null;
    if (emailUsers) {
      envolvido_info = emailUsers.filter((x) => x.email === sender);
    }
    var id;
    if (!envolvido_info.length) {
      await usersRef
        .where("email", "==", addressOf)
        .get()
        .then((snapshot) => {
          envolvido_info = [
            {
              email: snapshot.docs[0].data().email,
              id: snapshot.docs[0].id,
              nome: snapshot.docs[0].data().nome,
              sobrenome: snapshot.docs[0].data().sobrenome,
            },
          ];
        });
      usersRef
        .where("email", "==", sender)
        .get()
        .then((snapshot) => {
          id = snapshot.docs[0].id;
        });
    }
    if (envolvido_info.length) {
      await usersRef
        .where("email", "==", envolvido_info[0].email)
        .get()
        .then((snapshot) => {
          id = snapshot.docs[0].id;
        });
    }
    if (id) {
      userRemetentesStore.filter((x) => {
        if (x.email === sender) {
          setExisteRemetente(true);
        }
      });
      if (!existRemetente) {
        await usersRef.doc(id).update({
          remetentes: firebase.firestore.FieldValue.arrayUnion({
            email: userEmailStore,
            id: userIdStore,
            nome: userNameStore,
            sobrenome: userLastNameStore,
          }),
        });
      }
    }
    interacoesRef
      .add({
        qtd_msg: increment,
        visualizacoes: [userEmailStore],
        ativos: [
          userEmailStore,
          valueSugg ? valueSugg : addressOf ? addressOf : null,
        ],
        lixeira: [],
        excluido: [],
        envolvidos: [
          userEmailStore,
          valueSugg ? valueSugg : addressOf ? addressOf : null,
        ],
        envolvidos_info: [
          {
            nome:
              userNameStore && userLastNameStore
                ? userNameStore + " " + userLastNameStore
                : userNameStore,
          },
          envolvido_info[0],
        ],
        anfitriao_email: userEmailStore,
        anfitriao_nome:
          userNameStore && userLastNameStore
            ? userNameStore + " " + userLastNameStore
            : userNameStore,
        tipo_mensagem: "init",
        assunto: subjectUser ? subjectUser : null,
        de: {
          email: userEmailStore,
          nome: userNameStore,
          nomeCompleto:
            userNameStore && userLastNameStore
              ? userNameStore + " " + userLastNameStore
              : userNameStore,
          avatar: userAvatarStore ? userAvatarStore : avatarDefault,
          assinatura: userSignatureStore ? userSignatureStore : "",
        },
        mensagem: messageUser ? messageUser : null,
        para: valueSugg ? valueSugg : addressOf ? addressOf : null,
        data: firebase.firestore.FieldValue.serverTimestamp(),
        data_auxiliar: firebase.firestore.FieldValue.serverTimestamp(),
        anexos: files ? files : null,
        lida: false,
        history: firebase.firestore.FieldValue.arrayUnion({
          de: {
            email: userEmailStore,
            nome: userNameStore,
            nomeCompleto:
              userNameStore && userLastNameStore
                ? userNameStore + " " + userLastNameStore
                : userNameStore,
            avatar: userAvatarStore ? userAvatarStore : avatarDefault,
            assinatura: userSignatureStore ? userSignatureStore : "",
          },
          mensagem: messageUser ? messageUser : null,
          para: valueSugg ? valueSugg : addressOf ? addressOf : null,
          data: firebase.firestore.Timestamp.now(),
          anexos: files ? files : null,
          audio: audioSavedUrl ? audioSavedUrl : null,
        }),
      })
      .then(() => {
        NotificationManager.success("Mensagem enviada!", "Sucesso");
        setSendSuccessful(true);
        setTimeout(() => {
          setVerifyMessage(false);
          setSubjectUser("");
          setMessageUser("");
          setStateValue("");
          setActive(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
        NotificationManager.error("Não foi possível enviar a mensagem", "Ops!");
      });
  };

  useEffect(() => {
    if (!verifyMessage) return;
    const id = window.setTimeout(() => {
      sendFirstMessage();
    }, 2000);
    setIdTimeOut(id);
  }, [verifyMessage]);

  const cancelSend = () => {
    NotificationManager.info("Envio cancelado");
    setVerifyMessage(false);
    window.clearTimeout(idTimeOut);
  };

  return (
    <NewMessageStyle>
      {
        <div
          className={`contain_message ${active ? "aumenta" : "gridContainer"}`}
        >
          <div className="principal">
            <div className="divMensagem" onClick={() => setActive(!active)}>
              <p className="newMensagem">Nova mensagem</p>
              <div
                className={`action-contain ${
                  active ? "low" : "action-contain"
                }`}
              >
                <i className="icon-color fa fa-minus"></i>
              </div>
            </div>
          </div>
            <Scrollbar>
          <div className={active ? "act_content" : "content"}>
              <div className="section">
                <label className="labeltext">
                  Para:
                  {emailUsers && emailUsers.length > 0 ? (
                    <AutosuggestStyle>
                      <Autosuggest
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={
                          onSuggestionsFetchRequested
                        }
                        onSuggestionsClearRequested={
                          onSuggestionsClearRequested
                        }
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={inputProps}
                      />
                    </AutosuggestStyle>
                  ) : (
                    <FormControl
                      type="email"
                      value={addressOf}
                      onChange={(e) => setAddressOf(e.target.value)}
                    />
                  )}
                </label>
                <hr />
              </div>

              <div className="section">
                <input
                  className="inputStyle"
                  placeholder="Assunto"
                  value={subjectUser}
                  type="text"
                  onChange={(e) => setSubjectUser(e.target.value)}
                />
                <hr />
              </div>

              <div className="section">
                <label className="labeltext">Sua mensagem</label>
                <TextEditorStyle>
                  <Editor
                    apiKey="3b5lzvgi3vfq7vlq7nfvub3pmonp9g382q1pz6j1o0cqztqb"
                    inline={true}
                    init={{
                      language: "pt_BR",
                      height: 300,
                      selector: "textarea#full-featured-non-premium",
                      plugins:
                        "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen link template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount textpattern noneditable help charmap emoticons",
                      menubar: "file edit view format tools table help",
                      toolbar:
                        "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile template link anchor codesample | ltr rtl",
                      toolbar_sticky: true,
                    }}
                    value={messageUser}
                    onEditorChange={(content) => setMessageUser(content)}
                  />
                </TextEditorStyle>
              </div>
              <div className="section">
                <div
                  {...getRootProps()}
                  className="files_contain"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "100px",
                    alignItems: "center",
                  }}
                >
                  <input {...getInputProps()} />
                  <p className="text-muted">
                    Arraste ou clique para selecionar
                  </p>
                </div>
                <div className="files">
                  {nameFiles.length > 0 && (
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>Nome</th>
                          <th className="text-right">Ações</th>
                        </tr>
                      </thead>
                      <tbody>
                        {nameFiles.map((file, key) => {
                          return (
                            <tr key={key}>
                              <td>{file.name + " - " + file.size}</td>
                              <td className="td-actions text-right">
                                <OverlayTrigger
                                  placement="top"
                                  overlay={remove}
                                >
                                  <Button
                                    simple
                                    bsStyle="danger"
                                    bsSize="xs"
                                    onClick={removeFile(key, file.name)}
                                  >
                                    <i className="fa fa-times" />
                                  </Button>
                                </OverlayTrigger>
                              </td>
                            </tr>
                          );
                        })}
                        <tr>
                          <td>
                            {progress < 100 && files.length < 1 ? (
                              <>
                                <p>Enviando...</p>
                                <ProgressBar
                                  now={progress}
                                  className="progress-line-primary"
                                  label="30% Complete"
                                  srOnly
                                />
                              </>
                            ) : files.length > 0 ? (
                              ""
                            ) : (
                              <>
                                <p>Enviando...</p>
                                <ProgressBar
                                  now={progress}
                                  className="progress-line-primary"
                                  label="30% Complete"
                                  srOnly
                                />
                              </>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  )}
                </div>
              </div>
              <div className="section footer">
                <button
                  className="btnStyle"
                  onClick={() => setVerifyMessage(true)}
                >
                  Enviar
                </button>
                <div className="action-footer">
                  <div>
                    <MicBtn
                      url={getUrl}
                      recording={recording}
                      setRecording={setRecording}
                    />
                  </div>
                </div>
              </div>
          </div>
            </Scrollbar>
        </div>
      }
      <VerifyMessageStyle view="caixa_entrada">
        <div
          className={`${
            verifyMessage ? "enable-verify" : "disable-verify"
          } verify-message`}
        >
          {!sendSuccessful ? (
            <p>
              Enviando...&nbsp;&nbsp;
              <span onClick={cancelSend}>Cancelar</span>
            </p>
          ) : (
            <p>Mensagem enviada</p>
          )}
        </div>
      </VerifyMessageStyle>
    </NewMessageStyle>
  );
};

export default NovaMensagem;
