import styled from 'styled-components';

const SliderAudio = styled.div`
  .rangeslider__handle {
    width: 15px;
    height: 15px;
    border: none;
    box-shadow: 2px 3px 6px -1px #ccc;
    outline: none;
    &::after {
      display: none;
    }
    .rangeslider__handle-tooltip {
      display: none;
    }
  }
  .rangeslider-horizontal {
    width: 150px;
    height: 6px;
    .rangeslider__fill {
      background-color: #447df7;
    }
  }
`
export default SliderAudio;