import React, { useState } from "react";
import KeyboardEventHandler from "react-keyboard-event-handler";
import { Link } from "react-router-dom";
import { css } from "@emotion/core";
import RecoveryStyle from "assets/css/views/Login";
import { NotificationContainer, NotificationManager } from "react-notifications";

import { useSelector, useDispatch } from "react-redux";

import firebase from "../../config/firebase";
import "firebase/auth";

const Recovery = () => {
  const [email, setEmail] = useState();
  const [password, setPass] = useState();

  const dispatch = useDispatch();

  const resetPassWord = () => {
    if (email) {
      firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => {
          NotificationManager.success(
            "Acesso o e-mail para redefinir sua senha!"
          );
        });
    } else {
      NotificationManager.error("Por favor digite um e-mail válido!");
    }
  };

  return (
    <RecoveryStyle>
      <NotificationContainer/>
      <div className="main off-canvas-sidebar" cz-shortcut-listen="true">
        <div className="login-dir">
          <div />
          <img src="https://firebasestorage.googleapis.com/v0/b/locutores-comerciais.appspot.com/o/imagem-glc.svg?alt=media&token=0c1f6b46-2eda-4e10-9bf4-7401c01ef13d" />
        </div>
        <div className="login-esq">
          <div className="login-esq-contain">
            <div className="login-cabecalho">
              <img className="mb-4" src="https://firebasestorage.googleapis.com/v0/b/locutores-comerciais.appspot.com/o/logo-oficial.svg?alt=media&token=81c4074d-fe03-47a5-bebd-b407284fce6d" alt="" width="200" />
            </div>
            <div className="form-signin">
              <div className="login-cabecalho-mobile">
                <img
                  className="mb-4"
                  src="https://firebasestorage.googleapis.com/v0/b/locutores-comerciais.appspot.com/o/logo-oficial.svg?alt=media&token=81c4074d-fe03-47a5-bebd-b407284fce6d"
                  alt=""
                  width="200"
                />
              </div>
              <form>
                <div className="content">
                  <div className="form-group">
                    <h1 className="login-color-text">Recuperar senha</h1>
                    <p>
                      Digite seu email, e acesse sua caixa de entrada para
                      redefinir a senha.
                    </p>
                    <div className="col-3 input-effect">
                      <input
                        placeholder="E-mail"
                        name="email"
                        onChange={(event) => setEmail(event.target.value)}
                        autocomplete="on"
                        type="email"
                        className="effect-16"
                        id="input-email"
                      />
                      <span className="focus-border"></span>
                    </div>

                    <div className="reset-password" onClick={resetPassWord}>
                      <button type="button" className="resetpassword btnLogin">
                        Confirmar
                      </button>
                    </div>

                    <div className="voltar-login">
                      <Link to="/auth/login-page" exact>Voltar para tela de login</Link>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="login-footer">
              <p style={{fontSize: '11px', textAlign: 'center'}}>
                Grupo Locutores Comerciais &reg; Todos os Direitos Reservados
              </p>
            </div>
          </div>
        </div>
      </div>
    </RecoveryStyle>
  );
};

export default Recovery;
