import styled from "styled-components";

const NewMessageStyle = styled.div`
  .contain_message {
    position: fixed;
    right: 32px;
    bottom: 0px;
    box-shadow: 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%),
      0 5px 5px -3px rgb(0 0 0 / 20%);
  }
  .gridContainer {
    width: 350px;
  }
  .aumenta {
    width: 625px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .content {
    display: none;
  }
  .act_content {
    display: block;
    height: 485px;
  }
  .principal {
    display: flex;
    justify-content: space-between;
    background-color: #444;
    color: #fff;
    align-items: baseline;
    line-height: 40px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    cursor: pointer;
  }
  .newMensagem {
    font-weight: bold;
    margin: 0;
    font-size: 14px;
  }
  .actions_contain {
    i.fa {
      color: #fff;
      text-align: center;
    }
    i.fa:hover {
      color: #fff;
    }
  }
  .icon-color:hover {
    background-color: #aaa;
  }
  .low {
    position: relative;
    top: 8px;
  }
  .actions_send {
    i.fa {
      color: #ffa000;
    }
  }
  .section {
    padding: 10px;
    padding-bottom: 8px;
    padding-top: 8px;
    border: none;
    line-height: 20px;
    min-height: auto;
    background-color: #fff;
    .labeltext:first-child {
      display: flex;
      width: 100%;
    }
    .react-autosuggest__input, input {
      border: none;
      padding: 0;
      height: initial;
    }
    hr {
      width: 100%;
    }
  }
  .labeltext {
    padding-right: 5px;
    font-size: inherit;
    font-weight: 400;
    color: #797979;
    div {
      width: 100%;
    }
  }
  .inputStyle {
    border: none;
  }
  .campoText {
    width: 100%;
    border: none;
    resize: none;
    outline: none;
  }
  .btnStyle {
    background-color: #ffa000;
    border: 1px solid #ffb800;
    font-size: 14px;
    padding: 8px;
    color: #fff;
    border-radius: 5px;
    outline: none;
    margin: 10px 0 10px 0;
  }
  .divMensagem {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
  }
  hr {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .footer {
    display: flex;
  }
  .gridContainer1 {
    right: 32px;
    width: 550px;
    bottom: 0px;
  }
  .action-footer {
    display: flex;
    align-items: center;
    font-size: 20px;
    width: 20px;
    margin-right: 10px;
    padding: 10px;
  }
  .action-clip {
    font-size: 20px;
    width: 20px;
    margin-right: 10px;

    .icon-paper {
      transform: rotate(45deg);
    }
  }
`;
export { NewMessageStyle };
