import styled from "styled-components";

const TextEditor = styled.div`
  position: relative;
  .mce-content-body {
    height: 175px;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    outline: none;
    padding: 8px 12px;
    overflow-y: scroll;
  }
`;
export default TextEditor;
