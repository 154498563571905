export const optionsUserFunction = [
  { value: "Nenhum", label: "Nenhum" },
  // { value: "Gravação de Offs", label: "Gravação de Offs" },
  // {
  //   value: "Atendente e Vendedor",
  //   label: "Atendente e Vendedor",
  // },
  // { value: "Revisão de Textos", label: "Revisão de Textos" },
  // {
  //   value: "Edição e Produção de Áudio",
  //   label: "Edição e Produção de Áudio",
  // },
  // {
  //   value: "Controle de Finanças",
  //   label: "Controle de Finanças",
  // },
  // {
  //   value: "Redação de Textos",
  //   label: "Redação de Textos",
  // },
  {
    value: "Administrador",
    label: "Administração da Plataforma",
  },
];

export const optionStatus = [
  {
    value: "Gravando",
    label: "Gravando",
  },
  {
    value: "Offline",
    label: "Offline",
  },
  {
    value: "Volto em instantes",
    label: "Volto em instantes",
  },
  {
    value: "Personalizado",
    label: "Personalizado",
  },
];

export const optionsUserCharge = [
  { value: "Nenhum", label: "Nenhum" },
  {
    value: "Locutor",
    label: "Locutor",
  },
  {
    value: "Gerente Comercial",
    label: "Gerente Comercial",
  },
  {
    value: "Revisor",
    label: "Revisor",
  },
  {
    value: "Produtor",
    label: "Produtor",
  },
  {
    value: "Redator",
    label: "Redator",
  },
  {
    value: "Diretor",
    label: "Diretor",
  },
  {
    value: "Vice-Diretor",
    label: "Vice-Diretor",
  },
  {
    value: "Controle de Finanças",
    label: "Controle de Finanças",
  },
];

export const optionsUserRemuneration = [
  { value: "Nenhum", label: "Nenhum" },
  {
    value: "Comissão do site em que atua",
    label: "Comissão do site em que atua",
  },
  {
    value: "Salário mínimo",
    label: "Salário mínimo",
  },
  {
    value: "Salário mínimo + comissão do site em que atua",
    label: "Salário mínimo + comissão do site em que atua",
  },
  {
    value: "Valor negociado a parte",
    label: "Valor negociado a parte",
  },
  {
    value: "Comissão geral da empresa",
    label: "Comissão geral da empresa",
  },
  {
    value: "Remuneração de Pacotes",
    label: "Remuneração de Pacotes",
  },
  {
    value: "Salário mínimo + comissão geral da empresa",
    label: "Salário mínimo + comissão geral da empresa",
  },
];

export const optionsUserHiring = [
  { value: "Nenhum", label: "Nenhum" },
  {
    value: "Carteira Assinada",
    label: "Carteira Assinada",
  },
  {
    value: "Freelancer",
    label: "Freelancer",
  },
];

export const optionDefault = [{ label: "Nenhum", value: "Nenhum" }];

export const defaultPermissions = [
  {
    SugesTodosEmails: {
      active: false,
      text: "Sugestão de todos os e-mails cadastrados na plataforma",
    },
  },
  {
    SugesRemetentes: {
      active: false,
      text: "Sugerir e-mails apenas de remetentes",
    },
  },
  { VerTipoRemuneracao: { active: false, text: "Ver tipo de remuneração" } },
  { VerTipoContratacao: { active: false, text: "Ver tipo de contratação" } },
  {
    VerRelatorioLocutores: {
      text: "Ver relatório dos locutores",
      active: false,
    },
  },
  { BotaoStatus: { active: false, text: "Botão de status" } },
];