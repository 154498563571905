import React from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

const UserCard = ({
  bgImage,
  onChangePhotoBg,
  avatar,
  onChangePhotoAvatar,
  name,
  lastName,
  email,
  description,
  socials,
  children,
  removeBgFunc,
  removeAvatarFunc
}) => {
  const removeBgImage = <Tooltip id="removeBgImage">Remover foto de fundo</Tooltip>;
  const alterBgImage = <Tooltip id="alterBgImage">Alterar foto de fundo</Tooltip>;
  const removeAvatarImage = <Tooltip id="removeAvatarImage">Remover foto de perfil</Tooltip>;
  const alterAvatarImage = <Tooltip id="alterAvatarImage">Alterar foto de perfil</Tooltip>;
  return (
    <div className="card card-user">
      <div className="image">
        <img src={bgImage} alt="..." />
        <div className="options-bg">
          <label htmlFor="backgroundFile">
            <OverlayTrigger placement="top" overlay={alterBgImage}>
              <i className="fa fa-pencil" title="Alterar foto de fundo" />
            </OverlayTrigger>
            <input
              id="backgroundFile"
              type="file"
              accept="image/*"
              onChange={onChangePhotoBg}
            />
          </label>
          <OverlayTrigger placement="top" overlay={removeBgImage}>
            <i className="fa fa-trash" title="Remover foto de fundo" onClick={removeBgFunc}/>
          </OverlayTrigger>
        </div>
      </div>
      <div className="content">
        <div className="author">
          <div className="contain-avatar">
            <img className="avatar border-gray" src={avatar} alt="..." />
            {onChangePhotoAvatar && (
              <div className="options-avatar">
                <label htmlFor="avatarFile">
                  <OverlayTrigger placement="top" overlay={alterAvatarImage}>
                    <i className="fa fa-pencil" title="Alterar foto de perfil" />
                  </OverlayTrigger>
                  <input
                    id="avatarFile"
                    type="file"
                    accept="image/*"
                    onChange={onChangePhotoAvatar}
                  />
                </label>
                <OverlayTrigger placement="top" overlay={removeAvatarImage}>
                  <i className="fa fa-trash" title="Remover foto de perfil" onClick={removeAvatarFunc}/>
                </OverlayTrigger>
              </div>
            )}
          </div>
          <h4 className="title">
            {name + " " + lastName}
            <br />
            <small>{email}</small>
          </h4>
        </div>
        <p className="description text-center">{description}</p>
      </div>
      <hr />
      <div className="text-center">{socials}</div>
      {children}
    </div>
  );
};

export default UserCard;
