import styled from "styled-components";

const VerifyMessage = styled.div`
  .verify-message {
    background: #fff;
    box-shadow: ${props => props.view === 'caixa_entrada' ? 'none' : '2px 3px 6px 1px #ccc'};
    height: 55px;
    position: fixed;
    bottom: 30px;
    right: ${props => props.view === 'caixa_entrada' ? 'inherit' : '45px'};
    left: ${props => props.view === 'caixa_entrada' ? '45px' : 'inherit'};
    border-radius: 5px;
    padding: 19px 40px;
    z-index: 1;
    p {
      color: #9a9a9a;
      font-size: 14px;
      span {
        margin-left: 10px;
        color: #1717de;
        font-weight: 600;
        cursor: pointer;
      }
    }
  }
  .disable-verify {
    display: none;
  }
  .enable-verify {
    display: block;
  }
`;
export default VerifyMessage;
