import React, { useState } from "react";
import KeyboardEventHandler from "react-keyboard-event-handler";
import { Link } from 'react-router-dom';
import { css } from "@emotion/core";
import LoginStyle from "../../assets/css/views/Login";
import { Redirect } from "react-router-dom";
import {
  NotificationManager,
} from "react-notifications";

import { useSelector, useDispatch } from "react-redux";

import firebase from "../../config/firebase";
import "firebase/auth";

const LoginPage = () => {

  const [email, setEmail] = useState();
  const [emailErrorLogin, setEmailError] = useState();
  const [password, setPass] = useState();
  const [passErrorLogin, setPassError] = useState();
  const [loading, setLoading] = useState();
  const [loginError, setLoginError] = useState();
  const [loginSuccess, setLoginSuccess] = useState();

  const dispatch = useDispatch();

  const logInto = () => {
    if (email === undefined || password === undefined) {
      setLoginError(
        <p className="text-warning text-center login-text">
          <u>
            <i>Preencha todos os campos</i>
          </u>
        </p>
      );
      return;
    }
    setLoading(true);
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        setLoading(false);
        setLoginSuccess(
          <p className="text-success text-center login-text">
            Login efetuado com successo!
          </p>
        );
        setTimeout(() => {
          dispatch({
            type: "LOG_IN",
            userEmail: email,
            userLogged: true,
          });
        }, 800);
        return null;
      })
      .catch((err) => {
        if (err.code === "auth/user-disabled") {
          setLoginError(
            <p className="text-danger text-center login-text">
              <u>
                <i>Conta desativada contate o administrador.</i>
              </u>
            </p>
          );
        } else {
          setLoginError(
            <p className="text-danger text-center login-text">
              <u>
                <i>E-mail ou senha incorretos.</i>
              </u>
            </p>
          );
        }
        setLoading(false);
      });
  };
  const handleLoginEmail = (event) => {
    setLoginError(null);
    setEmail(event.target.value);
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    re.test(event.target.value) === false
      ? setEmailError(
          <small className="text-danger">
            O e-mail é obrigatório e o formato deve ser <i>john@doe.com</i>.
          </small>
        )
      : setEmailError(null);
  };
  const handleLoginPassword = (event) => {
    setLoginError(null);
    setPass(event.target.value);
    event.target.value.length < 6
      ? setPassError(
          <small className="text-danger">
            Você deve inserir uma senha de pelo menos 6 caracteres.
          </small>
        )
      : setPassError(null);
  };

  const resetPassWord = () => {
    if (email) {
      firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => {
          NotificationManager.success(
            "Acesso o e-mail para redefinir sua senha!"
          );
        });
    } else {
      NotificationManager.error("Por favor digite um e-mail válido!");
    }
  };

  return (
    <LoginStyle>
      {
        useSelector(state => state.userLogged) ? <Redirect to="/admin/painel-de-controle" /> : null
      }
      <div className="main off-canvas-sidebar" cz-shortcut-listen="true">
      <div class="login-dir">
        <div />
        <img src="https://firebasestorage.googleapis.com/v0/b/locutores-comerciais.appspot.com/o/imagem-glc.svg?alt=media&token=0c1f6b46-2eda-4e10-9bf4-7401c01ef13d" />
      </div>
      <div className="login-esq">
        <div className="login-esq-contain">
          <div className="login-cabecalho">
            <img className="mb-4" src="https://firebasestorage.googleapis.com/v0/b/locutores-comerciais.appspot.com/o/logo-oficial.svg?alt=media&token=81c4074d-fe03-47a5-bebd-b407284fce6d" alt="" width="200" />
          </div>
          <div className="form-signin">
            <div className="login-cabecalho-mobile">
              <img className="mb-4" src="https://firebasestorage.googleapis.com/v0/b/locutores-comerciais.appspot.com/o/logo-oficial.svg?alt=media&token=81c4074d-fe03-47a5-bebd-b407284fce6d" alt="" width="200" />
            </div>
            <form>
              <div className="content">
                <div className="form-group">
                  <h1 className="login-color-text">Acessar Plataforma</h1>
                  <div className="col-3 input-effect">
                    <input
                      placeholder="E-mail"
                      name="email"
                      onChange={event => handleLoginEmail(event)}
                      autocomplete="on"
                      type="email"
                      className="effect-16"
                      id="input-email"
                    />
                    <span className="focus-border"></span>
                    {email === '' ? null : emailErrorLogin}
                  </div>
                  <div className="col-3 input-effect">
                    <input
                      placeholder="Senha"
                      autocomplete="off"
                      onChange={event => handleLoginPassword(event)}
                      type="password"
                      className="effect-16"
                      id="input-senha"
                    />
                    <span className="focus-border"></span>
                    {password === '' ? null : passErrorLogin}
                  </div>

                  <div className="text-center" onClick={logInto}>
                    <button type="button" className="btnLogin">
                      Entrar
                    </button>
                  </div>
                  <KeyboardEventHandler handleFocusableElements handleKeys={['enter']} onKeyEvent={logInto} />
                  {loginSuccess ? loginSuccess : loginError}
                  <div className="reset-password">
                    <Link to="/auth/recuperar-senha" exact>Esqueceu sua senha?</Link>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="login-footer">
            <p style={{fontSize: '11px', textAlign: 'center'}}>
              Grupo Locutores Comerciais &reg; Todos os Direitos Reservados
            </p>
          </div>
        </div>
      </div>
      </div>
    </LoginStyle>
  );
};

export default LoginPage;
