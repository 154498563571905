import React from "react";

import "firebase/firestore";

const RelatorioLocutores = () => {
  return (
    <div className="main-content">

    </div>
  );
};

export default RelatorioLocutores;
