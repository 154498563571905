import styled from 'styled-components';

const UserProfile = styled.div`
img.avatar {
  object-fit: cover;
  object-position: 0 0;
}
.card-user .image {
    img {
      object-fit: cover;
      height: 100%;
    }
}
`
export default UserProfile;