import styled from "styled-components";

const Login = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
  .main {
    display: flex;
  }
  h1,
  a,
  p,
  .btnLogin,
  input.form-control {
    font-family: "Montserrat", sans-serif;
  }

  h1 {
    font-size: 30px;
    margin: 25px 0;
    color: #1c1c1c;
    font-weight: 500;
  }

  input.form-control::placeholder {
    font-size: 14px;
  }

  @media (min-width: 1151px) {
    .login-esq {
      width: 40%;
      max-height: 100vh;
    }

    .login-dir {
      width: 60%;
      max-height: 100vh;
    }

    #root {
      display: flex;
      flex: auto;
      max-height: 100vh;
      overflow: hidden;
    }

    .login-cabecalho-mobile {
      display: none;
    }
  }
  .voltar-login {
    margin-top: 25px;
  }
  .login-esq-contain {
    width: 350px;
    padding: 15px;
    margin: 0 auto;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .login-cabecalho {
    margin: 0 auto;
  }

  .form-signin .form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: 16px;
  }

  .form-signin .form-control:focus {
    z-index: 2;
  }

  .login-dir {
    background-color: #ffa000;
    background-size: cover;
    display: flex;
    padding: 0 5%;
    img {
      width: 100%;
    }
  }

  .login-dir div {
    background-size: cover;
    width: 80%;
    height: 100vh;
    img {
      width: 100%;
    }
  }

  .form-signin input[type="password"] {
    margin-bottom: 0;
  }

  .form-signin input[type="email"] {
    margin-bottom: 0;
  }

  .form-control {
    background-color: #fff;
    box-shadow: none;
  }

  #input-email {
    background-color: white;
    background: url("https://firebasestorage.googleapis.com/v0/b/locutores-comerciais.appspot.com/o/mail.png?alt=media&token=0b9248b2-afed-4d8e-9e9c-ffaea80ad05e")
      no-repeat scroll;
    padding-left: 40px;
    background-position: 7px 12px;
    width: 100%;
  }

  #input-senha {
    background-color: white;
    background: url("https://firebasestorage.googleapis.com/v0/b/locutores-comerciais.appspot.com/o/lock.png?alt=media&token=2c3ef344-a38b-4d96-b59a-ec684ffc01ec")
      no-repeat scroll;
    padding-left: 40px;
    background-position: 5px 10px;
    margin-top: 10px;
    width: 100%;
  }

  .effect-16,
  .effect-17,
  .effect-18 {
    border: 1px solid #e3e3e3;
    padding: 12px;
    border-bottom: 1px solid #e3e3e3;
    background-color: transparent;
  }

  .effect-16 ~ .focus-border {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: rgb(255, 160, 0);
    transition: 0.4s;
  }

  .effect-16:focus ~ .focus-border,
  .has-content.effect-16 ~ .focus-border {
    width: 100%;
    transition: 0.4s;
  }

  :focus {
    outline: none;
  }

  .col-3 {
    width: 100%;
    position: relative;
  }

  /* necessary to give position: relative to parent. */
  input[type="text"] {
    width: 100%;
    box-sizing: border-box;
    letter-spacing: 1px;
  }

  .btnLogin {
    width: 100%;
    padding: 15px;
    border: 0;
    font-weight: 600;
    color: white;
    letter-spacing: 0.2rem;
    background: rgb(255, 160, 0);
    background: -moz-linear-gradient(
      60deg,
      rgba(255, 160, 0, 1) 0%,
      rgba(255, 184, 0, 1) 100%
    );
    background: -webkit-linear-gradient(
      60deg,
      rgba(255, 160, 0, 1) 0%,
      rgba(255, 184, 0, 1) 100%
    );
    background: linear-gradient(
      60deg,
      rgba(255, 160, 0, 1) 0%,
      rgba(255, 184, 0, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffa000", endColorstr="#ffb800", GradientType=1);
    transition: 0.5s;
    margin-top: 18px;
  }

  .btnLogin:hover {
    box-shadow: 0px 15px 30px 0px rgb(239 122 8 / 28%);
    transform: translateY(-5px);
  }

  a {
    color: #ffa000;
    text-decoration: none;
  }

  a:hover {
    color: #ffa000;
  }

  a,
  .btnLogin {
    cursor: pointer;
  }

  .reset-password {
    margin-top: 25px;
  }

  .login-footer p {
    font-size: 14px;
    font-weight: bold;
  }

  @media (max-width: 1150px) {
    .login-dir {
      width: 100%;
    }

    .login-esq {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .form-signin {
      background-color: white;
      padding: 40px;
      border-radius: 10px;
    }

    .login-footer a {
      color: black;
    }

    .login-cabecalho {
      display: none;
    }
  }
  .login-text {
    font-size: 14px;
    margin-top: 25px;
  }
`;
export default Login;
