import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { Grid, Row, Col } from "react-bootstrap";
import { css } from "@emotion/core";
import BarLoader from "react-spinners/BarLoader";

import TableMessagesSent from "./Components/TableContainer";

import Card from "components/Card/Card.jsx";

import firebase from "../config/firebase";
import "firebase/firestore";
import "firebase/storage";

const NoResult = ({ text }) => {
  const style = {
    noResultDiv: {
      height: "150px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "1.5em",
      color: "#ccc",
      userSelect: "none",
      fontWeight: "lighter",
    },
  };
  return <div style={style.noResultDiv}>{text}</div>;
};

const EmailsInativos = () => {
  let isMounted = false;

  let monName = [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ];

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  const userEmailStore = useSelector((state) => state.userEmail);

  const columns = useMemo(
    () => [
      {
        Header: "Nome",
        accessor: "de.nome",
      },
      {
        Header: "Email",
        accessor: "para",
      },
      {
        Header: "Assunto",
        accessor: "assunto",
      },
      {
        Header: "Data",
        accessor: (values) => {
          let dateFormated =
            values.data.toDate().getDate() +
            " de " +
            monName[values.data.toDate().getMonth()] +
            " de " +
            values.data.toDate().getFullYear() +
            " - " +
            values.data.toDate().toLocaleTimeString("pt-br");
          return dateFormated.replace(dateFormated.substr(-3, dateFormated.lenght), '');
        },
      }
    ],
    []
  );
  const [messagesDeleted, getMessages] = useState([]);
  const [loading, setLoading] = useState(true);

  const interacoesRef = firebase.firestore().collection("interacoes");

  useEffect(() => {
    isMounted = true;
    interacoesRef
      .where("lixeira", "array-contains", userEmailStore)
      .orderBy("data", "desc")
      .limit(20)
      .onSnapshot(
        (snapshot) => {
          if (isMounted) {
            if (snapshot.empty) {
              setLoading(false);
            }
            snapshot.docChanges().map((doc) => {
              setLoading(false);
              if (doc.type === "added") {
                let dataMessages = [];
                snapshot.forEach((message) => {
                  dataMessages.push({ ...message.data(), id: message.id });
                });
                let newData = dataMessages.filter(x => !x.excluido.includes(userEmailStore));
                getMessages(newData);
              }
              if (doc.type === "modified") {
                let dataMessages = [];
                snapshot.forEach((message) => {
                  dataMessages.push({ ...message.data(), id: message.id });
                });
                let newData = dataMessages.filter(x => !x.excluido.includes(userEmailStore));
                getMessages(newData);
              }
              if (doc.type === "removed") {
                let dataMessages = [];
                snapshot.forEach((message) => {
                  dataMessages.push({ ...message.data(), id: message.id });
                });
                let newData = dataMessages.filter(x => !x.excluido.includes(userEmailStore));
                getMessages(newData);
              }
            });
          }
        },
        (err) => {
          console.log(err);
        }
      );
    return () => (isMounted = false);
  }, []);

  return (
    <div className="main-content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              title="Lixeira"
              category="Todos os itens excluídos"
              ctTableFullWidth
              ctTableResponsive
              content={
                loading ? (
                  <BarLoader
                    css={override}
                    size={150}
                    color={"#123abc"}
                    loading={loading}
                  />
                ) : (
                  messagesDeleted.length > 0 ? (
                    <TableMessagesSent columns={columns} data={messagesDeleted} view="lixeira" />
                  ) : (
                    <NoResult text="Lixeira vazia" />
                  )
                )
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default EmailsInativos;
