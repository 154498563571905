import styled from 'styled-components';

export const TableUsersStyle = styled.div`
  .opacity_dsb {
    opacity: .6;
  }
  .opacity_none {
    opacity: 1;
  }
  .pe-7s-more {
    color: #000;
    font-weight: bold;
    opacity: 1;
  }

`;