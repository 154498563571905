/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.jsx";
import CaixaEntrada from 'views/CaixaEntrada';
import CaixaSaida from 'views/CaixaSaida';
import EnviarMensagem from 'views/EnviarMensagem';
import ListaUsuarios from 'views/ListaUsuarios';
import PerfilUsuario from 'views/PerfilUsuario';
import Buttons from "views/Components/Buttons.jsx";
import GridSystem from "views/Components/GridSystem.jsx";
import Panels from "views/Components/Panels.jsx";
import SweetAlert from "views/Components/SweetAlertPage.jsx";
import Notifications from "views/Components/Notifications.jsx";
import Icons from "views/Components/Icons.jsx";
import Typography from "views/Components/Typography.jsx";
import RegularForms from "views/Forms/RegularForms.jsx";
import ExtendedForms from "views/Forms/ExtendedForms.jsx";
import ValidationForms from "views/Forms/ValidationForms.jsx";
import Wizard from "views/Forms/Wizard/Wizard.jsx";
import RegularTables from "views/Tables/RegularTables.jsx";
import ExtendedTables from "views/Tables/ExtendedTables.jsx";
import ReactTables from "views/Tables/ReactTables.jsx";
import GoogleMaps from "views/Maps/GoogleMaps.jsx";
import FullScreenMap from "views/Maps/FullScreenMap.jsx";
import VectorMap from "views/Maps/VectorMap.jsx";
import Charts from "views/Charts.jsx";
import Calendar from "views/Calendar.jsx";
import UserPage from "views/Pages/UserPage.jsx";
import LoginPage from "views/Pages/LoginPage.jsx";
import RecoveryPass from "views/Pages/RecoveryPass.jsx";
import RegisterPage from "views/Pages/RegisterPage.jsx";
import LockScreenPage from "views/Pages/LockScreenPage.jsx";
import EmailsInativos from "views/EmailsInativos";
import RelatorioLocutores from "views/RelatorioLocutores";

var routes = [
  {
    path: "/painel-de-controle",
    name: "Início",
    icon: "pe-7s-home",
    component: Dashboard,
    layout: "/admin"
  },
  {
    path: "/enviar-mensagem/:id/:type",
    name: "Enviar pedido",
    icon: "pe-7s-note",
    component: EnviarMensagem,
    invisible: true,
    layout: "/admin"
  },
  {
    path: "/caixa-entrada",
    name: "Caixa de entrada",
    icon: "pe-7s-drawer",
    component: CaixaEntrada,
    layout: "/admin"
  },
  {
    path: "/caixa-saida",
    name: "Itens enviados",
    icon: "fa fa-send",
    component: CaixaSaida,
    layout: "/admin"
  },
  {
    path: "/lixeira",
    name: "Lixeira",
    icon: "pe-7s-trash",
    component: EmailsInativos,
    layout: "/admin"
  },
  {
    path: "/lista-usuarios",
    name: "Lista de usuários",
    icon: "fa fa-users",
    component: ListaUsuarios,
    layout: "/admin"
  },
  {
    path: "/relatorio-locutores",
    name: "Relatório dos Locutores",
    icon: "pe-7s-note2",
    component: RelatorioLocutores,
    layout: "/admin"
  },
  {
    path: "/perfil-usuario/:id",
    name: "Perfil Usuario",
    invisible: true,
    component: PerfilUsuario,
    layout: "/admin"
  },
  {
    collapse: true,
    invisible: true,
    path: "/components",
    name: "Components",
    state: "openComponents",
    icon: "pe-7s-plugin",
    views: [
      {
        path: "/buttons",
        layout: "/pedidos",
        name: "Buttons",
        mini: "B",
        component: Buttons
      },
      {
        path: "/grid-system",
        layout: "/pedidos",
        name: "Grid System",
        mini: "GS",
        component: GridSystem
      },
      {
        path: "/panels",
        layout: "/pedidos",
        name: "Panels",
        mini: "P",
        component: Panels
      },
      {
        path: "/sweet-alert",
        layout: "/pedidos",
        name: "Sweet Alert",
        mini: "SA",
        component: SweetAlert
      },
      {
        path: "/notifications",
        layout: "/pedidos",
        name: "Notifications",
        mini: "N",
        component: Notifications
      },
      {
        path: "/icons",
        layout: "/pedidos",
        name: "Icons",
        mini: "I",
        component: Icons
      },
      {
        path: "/typography",
        layout: "/pedidos",
        name: "Typography",
        mini: "T",
        component: Typography
      }
    ]
  },
  {
    collapse: true,
    invisible: true,
    path: "/forms",
    name: "Forms",
    state: "openForms",
    icon: "pe-7s-note2",
    views: [
      {
        path: "/regular-forms",
        layout: "/pedidos",
        name: "Regular Forms",
        mini: "RF",
        component: RegularForms
      },
      {
        path: "/extended-forms",
        layout: "/pedidos",
        name: "Extended Forms",
        mini: "EF",
        component: ExtendedForms
      },
      {
        path: "/validation-forms",
        layout: "/pedidos",
        name: "Validation Forms",
        mini: "VF",
        component: ValidationForms
      },
      {
        path: "/wizard",
        layout: "/pedidos",
        name: "Wizard",
        mini: "W",
        component: Wizard
      }
    ]
  },
  {
    collapse: true,
    invisible: true,
    path: "/tables",
    name: "Tables",
    state: "openTables",
    icon: "pe-7s-news-paper",
    views: [
      {
        path: "/regular-tables",
        layout: "/pedidos",
        name: "Regular Tables",
        mini: "RT",
        component: RegularTables
      },
      {
        path: "/extended-tables",
        layout: "/pedidos",
        name: "Extended Tables",
        mini: "ET",
        component: ExtendedTables
      },
      {
        path: "/react-table",
        layout: "/pedidos",
        name: "React Table",
        mini: "RT",
        component: ReactTables
      }
    ]
  },
  {
    collapse: true,
    invisible: true,
    path: "/maps",
    name: "Maps",
    state: "openMaps",
    icon: "pe-7s-map-marker",
    views: [
      {
        path: "/google-maps",
        layout: "/pedidos",
        name: "Google Maps",
        mini: "GM",
        component: GoogleMaps
      },
      {
        path: "/full-screen-maps",
        layout: "/pedidos",
        name: "Full Screen Map",
        mini: "FSM",
        component: FullScreenMap
      },
      {
        path: "/vector-maps",
        layout: "/pedidos",
        name: "Vector Map",
        mini: "VM",
        component: VectorMap
      }
    ]
  },
  {
    invisible: true,
    path: "/charts",
    layout: "/pedidos",
    name: "Charts",
    icon: "pe-7s-graph1",
    component: Charts
  },
  {
    invisible: true,
    path: "/calendar",
    layout: "/pedidos",
    name: "Calendar",
    icon: "pe-7s-date",
    component: Calendar
  },
  {
    invisible: true,
    collapse: true,
    path: "/pages",
    name: "Pages",
    state: "openPages",
    icon: "pe-7s-gift",
    views: [
      {
        path: "/editar-perfil",
        name: "Editar Perfil",
        icon: "pe-7s-user",
        component: UserPage,
        layout: "/pedidos"
      },
      {
        path: "/login-page",
        layout: "/auth",
        name: "Login Page",
        mini: "LP",
        component: LoginPage
      },
      {
        path: "/recuperar-senha",
        layout: "/auth",
        name: "Recuperar senha",
        mini: "RS",
        component: RecoveryPass
      },
      {
        path: "/register-page",
        layout: "/auth",
        name: "Register",
        mini: "RP",
        component: RegisterPage
      },
      {
        path: "/lock-screen-page",
        layout: "/auth",
        name: "Lock Screen Page",
        mini: "LSP",
        component: LockScreenPage
      }
    ]
  }
];
export default routes;
