import styled from 'styled-components';

const CustomFile = styled.div`
input[type='file'] {
  display: none
}

/* Aparência que terá o seletor de arquivo */
label {
  /* background-color: #3498db; */
  border-radius: 4px;
  border: 1px solid #3498db;
  color: #3498db;
  font-weight: 400;
  cursor: pointer;
  padding: 6px 20px
}
`
export default CustomFile;