import * as React from 'react';
import Timer from 'react-compound-timer';

const RecordControl = ({ handleClick }) => {
  return (
    <div className="flex items-center ml-8" style={{display: 'flex', alignItems: 'center'}}>
      <i
        onClick={handleClick(false)}
        style={{fontSize: '22px',
        color: 'red', cursor: 'pointer'}}
        className="pe-7s-close-circle"
      />
      <div className="mx-8 flex" style={{width: '50px', textAlign: 'center', fontSize: '17px'}}>
        <Timer>
          <Timer.Minutes formatValue={v => `${v < 10 ? `${v}` : v}:`} />
          <Timer.Seconds formatValue={v => `${v < 10 ? `0${v}` : v}`} />
        </Timer>
      </div>
      <i
        style={{fontSize: '22px', cursor: 'pointer'}}
        className="pe-7s-check text-success"
        onClick={handleClick(true)}
      />
    </div>
  );
};

export default RecordControl;
