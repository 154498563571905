import styled from 'styled-components';

const PerfilUsuario = styled.div`
.clear {
  clear: both;
}
.card-user {
  .image {
    height: 250px;
    position: relative;
    &::before {
      content: '';
      opacity: 0;
      transition: .7s;
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, .5);
      z-index: 1;
    }
    .options-bg {
      opacity: 0;
      transition: .7s;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      position: absolute;
      z-index: 1;
      width: 50px;
      display: flex;
      justify-content: space-between;
      i {
        font-size: 2rem;
        color: #fff;
        cursor: pointer;
      }
      i.fa-trash {
        color: red;
      }
    }
    #backgroundFile {
      display: none;
    }
    &:hover::before, &:hover .options-bg {
      opacity: 1;
    }
    img {
      object-fit: cover;
      height: 100%;
    }
  }
  .avatar {
    object-fit: cover;
    object-position: 0 0;
    border: none;
  }
  .title {
    margin-top: 15px;
  }
}
.contain-avatar {
  user-select: none;
  display: inline-block;
  position: relative;
  width: 124px;
  height: 124px;
  border-radius: 50%;
  &::before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
    opacity: 0;
    background: rgba(0, 0, 0, .5);
    border-radius: 50%;
    transition: .7s;
    z-index: 1;
  }
  .options-avatar {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: .7s;
    z-index: 1;
    cursor: pointer;
    width: 50px;
    display: flex;
    justify-content: space-between;
    i {
      font-size: 2rem;
      color: #fff;
      cursor: pointer;
    }
    i.fa-trash {
      color: red;
    }
  }
  #avatarFile {
    display: none;
  }
  &:hover::before, &:hover .options-avatar {
    opacity: 1;
  }
}
.footer-card {
  padding: 15px;
  .group-buttons {
    float: right;
    display: flex;
    justify-content: space-around;
    .nav {
      display: flex;
      align-items: center;
      font-size: 20px;
      padding: 0 10px;
      #disable-account {
        padding: 0;
        i {
          color: #000;
        }
      }
      .open > a {
        background: none;
      }
    }
    button {
      margin: 0 7px;
    }
    .btn-edit {
      background: #17a2b8;
      border: 1px solid #17a2b8;
      color: #fff;
      &:active {
        background: #17a2b8;
        border: 1px solid #17a2b8;
        color: #fff;
      }
    }
    .btn-pass-edit {
      background: #dc3545;
      border: 1px solid #dc3545;
      color: #fff;
      &:active {
        background: #dc3545;
        border: 1px solid #dc3545;
        color: #fff;
      }
    }
    .btn-perm-edit {
      background: #ffa534;
      border: 1px solid #ffa534;
      color: #fff;
      &:active {
        background: #ffa534;
        border: 1px solid #ffa534;
        color: #fff;
      }
    }
    .btn-edit-status {
      background: #4771cc;
      border: 1px solid #4771cc;
      color: #fff;
      &:active {
        background: #4771cc;
        border: 1px solid #4771cc;
        color: #fff;
      }
    }
  }
}
.signature {
  width: 100%;
}
.dados {
  transition: 1s height;
  overflow: hidden;
  padding: 0 15px;
}
.active-edit {
  height: auto;
  padding-bottom: 15px;
}
.disable-edit {
  height: 0;
}
`
export default PerfilUsuario;