import styled from 'styled-components';

const AutoSuggestStyle = styled.div`

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  color: #565656;
  padding: 8px 12px;
  height: 40px;
  background-image: none;
  box-shadow: none;
  transition: all 300ms linear;
  display: block;
  width: 100%;
  font-size: 14px;
  line-height: 1.42857143;
  ::-webkit-input-placeholder {
    color: #c4c4c4;
  }
}
.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  /* width: 280px; */
  border: 1px solid #e3e3e3;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #23ccef;
}
`
export default AutoSuggestStyle;