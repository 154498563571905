import * as React from 'react';
import * as moment from 'moment';

const classes = {
  timerContainer: {
    marginLeft: 5
  },
};

const getTime = duration => {
  return moment
    .utc(moment.duration(duration, 'seconds').asMilliseconds())
    .format('m:ss');
};

const Timer = ({ currentTime, active, duration }) => {
  return (
    <div style={classes.timerContainer}>
      {active ? getTime(currentTime) : getTime(duration)}
    </div>
  );
};

export default Timer;
