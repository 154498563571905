import styled from 'styled-components';

const ListaUsuario = styled.div`
h3.title {
  display: flex;
  justify-content: space-between;
}
div.switch-perm {
  margin-left: 10px;
  display: inline-block;
  div:first-child {
    margin-bottom: 0;
  }
}
`
export default ListaUsuario;