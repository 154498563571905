import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Navbar,
  Nav,
  NavItem,
  NavDropdown,
  MenuItem,
  FormGroup,
  FormControl,
  InputGroup,
} from "react-bootstrap";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import firebase from "../../config/firebase";
import "firebase/firestore";

const HeaderLinks = () => {
  let status = [
    {
      nome: "Online",
      icon_color: "text-success",
    },
    {
      nome: "Volto em 10 minutos",
      icon_color: "text-info",
    },
    {
      nome: "Ausente",
      icon_color: "text-danger",
    },
    {
      nome: "Problema técnico",
      icon_color: "text-warning",
    },
  ];

  const dispatch = useDispatch();

  const userEmailStore = useSelector((state) => state.userEmail);
  const userIdStore = useSelector((state) => state.userId);
  const userPermissionsStore = useSelector((state) => state.userPermissions);

  const interacoesRef = firebase.firestore().collection("interacoes");
  const usersRef = firebase.firestore().collection("users");

  const [notificationSize, setNotificationSize] = useState(0);
  const [notificationData, setNotificationData] = useState([]);
  const [userData, setUserData] = useState();
  const [userStatus, setUserStatus] = useState();
  const [viewUserStatus, setViewUserStatus] = useState(false);

  const updateUserStatus = (stat_nome, icon) => {
    setUserStatus({ nome: stat_nome, icon_color: icon });
    usersRef.doc(userData.id).update({
      status: {
        nome: stat_nome,
        icon_color: icon,
      },
    });
  };

  const verifyPermissions = (perm) => {
    if (perm.hasOwnProperty("BotaoStatus")) {
      if (perm.BotaoStatus.active) {
        setViewUserStatus(true);
      }
    }
  };

  useEffect(() => {
    if (!userPermissionsStore.length) return;
    userPermissionsStore.filter(verifyPermissions);
  }, [userPermissionsStore]);

  useEffect(() => {
    usersRef
      .where("email", "==", userEmailStore)
      .get()
      .then((snapshot) => {
        if (!snapshot.empty) {
          setUserData({ ...snapshot.docs[0].data(), id: snapshot.docs[0].id });
        }
      });
    interacoesRef
      .where("para", "==", userEmailStore)
      .orderBy("data", "desc")
      .onSnapshot((snapshot) => {
        snapshot.docChanges().map((doc) => {
          if (doc.type === "added") {
            let docs = [];
            snapshot.forEach((doc) => {
              docs.push({ ...doc.data(), id: doc.id });
            });
            if (docs.length > 0) {
              let newView = docs.filter(
                (x) => !x.visualizacoes.includes(userEmailStore)
              );
              setNotificationSize(newView.length);
              setNotificationData(newView);
            }
          }
          if (doc.type === "modified") {
            let docs = [];
            snapshot.forEach((doc) => {
              docs.push({ ...doc.data(), id: doc.id });
            });
            if (docs.length > 0) {
              let newView = docs.filter(
                (x) => !x.visualizacoes.includes(userEmailStore)
              );
              setNotificationSize(newView.length);
              setNotificationData(newView);
            }
          }
          if (snapshot.size === 0) {
            setNotificationData([]);
          } else {
            if (doc.type === "removed") {
              let docs = [];
              snapshot.forEach((doc) => {
                docs.push({ ...doc.data(), id: doc.id });
              });
              if (docs.length > 0) {
                let newView = docs.filter(
                  (x) => !x.visualizacoes.includes(userEmailStore)
                );
                setNotificationSize(newView.length);
                setNotificationData(newView);
              }
            }
          }
        });
      });
  }, []);

  return (
    <div>
      <NotificationContainer />
      <Nav pullRight>
        {viewUserStatus && (
          <NavDropdown
            eventKey={2}
            title={
              <div>
                {userStatus ? (
                  <>
                    <i className={`fa fa-circle ${userStatus.icon_color}`} />
                    <p>
                      {userStatus.nome}
                      <b className="caret" />
                    </p>
                  </>
                ) : (
                  userData && (
                    <>
                      <i
                        className={`fa fa-circle ${userData.status.icon_color}`}
                      />
                      <p>
                        {userData.status.nome}
                        <b className="caret" />
                      </p>
                    </>
                  )
                )}
              </div>
            }
            noCaret
            id="basic-nav-dropdown-1"
          >
            {status.map((stat) => {
              return (
                <MenuItem
                  eventKey={2.1}
                  onClick={() => updateUserStatus(stat.nome, stat.icon_color)}
                  key={stat.nome}
                >
                  <i className={`fa fa-circle ${stat.icon_color}`} />
                  {stat.nome}
                </MenuItem>
              );
            })}
          </NavDropdown>
        )}
        <NavDropdown
          eventKey={3}
          title={
            <div>
              <title>{notificationSize ? `(${notificationSize})` : ''} Painel | Grupo Locutores comerciais</title>
              <i className="fa fa-bell-o" />
              {notificationSize && (
                <>
                  <span className="notification">{notificationSize}</span>
                  <p className="hidden-md hidden-lg">
                    Notificações
                    <b className="caret" />
                  </p>
                </>
              )}
            </div>
          }
          noCaret
          id="basic-nav-dropdown-2"
        >
          {notificationData.length > 0 &&
            notificationData.map((notification) => {
              return (
                <MenuItem
                  key={notification.id}
                  eventKey={3.1}
                  href={`/admin/enviar-mensagem/${notification.id}/resp`}
                >
                  Assunto: {notification.tipo_mensagem === "resp" ? "Res: " + notification.assunto : notification.assunto }
                </MenuItem>
              );
            })}
        </NavDropdown>
        <NavDropdown
          eventKey={4}
          title={
            <div>
              <i className="fa fa-list" />
              <p className="hidden-md hidden-lg">
                Mais
                <b className="caret" />
              </p>
            </div>
          }
          noCaret
          id="basic-nav-dropdown-3"
          bsClass="dropdown-with-icons dropdown"
        >
          <MenuItem eventKey={4.1} href={"/admin/caixa-entrada"}>
            <i className="pe-7s-mail" /> Mensagens
          </MenuItem>
          {/* <MenuItem eventKey={4.2}>
            <i className="pe-7s-help1" /> Help Center
          </MenuItem> */}
          <MenuItem
            eventKey={4.3}
            href={`/admin/perfil-usuario/${userIdStore}`}
          >
            <i className="pe-7s-tools" /> Configurações
          </MenuItem>
          <MenuItem divider />
          {/* <MenuItem eventKey={4.4}>
            <i className="pe-7s-lock" /> Lock Screen
          </MenuItem> */}
          <MenuItem
            eventKey={4.5}
            onClick={() => dispatch({ type: "LOG_OUT" })}
          >
            <div className="text-danger">
              <i className="pe-7s-close-circle" /> Sair
            </div>
          </MenuItem>
        </NavDropdown>
      </Nav>
    </div>
  );
};
export default HeaderLinks;
