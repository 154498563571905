import * as React from "react";
import * as moment from "moment";
import * as MicRecorder from "mic-recorder-to-mp3";

import firebase from "firebase/app";
import "firebase/firestore";

import RecordControl from "./RecordControl";
import PreviewRecord from "./PreviewRecord";

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const sliceData = (file) => {
  const pos = file.indexOf(",");
  return file.slice(pos + 1);
};

const MicBtn = ({ isReply = false, recording = false, setRecording, url }) => {
  const [audioRecorder, setAudioRecorder] = React.useState(null);
  const [audioPreview, setAudioPreview] = React.useState(false);
  const [audioSaved, setAudioSaved] = React.useState("");

  const storageRef = firebase.storage().ref("anexos-enviados");

  React.useEffect(() => {
    if (!recording) return;
    (() => {
      setAudioRecorder(
        new MicRecorder({
          bitRate: 128,
        })
      );
    })();
  }, [recording]);

  React.useEffect(() => {
    if (!audioRecorder) return;
    (async () => {
      try {
        await audioRecorder.start();
      } catch (err) {
        setRecording(false);
        setAudioPreview(false);
        setAudioSaved("");
      }
    })();
  }, [audioRecorder]);

  const _handleMicClick = (ev) => {
    if (isReply) {
      return ev.stopPropagation();
    }
    setRecording(true);
  };

  const _handleNewMsg = async () => {
    try {
      const mimeType = audioSaved?.split(";base64,")[0].replace("data:", "");
      const msg = {
        Base64: sliceData(audioSaved),
        Type: "audio",
        MimeType: mimeType,
        FileName: `${moment().unix()}.mp3`
      };
    } catch (err) {
      console.log("err", err);
    }
  };

  const mimeType = audioSaved?.split(";base64,")[0].replace("data:", "");

  const msg = {
    Base64: audioSaved,
    Type: "audio",
    MimeType: mimeType,
    FileName: `${moment().unix()}.mp3`
  };

  const _handleControlClick = (type) => async (ev) => {
    try {
      const res = await audioRecorder.stop();
      const [buffer, blob] = await res.getMp3();

      const base64 = await toBase64(blob);

      let fileUpload = storageRef
        .child(msg.FileName)
        .putString(base64, "data_url");
      fileUpload.on(
        "state_changed",
        function (snapshot) {

        },
        function (error) {
          // Tratamento para erros de upload
        },
        function () {
          fileUpload.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            let audio = {
              filename: msg.FileName,
              url: downloadURL
            };
            url(audio);
          });
        }
      );

      setAudioSaved(base64);

      if (!type) {
        return setRecording(false);
      }

      setAudioPreview(true);
    } catch (err) {
      console.log("err", err);
    }
  };

  const handlePreviewClick = (type) => async (ev) => {
    try {
      type ? _handleNewMsg() : url(false);
    } catch (err) {
      console.log("err", err);
    } finally {
      setRecording(false);
      setAudioPreview(false);
      setAudioSaved("");
    }
  };

  return recording || audioPreview ? (
    <>
      {audioPreview ? (
        <PreviewRecord
          item={audioSaved}
          handleClick={handlePreviewClick}
        />
      ) : (
        <RecordControl handleClick={_handleControlClick} />
      )}
    </>
  ) : (
    <i
      style={{ fontSize: "24px", cursor: "pointer" }}
      className="pe-7s-micro"
      onClick={_handleMicClick}
    />
  );
};

export default MicBtn;
