// eslint-disable-next-line react-hooks/exhaustive-deps
import React, { useState, useEffect, useCallback } from "react";
import styled from 'styled-components';
import { PanelGroup, Panel } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import { Editor } from "@tinymce/tinymce-react";
import { css } from "@emotion/core";
import BarLoader from "react-spinners/BarLoader";
import { withRouter } from "react-router-dom";
import history from "../history";
import * as moment from "moment";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  ProgressBar,
  Table,
  Tooltip,
  OverlayTrigger,
  Modal,
} from "react-bootstrap";

import jsDown from "js-file-download";

import { NotificationManager } from "react-notifications";
import { Redirect } from "react-router-dom";
import ButtonD from "./Components/ButtonDefault";
import Button from "components/CustomButton/CustomButton.jsx";
import Card from "components/Card/Card.jsx";
import AutosuggestStyle from "assets/css/components/AutoSuggest";
import Autosuggest from "react-autosuggest";
import TextEditorStyle from "assets/css/components/TextEditor";
import VerifyMessageStyle from "assets/css/components/VerifyMessage";

import firebase from "firebase/app";
import "firebase/firestore";
import MicBtn from "./Components/MicBtn";
import PreviewRecord from "./Components/PreviewRecord";

const PreviewMessage = styled.div`
  p {
    font-size: 14px;
  }
`;

const styles = {
  dropZone: {
    display: "flex",
    justifyContent: "center",
    height: "100px",
    alignItems: "center",
  },
  modalContent: {
    display: "flex",
    justifyContent: "center",
  },
  divTitle: {
    display: "flex",
    flexWrap: "wrap",
  },
  avatar: {
    width: "3em",
    height: "3em",
    objectFit: "cover",
    borderRadius: "36px",
    userSelect: "none",
  },
  formGroup: {
    margin: "0 0 0 5px",
    lineHeight: 1,
  },
  controlLabel: {
    width: "100%",
    margin: "0 0 0 5px",
    fontSize: ".9em",
    textTransform: "none",
    fontWeight: "bold",
  },
  smallInfos: {
    margin: "0 0 0 5px",
    fontSize: "70%",
    color: "#444",
    fontWeight: "normal",
  },
};

const avatarDefault = 'https://firebasestorage.googleapis.com/v0/b/locutores-comerciais.appspot.com/o/avatar-default.png?alt=media&token=77a4bd84-0ca8-420c-bd2f-d6e6e6a20d8c';

const monName = [
  "Jan",
  "Fev",
  "Mar",
  "Abr",
  "Mai",
  "Jun",
  "Jul",
  "Ago",
  "Set",
  "Out",
  "Nov",
  "Dez",
];

const Mensagem = (props) => {
  let isMounted = false;

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  const userEmailStore = useSelector((state) => state.userEmail);
  const userChargeStore = useSelector((state) => state.userCharge);
  const userIdStore = useSelector((state) => state.userId);
  const userNameStore = useSelector((state) => state.userName);
  const userAvatarStore = useSelector((state) => state.userAvatar);
  const userLastNameStore = useSelector((state) => state.userLastName);
  const userSignatureStore = useSelector((state) => state.userSignature);
  const userPermissionsStore = useSelector((state) => state.userPermissions);

  const interacoesRef = firebase.firestore().collection("interacoes");
  const usersRef = firebase.firestore().collection("users");
  const storageRef = firebase.storage().ref("anexos-enviados");

  const [dataMessage, setDataMessage] = useState();
  const [messageUser, setMessageUser] = useState();
  const [subjectUser, setSubjectUser] = useState();
  const [addressOf, setAddressOf] = useState();
  const [progress, setProgress] = useState();
  const [files, setFiles] = useState([]);
  const [nameFiles, setName] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [show, setShow] = useState(false);
  const [showVerify, setShowVerify] = useState(false);
  const [redirectMailBox, setRedirectMailBox] = useState(false);
  const [fileNameModal, setFileNameModal] = useState();
  const [pathFileModal, setPathFileModal] = useState();
  const [infoFileModal, setInfoFileModal] = useState();
  const [emailUsers, setEmailUsers] = useState([]);
  const [valueSugg, setStateValue] = useState();
  const [suggestions, setStateSugges] = useState([]);
  const [creditsUser, setCreditsUser] = useState();
  const [verifyCredits, setVerifyCredits] = useState(false);
  const [loading, setLoading] = useState(true);
  const [verifyMessage, setVerifyMessage] = useState(false);
  const [sendSuccessful, setSendSuccessful] = useState(false);
  const [idTimeOut, setIdTimeOut] = useState(false);
  const [recording, setRecording] = React.useState(false);
  const [audioSavedUrl, setAudioSavedUrl] = React.useState();
  const [changeNamePage, setChangeNamePage] = React.useState(false);
  const [infoRemetentes, setInfoRemetentes] = React.useState();

  const remove = <Tooltip id="remove">Remover</Tooltip>;
  const download = <Tooltip id="baixar">Baixar</Tooltip>;
  const play = <Tooltip id="play">Escutar</Tooltip>;

  const handleClose = () => setShow(false);
  const handleShow = (namefile, path, info) => {
    setFileNameModal(namefile);
    setPathFileModal(path);
    setInfoFileModal(info);
    setShow(true);
  };

  const handleCloseVerify = () => setShowVerify(false);
  const handleShowVerify = () => setShowVerify(true);
  const sendCreditsVerify = () => {
    let decrementCredits = firebase.firestore.FieldValue.increment(
      -creditsUser
    );
    usersRef
      .where("funcao", "==", "funcionario")
      .get()
      .then((snapshot) => {
        snapshot.docs.forEach((doc) => {
          usersRef.doc(doc.id).update({
            creditos_disponiveis: decrementCredits,
          });
        });
      });
    usersRef
      .doc(userIdStore)
      .update({
        creditos_disponiveis: decrementCredits,
      })
      .then(() => {
        setShowVerify(false);
        setVerifyCredits(true);
      });
  };

  const formatDate = (date) => {
    let dateFormated =
      date.toDate().getDate() +
      " de " +
      monName[date.toDate().getMonth()] +
      " de " +
      date.toDate().getFullYear() +
      " - " +
      date.toDate().toLocaleTimeString("pt-br");
    return dateFormated.replace(
      dateFormated.substr(-3, dateFormated.lenght),
      ""
    );
  };

  useEffect(() => {
    if (!userChargeStore) return;
    if (typeof userChargeStore === 'object') {
      if (userChargeStore.hasOwnProperty("another")) {
        if (userChargeStore.another.value !== 'Gerente Comercial') {
          setChangeNamePage(true);
        }
      }
      if (userChargeStore.hasOwnProperty("default")) {
        if (userChargeStore.default.value !== 'Gerente Comercial') {
          setChangeNamePage(true);
        }
      }
    }
  }, [userChargeStore]);

  const generateKey = (pre) => {
    return `${pre}_${
      new moment().unix() + "" + Math.floor(Math.random() * 500)
    }`;
  };

  function formatFileSize(bytes,decimalPoint) {
    if(bytes == 0) return '0 Bytes';
    var k = 1000,
        dm = decimalPoint || 2,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        const dataUrlFile = reader.result;
        setName((prevState) => [...prevState, { name: file.name, size: formatFileSize(file.size)}]);
        let fileUpload = storageRef
          .child(file.name)
          .putString(dataUrlFile, "data_url");
        fileUpload.on(
          "state_changed",
          function (snapshot) {
            var progressTransfer =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgress(progressTransfer);
          },
          function (error) {
            // Tratamento para erros de upload
          },
          function () {
            fileUpload.snapshot.ref
              .getDownloadURL()
              .then(function (downloadURL) {
                let fileSend = {
                  filename: file.name,
                  path: downloadURL,
                  size: formatFileSize(file.size)
                };
                setFiles((prevState) => [...prevState, fileSend]);
              });
          }
        );
      };
      reader.readAsDataURL(file);
    });
  });

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const increment = firebase.firestore.FieldValue.increment(1);

  const sendFirstMessage = async () => {
    var envolvido_info = [];
    const sender = valueSugg ? valueSugg : addressOf ? addressOf : null;
    if (emailUsers) {
      envolvido_info = emailUsers.filter((x) => x.email === sender);
    }
    var id;
    if (!envolvido_info.length) {
      await usersRef.where("email","==",addressOf).get().then(snapshot => {
        envolvido_info = [{
          email: snapshot.docs[0].data().email,
          id: snapshot.docs[0].id,
          nome: snapshot.docs[0].data().nome,
          sobrenome: snapshot.docs[0].data().sobrenome
        }];
      });
      usersRef.where("email","==",sender).get().then(snapshot => {
        id = snapshot.docs[0].id
      })
    }
    if (envolvido_info.length) {
      await usersRef.where("email","==",envolvido_info[0].email).get().then(snapshot => {
        id = snapshot.docs[0].id
      })
    }
    if (id) {
      await usersRef.doc(id).update({
        remetentes: firebase.firestore.FieldValue.arrayUnion({email: userEmailStore, id: userIdStore, nome: userNameStore, sobrenome: userLastNameStore})
      });
    }
    interacoesRef
      .add({
        qtd_msg: increment,
        visualizacoes: [userEmailStore],
        ativos: [userEmailStore, valueSugg ? valueSugg : addressOf ? addressOf : null],
        lixeira: [],
        excluido: [],
        envolvidos: [userEmailStore, valueSugg ? valueSugg : addressOf ? addressOf : null],
        envolvidos_info: [
          {
            nome:
              userNameStore && userLastNameStore
                ? userNameStore + " " + userLastNameStore
                : userNameStore,
          },
          envolvido_info[0],
        ],
        anfitriao_email: userEmailStore,
        anfitriao_nome:
          userNameStore && userLastNameStore
            ? userNameStore + " " + userLastNameStore
            : userNameStore,
        tipo_mensagem: "init",
        assunto: subjectUser ? subjectUser : null,
        de: {
          email: userEmailStore,
          nome: userNameStore,
          nomeCompleto:
            userNameStore && userLastNameStore
              ? userNameStore + " " + userLastNameStore
              : userNameStore,
          avatar: userAvatarStore ? userAvatarStore : avatarDefault,
          assinatura: userSignatureStore ? userSignatureStore : "",
        },
        mensagem: messageUser ? messageUser : null,
        para: valueSugg ? valueSugg : addressOf ? addressOf : null,
        data: firebase.firestore.FieldValue.serverTimestamp(),
        data_auxiliar: firebase.firestore.FieldValue.serverTimestamp(),
        anexos: files ? files : null,
        lida: false,
        history: firebase.firestore.FieldValue.arrayUnion({
          de: {
            email: userEmailStore,
            nome: userNameStore,
            nomeCompleto:
              userNameStore && userLastNameStore
                ? userNameStore + " " + userLastNameStore
                : userNameStore,
            avatar: userAvatarStore ? userAvatarStore : avatarDefault,
            assinatura: userSignatureStore ? userSignatureStore : "",
          },
          mensagem: messageUser ? messageUser : null,
          para: valueSugg ? valueSugg : addressOf ? addressOf : null,
          data: firebase.firestore.Timestamp.now(),
          anexos: files ? files : null,
          audio: audioSavedUrl ? audioSavedUrl : null,
        }),
      })
      .then(() => {
        NotificationManager.success("Mensagem enviada!", "Sucesso");
        setSendSuccessful(true);
        setTimeout(() => {
          setRedirectMailBox(true);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
        NotificationManager.error("Não foi possível enviar a mensagem", "Ops!");
      });
  };

  const sendRespMessage = (email = null, assunto = null) => {
    interacoesRef
      .doc(props.match.params.id)
      .update({
        qtd_msg: increment,
        visualizacoes: [userEmailStore],
        lixeira: [],
        excluido: [],
        ativos: [userEmailStore, email ? email : addressOf ? addressOf : null],
        envolvidos: [
          userEmailStore,
          email ? email : addressOf ? addressOf : null,
        ],
        tipo_mensagem: "resp",
        assunto: assunto ? assunto : subjectUser ? subjectUser : null,
        de: {
          email: userEmailStore,
          nome: userNameStore,
          nomeCompleto:
            userNameStore && userLastNameStore
              ? userNameStore + " " + userLastNameStore
              : userNameStore,
          avatar: userAvatarStore ? userAvatarStore : avatarDefault,
          assinatura: userSignatureStore ? userSignatureStore : "",
        },
        mensagem: messageUser ? messageUser : null,
        para: email ? email : addressOf ? addressOf : null,
        // data: firebase.firestore.FieldValue.serverTimestamp(),
        data_auxiliar: firebase.firestore.FieldValue.serverTimestamp(),
        lida: false,
        anexos: files ? files : null,
        history: firebase.firestore.FieldValue.arrayUnion({
          de: {
            email: userEmailStore,
            nome: userNameStore,
            nomeCompleto:
              userNameStore && userLastNameStore
                ? userNameStore + " " + userLastNameStore
                : userNameStore,
            avatar: userAvatarStore ? userAvatarStore : avatarDefault,
            assinatura: userSignatureStore ? userSignatureStore : "",
          },
          mensagem: messageUser ? messageUser : null,
          para: email
            ? email
            : valueSugg
            ? valueSugg
            : addressOf
            ? addressOf
            : null,
          data: firebase.firestore.Timestamp.now(),
          anexos: files ? files : null,
          audio: audioSavedUrl ? audioSavedUrl : null,
        }),
      })
      .then(() => {
        setSendSuccessful(true);
        NotificationManager.success("Resposta enviada!", "Sucesso");
        setTimeout(() => {
          setRedirectMailBox(true);
        }, 1500);
      })
      .catch(() => {
        NotificationManager.error("Não foi possível enviar a Resposta", "Ops!");
      });
  };

  useEffect(() => {
    if (!verifyMessage) return;
    if (props.match.params.type === "resp") {
      const id = window.setTimeout(() => {
        sendRespMessage(
          dataMessage && dataMessage.de.email === userEmailStore
            ? dataMessage.para
            : dataMessage.de.email,
          dataMessage && dataMessage.assunto
        );
      }, 2000);
      setIdTimeOut(id);
    } else {
      const id = window.setTimeout(() => {
        sendFirstMessage();
      }, 2000);
      setIdTimeOut(id);
    }
  }, [verifyMessage]);

  const cancelSend = () => {
    NotificationManager.info("Envio cancelado");
    setVerifyMessage(false);
    window.clearTimeout(idTimeOut);
  };

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : emailUsers.filter(
          (email) =>
            email.email.toLowerCase().slice(0, inputLength) === inputValue
        );
  };

  const getSuggestionValue = (suggestion) => suggestion.email;

  const renderSuggestion = (suggestion) => <div>{suggestion.nome+' '+suggestion?.sobrenome+' - '+suggestion.email}</div>;
  const onChange = (event, { newValue }) => {
    setStateValue(newValue);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setStateSugges(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setStateSugges([]);
  };

  const inputProps = {
    placeholder: "Email",
    value: typeof valueSugg !== "undefined" ? valueSugg : "",
    onChange: onChange,
  };

  const clickForDown = (name) => () => {
    storageRef
      .child(name)
      .getDownloadURL()
      .then(function (url) {
        var xhr = new XMLHttpRequest();
        xhr.responseType = "blob";
        xhr.onload = function (event) {
          var blob = xhr.response;
          console.log(blob)
        };
        xhr.open("GET", url);
        xhr.send();
        fetch(url)
          .then((response) => response.blob())
          .then((response) => {
            jsDown(response, name);
          });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const removeFile = (index, name) => () => {
    let item = nameFiles.findIndex((x) => x === name);
    let listNames = [...nameFiles];
    listNames.splice(item, 1);
    let listFiles = [...files];
    listFiles.splice(index, 1);
    setFiles(listFiles);
    setName(listNames);
  };

  const formatMessagePreview = (text) => {
    var newText;
    if (text.length > 35) {
      newText = String(text).substr(0, 35) + "...";
      return { __html: newText };
    } else return { __html: text };
  };

  useEffect(() => {
    if (!infoRemetentes) return;
    const dataUsers = [];
    infoRemetentes.map((info, index) => {
      usersRef.doc(info.id).get().then((doc) =>{
        dataUsers.push(doc.data())
      })
    });
    setEmailUsers(dataUsers)
  }, [infoRemetentes])


  const verifyPermissions = (perm) => {
    if (perm.hasOwnProperty('SugesTodosEmails')) {
      if (perm.SugesTodosEmails.active) {
        usersRef.get().then((snapshot) => {
          let result = [];
          snapshot.docs.forEach((doc) => {
            result.push({
              email: doc.data().email,
              id: doc.id,
              nome: doc.data().nome,
              sobrenome: doc.data().sobrenome,
            });
          });
          setEmailUsers(result);
        });
        return
      } else {
        setEmailUsers([]);
      }
    }
    if (perm.hasOwnProperty('SugesRemetentes')) {
      if (perm.SugesRemetentes.active) {
        usersRef.doc(userIdStore).get().then((doc) => {
          setInfoRemetentes(doc.data().remetentes);
        });
        return
      } else {
        setEmailUsers([]);
      }
    }
    if (perm.hasOwnProperty('SugesTodosEmails') && perm.hasOwnProperty('SugesRemetentes')) {
      if (perm.SugesRemetentes.active && perm.SugesTodosEmails.active) {
        usersRef.get().then((snapshot) => {
          let result = [];
          snapshot.docs.forEach((doc) => {
            result.push({
              email: doc.data().email,
              id: doc.id,
              nome: doc.data().nome,
              sobrenome: doc.data().sobrenome,
            });
          });
          setEmailUsers(result);
        });
        return
      }
    }
  };

  useEffect(() => {
    isMounted = true;
    if (props.match.params.type === "resp") {
      interacoesRef.doc(props.match.params.id).update({
        visualizacoes: firebase.firestore.FieldValue.arrayUnion(userEmailStore),
      });
      interacoesRef
        .doc(props.match.params.id)
        .get()
        .then((resp) => {
          if (isMounted) {
            setDisabled(true);
            setDataMessage(resp.data());
          }
        });
    } else {
      userPermissionsStore.filter(verifyPermissions);
      setDisabled(false);
      setDataMessage(null);
    }
    return () => (isMounted = false);
  }, [firebase, props.match.params]);

  useEffect(() => {
    isMounted = true;
    if (props.match.params.type === "resp") {
      if (!dataMessage) return;
      setLoading(false);
    } else setLoading(false);
  });


  const getUrl = (type) => {
    setAudioSavedUrl(type);
  };

  return (
    <div className="main-content">
      {redirectMailBox && <Redirect to="/admin/caixa-entrada" />}
      {loading ? (
        <BarLoader
          css={override}
          size={150}
          color={"#123abc"}
          loading={loading}
        />
      ) : (
        <Grid fluid>
          <Row>
            <Col md={12}>
              <ButtonD
                onClick={() => history.goBack()}
                style={{ marginBottom: "1rem" }}
              >
                Voltar
              </ButtonD>
              <Card
                title={
                  props.match.params.type === "resp"
                    ? dataMessage?.assunto
                    : `Enviar ${changeNamePage ? 'mensagem' : 'pedido'}`
                }
                ctTableFullWidth
                ctTableResponsive
                content={
                  <form>
                    {props.match.params.type === "resp" && (
                      <FormGroup>
                        <ControlLabel>De: </ControlLabel>
                        <FormControl
                          placeholder="Email"
                          type="email"
                          disabled={disabled}
                          defaultValue={dataMessage && dataMessage.de.email}
                        />
                      </FormGroup>
                    )}
                    <FormGroup>
                      <ControlLabel>Para: </ControlLabel>
                      {props.match.params.type === "resp" ?
                        <FormControl
                          placeholder="Email"
                          type="email"
                          disabled={disabled}
                          defaultValue={
                            dataMessage &&
                            dataMessage.de.email === userEmailStore
                              ? dataMessage.para
                              : dataMessage.de.email
                          }
                          onChange={(e) => setAddressOf(e.target.value)}
                        />
                       :
                       emailUsers && emailUsers.length > 0 ?
                          <AutosuggestStyle>
                            <Autosuggest
                              suggestions={suggestions}
                              onSuggestionsFetchRequested={
                                onSuggestionsFetchRequested
                              }
                              onSuggestionsClearRequested={
                                onSuggestionsClearRequested
                              }
                              getSuggestionValue={getSuggestionValue}
                              renderSuggestion={renderSuggestion}
                              inputProps={inputProps}
                            />
                          </AutosuggestStyle>
                        :
                        <FormControl
                          placeholder="E-mail"
                          type="email"
                          onChange={(e) => setAddressOf(e.target.value)}
                        />
                      }
                    </FormGroup>
                    {props.match.params.type === "resp" && (
                      <FormGroup>
                        <ControlLabel>Assunto: </ControlLabel>
                        <FormControl
                          disabled={disabled}
                          placeholder="Assunto"
                          type="text"
                          defaultValue={"Re: " + dataMessage?.assunto}
                          onChange={(e) => setSubjectUser(e.target.value)}
                        />
                      </FormGroup>
                    )}
                    {props.match.params.type !== "resp" && (
                      <FormGroup>
                        <ControlLabel>Assunto: </ControlLabel>
                        <FormControl
                          placeholder="Assunto"
                          type="text"
                          onChange={(e) => setSubjectUser(e.target.value)}
                        />
                      </FormGroup>
                    )}
                    <PanelGroup accordion id="accordion-example">
                      {dataMessage &&
                        dataMessage.history &&
                        dataMessage.history.length &&
                        dataMessage.history.map((hist, key) => {
                          return (
                            <Panel eventKey={key} key={key}>
                              <Panel.Heading  style={{ height: "75px" }}>
                                <Panel.Title
                                  toggle
                                  style={{ position: "relative" }}
                                >
                                  <div style={styles.divTitle}>
                                    <img
                                      src={hist.de.avatar ? hist.de.avatar : avatarDefault}
                                      alt="Avatar"
                                      style={styles.avatar}
                                    />
                                    <FormGroup style={styles.formGroup}>
                                      <ControlLabel style={styles.controlLabel}>
                                        {hist.de.nomeCompleto
                                          ? hist.de.nomeCompleto
                                          : hist.de.nome}
                                        <small style={styles.smallInfos}>
                                          {"> "}
                                          {hist.de.email}
                                          {!!hist.anexos.length && (
                                            <i
                                              style={{
                                                margin: "0 0 0 5px",
                                              }}
                                              className="pe-7s-paperclip"
                                            />
                                          )}
                                        </small>
                                      </ControlLabel>
                                      {hist.mensagem && (
                                        <PreviewMessage>
                                          <div
                                            style={{
                                              margin: "0 0 0 5px",
                                            }}
                                            dangerouslySetInnerHTML={formatMessagePreview(
                                              hist.mensagem
                                            )}
                                          />
                                        </PreviewMessage>
                                      )}
                                    </FormGroup>
                                    <div
                                      style={{
                                        fontSize: "12px",
                                        position: "absolute",
                                        right: "2px",
                                        top: "15px",
                                        color: "#8c8c8c",
                                      }}
                                    >
                                      {formatDate(hist.data)}
                                    </div>
                                  </div>
                                </Panel.Title>
                              </Panel.Heading>
                              <Panel.Body
                                collapsible={
                                  key + 1 === dataMessage.history.length
                                    ? false
                                    : true
                                }
                              >
                                <FormGroup controlId="textAreaResp">
                                  {key + 1 === dataMessage.history.length
                                    ? hist.mensagem && (
                                        <>
                                          <ControlLabel>
                                            Última mensagem:{" "}
                                          </ControlLabel>
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: hist.mensagem,
                                            }}
                                          ></div>
                                        </>
                                      )
                                    : hist.mensagem && (
                                        <>
                                          <ControlLabel>
                                            Mensagem:{" "}
                                          </ControlLabel>
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: hist.mensagem,
                                            }}
                                          ></div>
                                        </>
                                      )}
                                </FormGroup>
                                {hist.audio && (
                                  <FormGroup>
                                    <ControlLabel>Áudio recebido:</ControlLabel>
                                    <PreviewRecord
                                      item={hist.audio.url}
                                      view="Message"
                                      downloadAudio={clickForDown}
                                      audioInfo={hist.audio}
                                    />
                                  </FormGroup>
                                )}
                                {!!hist.anexos.length && (
                                  <FormGroup controlId="textAreaResp">
                                    <ControlLabel>
                                      Anexos recebidos:{" "}
                                    </ControlLabel>
                                    <Table responsive>
                                      <thead>
                                        <tr>
                                          <th>Nome</th>
                                          <th className="text-right">Ações</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {hist.anexos.map((anx, key) => {
                                          return (
                                            <tr key={generateKey(anx.filename)}>
                                              <td>{anx.filename+' - '+anx.file}</td>
                                              <td className="td-actions text-right">
                                                {anx.filename.match(
                                                  /\.(?:wav|mp3|wma|ogg)$/i
                                                ) && (
                                                  <React.Fragment>
                                                    <OverlayTrigger
                                                      placement="top"
                                                      overlay={play}
                                                    >
                                                      <i
                                                        className="fa fa-play text-info"
                                                        role="button"
                                                        onClick={() =>
                                                          handleShow(
                                                            anx.filename,
                                                            anx.path,
                                                            anx
                                                          )
                                                        }
                                                      />
                                                    </OverlayTrigger>
                                                  </React.Fragment>
                                                )}
                                                <OverlayTrigger
                                                  placement="top"
                                                  overlay={download}
                                                >
                                                  <i
                                                    className="fa fa-download text-success"
                                                    role="button"
                                                    onClick={clickForDown(
                                                      anx.filename
                                                    )}
                                                  />
                                                </OverlayTrigger>
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </Table>
                                  </FormGroup>
                                )}
                                {hist.de.assinatura && (
                                  <FormGroup>
                                    {/* <SignatureStyle> */}
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: hist.de.assinatura,
                                        }}
                                      />
                                    {/* </SignatureStyle> */}
                                  </FormGroup>
                                )}
                              </Panel.Body>
                            </Panel>
                          );
                        })}
                    </PanelGroup>
                    <FormGroup controlId="texteditor">
                      <ControlLabel>
                        Sua{" "}
                        {props.match.params.type === "resp"
                          ? "resposta"
                          : "mensagem"}
                      </ControlLabel>
                      <TextEditorStyle>
                        <Editor
                          apiKey="3b5lzvgi3vfq7vlq7nfvub3pmonp9g382q1pz6j1o0cqztqb"
                          inline={true}
                          init={{
                            language: "pt_BR",
                            height: 350,
                            selector: "textarea#full-featured-non-premium",
                            plugins:
                              "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen link template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount textpattern noneditable help charmap emoticons",
                            menubar: "file edit view format tools table help",
                            toolbar:
                              "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile template link anchor codesample | ltr rtl",
                            toolbar_sticky: true,
                          }}
                          onEditorChange={(content) => setMessageUser(content)}
                        />
                      </TextEditorStyle>
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel style={{ width: "100%" }}>
                        Gravar áudio:
                      </ControlLabel>
                      <MicBtn
                        url={getUrl}
                        recording={recording}
                        setRecording={setRecording}
                      />
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel>Anexos</ControlLabel>
                      <div
                        {...getRootProps()}
                        className="files_contain"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          height: "100px",
                          alignItems: "center",
                        }}
                      >
                        <input {...getInputProps()} />
                        <p className="text-muted">
                          Arraste ou clique para selecionar
                        </p>
                      </div>
                      <div className="files">
                        {nameFiles.length > 0 && (
                          <Table responsive>
                            <thead>
                              <tr>
                                <th>Nome</th>
                                <th className="text-right">Ações</th>
                              </tr>
                            </thead>
                            <tbody>
                              {nameFiles.map((file, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{file.name+' - '+file.size}</td>
                                    <td className="td-actions text-right">
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={remove}
                                      >
                                        <Button
                                          simple
                                          bsStyle="danger"
                                          bsSize="xs"
                                          onClick={removeFile(key, file.name)}
                                        >
                                          <i className="fa fa-times" />
                                        </Button>
                                      </OverlayTrigger>
                                    </td>
                                  </tr>
                                );
                              })}
                              <tr>
                                <td>
                                  {progress < 100 && files.length < 1 ? (
                                    <>
                                      <p>Enviando...</p>
                                      <ProgressBar
                                        now={progress}
                                        className="progress-line-primary"
                                        label="30% Complete"
                                        srOnly
                                      />
                                    </>
                                  ) : files.length > 0 ? (
                                    <p>
                                      Todos os anexos foram carregados!{" "}
                                      <i className="fa fa-check text-success" />
                                    </p>
                                  ) : (
                                    <>
                                      <p>Enviando...</p>
                                      <ProgressBar
                                        now={progress}
                                        className="progress-line-primary"
                                        label="30% Complete"
                                        srOnly
                                      />
                                    </>
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        )}
                      </div>
                    </FormGroup>
                    <FormGroup></FormGroup>
                    {userChargeStore === "Locutor" && !verifyCredits ?
                      <Button bsStyle="info" onClick={handleShowVerify}>
                        Verificar envio
                      </Button>
                     :
                      <ButtonD
                        onClick={() => setVerifyMessage(true)}
                      >
                        {
                          props.match.params.type === "resp" ? 'Responder' : `Enviar ${changeNamePage ? 'mensagem' : 'pedido'}`
                        }

                      </ButtonD>
                    }
                    <Modal show={showVerify} onHide={handleCloseVerify}>
                      <Modal.Header closeButton>
                        <Modal.Title>Verificar envio</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <FormGroup controlId="textArea">
                          <ControlLabel>
                            Quantos créditos serão cobrados?
                          </ControlLabel>
                          <FormControl
                            placeholder="00"
                            type="text"
                            onChange={(e) => setCreditsUser(e.target.value)}
                          />
                        </FormGroup>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button bsStyle="danger" onClick={handleCloseVerify}>
                          Fechar
                        </Button>
                        <Button bsStyle="info" onClick={sendCreditsVerify}>
                          Enviar
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </form>
                }
              />
            </Col>
          </Row>
        </Grid>
      )}
      <VerifyMessageStyle>
        <div
          className={`${
            verifyMessage ? "enable-verify" : "disable-verify"
          } verify-message`}
        >
          {!sendSuccessful ? (
            <p>
              Enviando...&nbsp;&nbsp;
              <span onClick={cancelSend}>Cancelar</span>
            </p>
          ) : (
            <p>Mensagem enviada</p>
          )}
        </div>
      </VerifyMessageStyle>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{fileNameModal}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={styles.modalContent}>
          <PreviewRecord
            item={pathFileModal}
            view="Message"
            autoPlay
            downloadAudio={clickForDown}
            audioInfo={infoFileModal}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default withRouter(Mensagem);