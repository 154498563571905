import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import history from "./history";
import { Provider, useSelector } from "react-redux";
import { store, persistor } from "./store/";
import { PersistGate } from "redux-persist/integration/react";

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-notifications/lib/notifications.css";
import "assets/sass/light-bootstrap-dashboard-pro-react.scss?v=1.2.0";
import "assets/css/demo.css";
import "assets/css/pe-icon-7-stroke.css";

import AuthLayout from "layouts/Auth.jsx";
import AdminLayout from "layouts/Admin.jsx";

const AppWrapper = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  );
};

const App = () => {
  return (
    <BrowserRouter history={history}>
      <Switch>
        {useSelector((state) => state.userLogged) ? (
          <Route path="/" render={(props) => <AdminLayout {...props} />} />
        ) : (
          <>
            <Route
              exact
              path="/auth/login-page"
              render={(props) => <AuthLayout {...props} />}
            />
            <Route
              exact
              path="/auth/recuperar-senha"
              render={(props) => <AuthLayout {...props} />}
            />
            <Redirect
              to="/auth/login-page"
              render={(props) => <AuthLayout {...props} />}
              exact
            />
          </>
        )}
      </Switch>
    </BrowserRouter>
  );
};

ReactDOM.render(<AppWrapper />, document.getElementById("root"));
