import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import * as moment from "moment";
import {
  Grid,
  Row,
  Col,
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
} from "react-bootstrap";
import Switch from "react-bootstrap-switch";
import Select from "react-select";
import { css } from "@emotion/core";
import BarLoader from "react-spinners/BarLoader";
import Button from "components/CustomButton/CustomButton.jsx";
import { NotificationManager } from "react-notifications";

import ListaUsuarioStyle from "assets/css/views/ListaUsuarios";
import TableUser from "./Components/TableUser";

import Card from "components/Card/Card.jsx";

import {
  optionsUserFunction,
  optionsUserCharge,
  optionsUserRemuneration,
  optionsUserHiring,
  defaultPermissions,
} from "./OptionsData";

import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";

const NoResult = ({ text }) => {
  const style = {
    noResultDiv: {
      height: "150px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "1.5em",
      color: "#ccc",
      userSelect: "none",
      fontWeight: "lighter",
    },
    titleStyle: {
      display: "flex",
      justifyContent: "space-between",
    },
  };
  return <div style={style.noResultDiv}>{text}</div>;
};

const styles = {
  permSwitch: {
    marginLeft: "3px",
    display: "inline-block",
  },
  gridAlignC: {
    display: "grid",
    alignItems: "center",
    gridTemplateColumns: "89% 25%",
  },
  campoMoeda: {
    marginTop: "10px",
  },
  campoDiv: {
    display:"flex",
    justifyContent:"center",
    alignItems:"baseline"
  },
  campoReal: {
    paddingRight:"10px"
  }
};

const CaixaSaida = () => {
  let isMounted = false;

  let monName = [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ];

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  const columns = useMemo(
    () => [
      {
        Header: "Nome",
        accessor: (values) => {
          if (values.nome && values.sobrenome) {
            return values.nome + " " + values.sobrenome;
          } else {
            return values.nome;
          }
        },
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Função",
        accessor: (values) => {
          if (values.funcao.hasOwnProperty("default")) {
            return values.funcao.default.label;
          }
          if (values.funcao.hasOwnProperty("another")) {
            return values.funcao.another.label;
          }
        },
      },
      {
        Header: "Cargo",
        accessor: (values) => {
          if (values.cargo.hasOwnProperty("default")) {
            return values.cargo.default.label;
          }
          if (values.cargo.hasOwnProperty("another")) {
            return values.cargo.another.label;
          }
        },
      },
      {
        Header: "Data cadastro",
        accessor: (values) => {
          if (values.data_cadastro) {
            let dateFormated =
              values.data_cadastro.toDate().getDate() +
              " de " +
              monName[values.data_cadastro.toDate().getMonth()] +
              " de " +
              values.data_cadastro.toDate().getFullYear() +
              " - " +
              values.data_cadastro.toDate().toLocaleTimeString("pt-br");
            return dateFormated.replace(
              dateFormated.substr(-3, dateFormated.lenght),
              ""
            );
          }
        },
      },
    ],
    [monName]
  );

  const userTypeStore = useSelector((state) => state.userType);

  const [messagesReceived, getMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showCreateUser, setShowCreateUser] = useState(false);
  const [showCreateUserAccount, setShowCreateUserAccount] = useState(false);
  const [showCreateUserPermissions, setShowCreateUserPermissions] = useState(
    false
  );
  const [typeLocutor, setTypeLocutor] = useState(false);
  const [valorMoeda, setValorMoeda] = useState();

  const [userPassError, setPassError] = useState();
  const [cfpasswordError, setCfpasswordError] = useState();
  const [cfPass, setCfPass] = useState();
  const [emailError, setEmailError] = useState();

  const [newUserEmail, setNewUserEmail] = useState();
  const [newUserPass, setNewUserPass] = useState();
  const [newUserName, setNewUserName] = useState();
  const [newUserLastName, setNewUserLastName] = useState();
  const [anotherUserFunctionType, setAnotherUserFunctionType] = useState(false);
  const [newUserFunctionType, setNewUserFunctionType] = useState();
  const [anotherUserCharge, setAnotherUserCharge] = useState(false);
  const [newUserCharge, setNewUserCharge] = useState();
  const [defaultSiteActing, setDefaultSiteActing] = useState(false);
  const [anotherSiteActing, setAnotherSiteActing] = useState(false);
  const [newUserSiteActing, setNewUserSiteActing] = useState();
  const [anotherRemuneration, setAnotherRemuneration] = useState(false);
  const [newUserRemuneration, setNewUserRemuneration] = useState();
  const [anotherHiring, setAnotherHiring] = useState(false);
  const [newUserHiring, setNewUserHiring] = useState();
  const [newUserPermissions, setNewUserPermissions] = useState();

  const handleCloseCreateUser = () => setShowCreateUser(false);
  const handleShowCreateUser = () => {
    setShowCreateUser(true);
    setShowCreateUserAccount(false);
  };

  const handleShowCreateUserPermissions = () => {
    setShowCreateUserPermissions(true);
    setShowCreateUser(false);
  };

  const handleCloseCreateUserPermissions = () =>
    setShowCreateUserPermissions(false);

  const handleCloseCreateUserAccount = () => setShowCreateUserAccount(false);
  const handleShowCreateUserAccount = () => setShowCreateUserAccount(true);

  const usersRef = firebase.firestore().collection("users");

  useEffect(() => {
    if (typeof userTypeStore === "object") {
      if (userTypeStore.hasOwnProperty("another")) {
        if (userTypeStore.another.value !== "Administrador") {
          window.location.href = "/pedidos/painel-de-controle";
        }
      }
      if (userTypeStore.hasOwnProperty("default")) {
        if (userTypeStore.default.value !== "Administrador") {
          window.location.href = "/pedidos/painel-de-controle";
        }
      }
    }
    isMounted = true;
    usersRef.orderBy('data_cadastro','asc').onSnapshot(
      (snapshot) => {
        if (isMounted) {
          if (snapshot.empty) {
            setLoading(false);
          }
          snapshot.docChanges().forEach((doc) => {
            setLoading(false);
            if (doc.type === "added") {
              let dataMessages = [];
              snapshot.forEach((message) => {
                dataMessages.push({ ...message.data(), id: message.id });
              });
              getMessages(dataMessages);
            }
            if (doc.type === "modified") {
              let dataMessages = [];
              snapshot.forEach((message) => {
                dataMessages.push({ ...message.data(), id: message.id });
              });
              getMessages(dataMessages);
            }
            if (doc.type === "removed") {
              let dataMessages = [];
              snapshot.forEach((message) => {
                dataMessages.push({ ...message.data(), id: message.id });
              });
              getMessages(dataMessages);
            }
          });
        }
      },
      (err) => {
        setLoading(false);
        console.log(err);
      }
    );
    return () => (isMounted = false);
  }, []);


  const addNewUser = () => {
    usersRef
      .add({
        valor_pago_credito: valorMoeda ? valorMoeda  : 0,
        assinatura: null,
        creditos_disponiveis: 0,
        email: newUserEmail ? newUserEmail : null,
        foto_fundo: null,
        foto_perfil:
          "https://firebasestorage.googleapis.com/v0/b/locutores-comerciais.appspot.com/o/avatar-default.png?alt=media&token=77a4bd84-0ca8-420c-bd2f-d6e6e6a20d8c",
        nickname: null,
        nome: newUserName ? newUserName : null,
        sobrenome: newUserLastName ? newUserLastName : null,
        status: {
          icon_color: "text-success",
          nome: "Online",
        },
        funcao:
          typeof newUserFunctionType === "object" &&
          newUserFunctionType.hasOwnProperty("value")
            ? { default: newUserFunctionType }
            : newUserFunctionType
            ? { another: newUserFunctionType }
            : { default: { label: "Nenhum", value: "Nenhum" } },
        data_cadastro: firebase.firestore.FieldValue.serverTimestamp(),
        site_atuacao: defaultSiteActing
          ? { default: "Grupo Locutores Comerciais" }
          : newUserSiteActing
          ? { another: newUserSiteActing }
          : defaultSiteActing
          ? { default: "Grupo Locutores Comerciais" }
          : "Nenhum",
        cargo:
          typeof newUserCharge === "object" &&
          newUserCharge.hasOwnProperty("value")
            ? { default: newUserCharge }
            : newUserCharge
            ? { another: newUserCharge }
            : { default: { label: "Nenhum", value: "Nenhum" } },
        tipo_remuneracao:
          typeof newUserRemuneration === "object" &&
          newUserRemuneration.hasOwnProperty("value")
            ? { default: newUserRemuneration }
            : newUserRemuneration
            ? { another: newUserRemuneration }
            : { default: { label: "Nenhum", value: "Nenhum" } },
        tipo_contratacao:
          typeof newUserHiring === "object" &&
          newUserHiring.hasOwnProperty("value")
            ? { default: newUserHiring }
            : newUserHiring
            ? { another: newUserHiring }
            : { default: { label: "Nenhum", value: "Nenhum" } },
        permissoes: newUserPermissions
          ? newUserPermissions.length > 0
            ? newUserPermissions
            : defaultPermissions
          : defaultPermissions,
      })
      .then(() => {
        firebase
          .auth()
          .createUserWithEmailAndPassword(newUserEmail, newUserPass)
          .then(() => {
            setShowCreateUserPermissions(false);
          })
          .catch(() => {
            NotificationManager.error("Não foi possível criar uma nova conta!");
          });
        setShowCreateUser(false);
        NotificationManager.success("Novo usuário adicionado!");
      })
      .catch(() => {
        NotificationManager.error(
          "Não foi possível adicionar um novo usuário!"
        );
      });
  };

  const handlePasswordChange = (event) => {
    setNewUserPass(event.target.value);
    event.target.value.length < 6
      ? setPassError(
          <small className="text-danger">Pelo menos 6 caracteres.</small>
        )
      : setPassError(null);
  };

  const handleCfPasswordChange = (event) => {
    setCfPass(event.target.value);
    event.target.value !== newUserPass
      ? setCfpasswordError(
          <small className="text-danger">Senhas divergem.</small>
        )
      : setCfpasswordError(null);
  };

  const handleLoginEmail = (event) => {
    setNewUserEmail(event.target.value);
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    re.test(event.target.value) === false
      ? setEmailError(
          <small className="text-danger">
            O e-mail é obrigatório e o formato deve ser <i>john@doe.com</i>.
          </small>
        )
      : setEmailError(null);
  };

  const handleBackUserAccount = () => {
    setShowCreateUser(false);
    setShowCreateUserAccount(true);
  };

  const handleBackCreateUser = () => {
    setShowCreateUserPermissions(false);
    setShowCreateUser(true);
  };

  const generateKey = (pre) => {
    return `${pre}_${
      new moment().unix() + "" + Math.floor(Math.random() * 500)
    }`;
  };

  const handleSetUserPermissions = (name, active) => {
    var newArr = defaultPermissions;
    newArr.forEach((value, index) => {
      if (value.hasOwnProperty(name)) {
        newArr[index][name].active = active;
        setNewUserPermissions(newArr);
      }
    });
  };

  const exibirCampoValor = (event) => {
    if (event.value === "Locutor") {
      setTypeLocutor(true);
    } else {
      setTypeLocutor(false);
    }
    setNewUserCharge(event);
  };

  const formataMoeda = (valor) => {
    var v = valor.replace(/\D/g, "");
    v = (v / 100).toFixed(2) + "";
    v = v.replace(".", ",",);
    v = v.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
    v = v.replace(/(\d)(\d{3}),/g, "$1.$2,");
    setValorMoeda(v);
  };

  return (
    <ListaUsuarioStyle>
      <div className="main-content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title={
                  <>
                    Lista de usuários
                    <button
                      className="btn"
                      onClick={handleShowCreateUserAccount}
                    >
                      <i className="fa fa-user-plus" />
                      Criar usuário
                    </button>
                  </>
                }
                category="Todos os usuários"
                ctTableFullWidth
                ctTableResponsive
                content={
                  loading ? (
                    <BarLoader
                      css={override}
                      size={150}
                      color={"#123abc"}
                      loading={loading}
                    />
                  ) : messagesReceived.length > 0 ? (
                    <TableUser
                      columns={columns}
                      data={messagesReceived}
                    />
                  ) : (
                    <NoResult text="Lista vazia" />
                  )
                }
              />
            </Col>
          </Row>
        </Grid>
        <Modal show={showCreateUser} onHide={handleCloseCreateUser}>
          <Modal.Header closeButton>
            <Modal.Title style={{ display: "flex", alignItems: "center" }}>
              Continuar cadastro{" "}
              <i
                className="pe-7s-id"
                style={{ fontSize: "29px", marginLeft: "7px" }}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body scrollable>
            <Row>
              <FormGroup className="col-md-5">
                <ControlLabel
                  className="form-text"
                  style={{
                    fontWeight: 400,
                    textTransform: "uppercase",
                    fontSize: "12px",
                  }}
                >
                  Nome
                </ControlLabel>
                <FormControl
                  type="text"
                  placeholder="Nome"
                  value={newUserName}
                  className="form-control"
                  onChange={(e) => setNewUserName(e.target.value)}
                />
              </FormGroup>
              <FormGroup className="col-md-7">
                <ControlLabel
                  className="form-text"
                  style={{
                    fontWeight: 400,
                    textTransform: "uppercase",
                    fontSize: "12px",
                  }}
                >
                  Sobrenome
                </ControlLabel>
                <FormControl
                  type="text"
                  placeholder="Sobrenome"
                  value={newUserLastName}
                  onChange={(e) => setNewUserLastName(e.target.value)}
                />
              </FormGroup>
            </Row>
            <Row>
              <FormGroup className="col-md-12">
                <ControlLabel
                  className="form-text"
                  style={{
                    fontWeight: 400,
                    textTransform: "uppercase",
                    fontSize: "12px",
                  }}
                >
                  Função
                </ControlLabel>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  name="singleSelect"
                  value={newUserFunctionType}
                  onChange={(value) => setNewUserFunctionType(value)}
                  options={optionsUserFunction}
                  placeholder="Selecione uma função"
                />
                <div className="checkbox">
                  <input
                    id="anotherUserFunctionType"
                    type="checkbox"
                    defaultChecked={anotherUserFunctionType}
                    onChange={() =>
                      setAnotherUserFunctionType(!anotherUserFunctionType)
                    }
                  />
                  <label htmlFor="anotherUserFunctionType">Outro</label>
                </div>
                {anotherUserFunctionType && (
                  <FormControl
                    type="text"
                    placeholder="Digite aqui"
                    onChange={(e) => setNewUserFunctionType(e.target.value)}
                  />
                )}
              </FormGroup>
            </Row>
            <Row>
              <FormGroup className="col-md-12">
                <ControlLabel
                  className="form-text"
                  style={{
                    fontWeight: 400,
                    textTransform: "uppercase",
                    fontSize: "12px",
                  }}
                >
                  Cargo
                </ControlLabel>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  name="singleSelect"
                  value={newUserCharge}
                  onChange={(value) => exibirCampoValor(value)}
                  options={optionsUserCharge}
                  placeholder="Selecione um cargo"
                />
                {typeLocutor && (
                  <div style={styles.campoDiv}>
                    <p style={styles.campoReal}>R$</p>
                    <FormControl
                      style={styles.campoMoeda}
                      type="text"
                      value={valorMoeda}
                      placeholder="Valor pago por crédito"
                      onChange={(e) => formataMoeda(e.target.value)}
                    />
                  </div>
                )}
                <div className="checkbox">
                  <input
                    id="anotherUserCharge"
                    type="checkbox"
                    defaultChecked={anotherUserCharge}
                    onChange={() => setAnotherUserCharge(!anotherUserCharge)}
                  />
                  <label htmlFor="anotherUserCharge">Outro</label>
                </div>
                {anotherUserCharge && (
                  <FormControl
                    type="text"
                    placeholder="Digite aqui"
                    onChange={(e) => setNewUserCharge(e.target.value)}
                  />
                )}
              </FormGroup>
            </Row>
            <Row>
              <FormGroup className="col-md-12">
                <ControlLabel
                  className="form-text"
                  style={{
                    fontWeight: 400,
                    textTransform: "uppercase",
                    fontSize: "12px",
                  }}
                >
                  Tipo de remuneração
                </ControlLabel>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  name="singleSelect"
                  value={newUserRemuneration}
                  onChange={(value) => setNewUserRemuneration(value)}
                  options={optionsUserRemuneration}
                  placeholder="Selecione um tipo de remuneração"
                />
                <div className="checkbox">
                  <input
                    id="anotherRemuneration"
                    type="checkbox"
                    defaultChecked={anotherRemuneration}
                    onChange={() =>
                      setAnotherRemuneration(!anotherRemuneration)
                    }
                  />
                  <label htmlFor="anotherRemuneration">Outro</label>
                </div>
                {anotherRemuneration && (
                  <FormControl
                    type="text"
                    placeholder="Digite aqui"
                    onChange={(e) => setNewUserRemuneration(e.target.value)}
                  />
                )}
              </FormGroup>
            </Row>
            <Row>
              <FormGroup className="col-md-12">
                <ControlLabel
                  className="form-text"
                  style={{
                    fontWeight: 400,
                    textTransform: "uppercase",
                    fontSize: "12px",
                  }}
                >
                  Tipo de contratação
                </ControlLabel>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  name="singleSelect"
                  value={newUserHiring}
                  onChange={(value) => setNewUserHiring(value)}
                  options={optionsUserHiring}
                  placeholder="Selecione um tipo de contratação"
                />
                <div className="checkbox">
                  <input
                    id="anotherHiring"
                    type="checkbox"
                    defaultChecked={anotherHiring}
                    onChange={() => setAnotherHiring(!anotherHiring)}
                  />
                  <label htmlFor="anotherHiring">Outro</label>
                </div>
                {anotherHiring && (
                  <FormControl
                    type="text"
                    placeholder="Digite aqui"
                    onChange={(e) => setNewUserHiring(e.target.value)}
                  />
                )}
              </FormGroup>
            </Row>
            <Row>
              <FormGroup className="col-md-12">
                <ControlLabel
                  className="form-text col-md-12"
                  style={{
                    fontWeight: 400,
                    textTransform: "uppercase",
                    fontSize: "12px",
                    paddingLeft: 0,
                  }}
                >
                  Onde irá atuar?
                </ControlLabel>
                <Col>
                  <div className="checkbox">
                    <input
                      id="defaultActing"
                      type="checkbox"
                      defaultChecked={defaultSiteActing}
                      onChange={() => setDefaultSiteActing(!defaultSiteActing)}
                    />
                    <label htmlFor="defaultActing">
                      Grupo Locutores Comerciais
                    </label>
                  </div>
                  <div className="checkbox">
                    <input
                      id="anotherSiteActing"
                      type="checkbox"
                      defaultChecked={anotherSiteActing}
                      onChange={() => setAnotherSiteActing(!anotherSiteActing)}
                    />
                    <label htmlFor="anotherSiteActing">Outro</label>
                  </div>
                  {anotherSiteActing && (
                    <FormControl
                      type="text"
                      placeholder="Digite aqui"
                      value={newUserSiteActing}
                      onChange={(e) => setNewUserSiteActing(e.target.value)}
                    />
                  )}
                </Col>
              </FormGroup>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              bsStyle="info"
              fill
              type="button"
              onClick={handleBackUserAccount}
            >
              Voltar
            </Button>
            <Button
              bsStyle="danger"
              fill
              type="button"
              onClick={handleCloseCreateUser}
            >
              Fechar
            </Button>
            <Button
              bsStyle="success"
              pullRight
              fill
              disabled={newUserName ? false : true}
              type="button"
              onClick={handleShowCreateUserPermissions}
            >
              Próximo
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showCreateUserAccount}
          onHide={handleCloseCreateUserAccount}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Novo usuário <i className="fa fa-user" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <FormGroup className="col-md-12">
                <ControlLabel
                  className="form-text"
                  style={{
                    fontWeight: 400,
                    textTransform: "uppercase",
                    fontSize: "12px",
                  }}
                >
                  E-mail
                </ControlLabel>
                <FormControl
                  type="text"
                  placeholder="E-mail"
                  value={newUserEmail}
                  onChange={(e) => handleLoginEmail(e)}
                />
                {emailError}
              </FormGroup>
            </Row>
            <Row>
              <FormGroup className="col-md-12">
                <ControlLabel
                  className="form-text"
                  style={{
                    fontWeight: 400,
                    textTransform: "uppercase",
                    fontSize: "12px",
                  }}
                >
                  Senha: <span className="star">*</span>
                </ControlLabel>
                <FormControl
                  type="password"
                  placeholder="Senha"
                  value={newUserPass}
                  onChange={(event) => {
                    handlePasswordChange(event);
                  }}
                />
                {userPassError}
              </FormGroup>
            </Row>
            <Row>
              <FormGroup className="col-md-12">
                <ControlLabel
                  className="form-text"
                  style={{
                    fontWeight: 400,
                    textTransform: "uppercase",
                    fontSize: "12px",
                  }}
                >
                  Confirmar senha: <span className="star">*</span>
                </ControlLabel>
                <FormControl
                  type="password"
                  placeholder="Confirmar senha"
                  value={cfPass}
                  onChange={(event) => {
                    handleCfPasswordChange(event);
                  }}
                />
                {cfpasswordError}
              </FormGroup>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              bsStyle="danger"
              fill
              type="button"
              onClick={handleCloseCreateUserAccount}
            >
              Fechar
            </Button>
            <Button
              bsStyle="success"
              pullRight
              fill
              type="button"
              onClick={handleShowCreateUser}
              disabled={
                !userPassError && !cfpasswordError
                  ? newUserPass && cfPass && newUserEmail
                    ? false
                    : true
                  : true
              }
            >
              Próximo
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showCreateUserPermissions}
          onHide={handleCloseCreateUserPermissions}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Permissões <i className="pe-7s-key" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {defaultPermissions.map((value, index) => {
              return (
                <Row key={generateKey(value + index)}>
                  <FormGroup className="col-md-12" style={styles.gridAlignC}>
                    <ControlLabel
                      className="form-text"
                      style={{
                        fontWeight: 400,
                        textTransform: "uppercase",
                        fontSize: "12px",
                      }}
                    >
                      {Object.entries(value)[0][1].text}
                    </ControlLabel>
                    <div style={styles.permSwitch}>
                      <Switch
                        ey={generateKey(value + index)}
                        onChange={(e) =>
                          handleSetUserPermissions(e.props.name, e.state.value)
                        }
                        onText="✔"
                        offText="✘"
                        name={Object.entries(value)[0][0]}
                        defaultValue={Object.entries(value)[0][1].active}
                      />{" "}
                    </div>
                  </FormGroup>
                </Row>
              );
            })}
          </Modal.Body>
          <Modal.Footer>
            <Button
              bsStyle="info"
              fill
              type="button"
              onClick={handleBackCreateUser}
            >
              Voltar
            </Button>
            <Button
              bsStyle="danger"
              fill
              type="button"
              onClick={handleCloseCreateUserPermissions}
            >
              Fechar
            </Button>
            <Button
              bsStyle="success"
              pullRight
              fill
              type="button"
              onClick={addNewUser}
            >
              Finalizar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </ListaUsuarioStyle>
  );
};

export default withRouter(CaixaSaida);
