/*eslint-disable*/
import React, { Component, useState, useEffect } from "react";
import { useSelector, connect } from "react-redux";
import { Collapse } from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
// this is used to create scrollbars on windows devices like the ones from apple devices
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.jsx";

import routes from "routes.js";

import firebase from "../../config/firebase";
import "firebase/firestore";
import "firebase/storage";

var ps;

const User = () => {
  const [openAvatar, setOpenAvatar] = useState();
  const [user, setUser] = useState();
  const [userData, setUserData] = useState([]);

  const userEmailStore = useSelector((state) => state.userEmail);
  const userIdStore = useSelector((state) => state.userId);
  const userNameStore = useSelector((state) => state.userName);
  const userLastNameStore = useSelector((state) => state.userLastName);
  const userAvatarStore = useSelector((state) => state.userAvatar);
  const userRef = firebase.firestore().collection("users");

  useEffect(() => {
    userRef
      .where("email", "==", userEmailStore)
      .get()
      .then((snapshot) => {
        if (!snapshot.empty) {
          setUser(snapshot.docs[0].data());
        }
      });
    let query = userRef.where("email", "==", userEmailStore);
    query.onSnapshot((snapshot) => {
      snapshot.docChanges().map((doc) => {
        if (doc.type === "added") {
          let dataArray = [];
          snapshot.forEach((doc) =>
            dataArray.push({ ...doc.data(), id: doc.id })
          );
          setUserData(dataArray);
        }
        if (doc.type === "modified") {
          let dataArray = [];
          snapshot.forEach((doc) =>
            dataArray.push({ ...doc.data(), id: doc.id })
          );
          setUserData(dataArray);
        }
        if (doc.type === "removed") {
          let dataArray = [];
          snapshot.forEach((doc) =>
            dataArray.push({ ...doc.data(), id: doc.id })
          );
          setUserData(dataArray);
        }
      });
    });
  }, [firebase]);

  return (
    <div className="user">
      <div className="photo">
        <img
          src={
            userAvatarStore
              ? userAvatarStore
              : userData.length > 0
              ? userData[0].foto_perfil
                ? userData[0].foto_perfil
                : "https://firebasestorage.googleapis.com/v0/b/locutores-comerciais.appspot.com/o/avatar-default.png?alt=media&token=77a4bd84-0ca8-420c-bd2f-d6e6e6a20d8c"
              : "https://firebasestorage.googleapis.com/v0/b/locutores-comerciais.appspot.com/o/avatar-default.png?alt=media&token=77a4bd84-0ca8-420c-bd2f-d6e6e6a20d8c"
          }
          alt="Avatar"
        />
      </div>
      <div className="info">
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setOpenAvatar(!openAvatar);
          }}
        >
          <span>
            {userNameStore && userLastNameStore
              ? userNameStore + " " + userLastNameStore
              : userData && userData.length > 0
              ? userData[0].nome
              : user && user.nome}
            <b className={openAvatar ? "caret rotate-180" : "caret"} />
          </span>
        </a>
        <Collapse in={openAvatar}>
          <ul className="nav">
            <li>
              <Link to={`/admin/perfil-usuario/${userIdStore}`}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <i className="pe-7s-tools" />
                  <span className="sidebar-normal">Configurações</span>
                </div>
              </Link>
            </li>
          </ul>
        </Collapse>
      </div>
    </div>
  );
};

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.getCollapseStates(routes),
      openAvatar: false,
      isWindows: navigator.platform.indexOf("Win") > -1 ? true : false,
      width: window.innerWidth,
    };
  }
  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }
  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = (routes) => {
    var newRoutes = routes;
    var newNameRoute = routes;
    if (this.props.userTypeProp) {
      if (this.props.userTypeProp.hasOwnProperty("another")) {
        if (this.props.userTypeProp.another.value !== "Administrador") {
          newRoutes = route.filter((x) => x.name !== "Lista de usuários");
          this.props.userPermissionsProp.filter((perm) => {
            if (perm.hasOwnProperty("VerRelatorioLocutores")) {
              if (!perm.VerRelatorioLocutores.active) {
                newRoutes = routes.filter(
                  (x) => x.name !== "Relatório dos Locutores"
                );
              }
            }
          });
        }
      }
      if (this.props.userTypeProp.hasOwnProperty("default")) {
        if (this.props.userTypeProp.default.value !== "Administrador") {
          newRoutes = routes.filter((x) => x.name !== "Lista de usuários");
          this.props.userPermissionsProp.map((perm) => {
            if (perm.VerRelatorioLocutores) {
              if (!perm.VerRelatorioLocutores.active) {
                newRoutes = newRoutes.filter(
                  (x) => x.name !== "Relatório dos Locutores"
                );
                return newRoutes;
              }
            }
          });
          // console.log(filterPerm[4]);
        }
      }
    }
    if (this.props.userChargeProp) {
      if (this.props.userChargeProp.hasOwnProperty("another")) {
        if (this.props.userChargeProp.another.value !== "Gerente Comercial") {
          var d;
          var j;
          newNameRoute.filter((x) => {
            if (x.name === "Enviar pedido") {
              d = x.component;
            }
          });
          j = {
            component: d,
            icon: "pe-7s-note",
            layout: "/admin",
            name: "Enviar Mensagem",
            path: "/enviar-mensagem/:id/:type",
          };
          var h = newRoutes.findIndex((x) => x.name === "Enviar pedido");
          newRoutes[h] = j;
        }
      }
      if (this.props.userChargeProp.hasOwnProperty("default")) {
        if (this.props.userChargeProp.default.value !== "Gerente Comercial") {
          var d;
          var j;
          newNameRoute.filter((x) => {
            if (x.name === "Enviar pedido") {
              d = x.component;
            }
          });
          j = {
            component: d,
            icon: "pe-7s-note",
            layout: "/admin",
            name: "Enviar Mensagem",
            path: "/enviar-mensagem/:id/:type",
          };
          var h = newRoutes.findIndex((x) => x.name === "Enviar pedido");
          newRoutes[h] = j;
        }
      }
    }

    return newRoutes.map((prop, key) => {
      if (prop.invisible) return null;

      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !this.state[prop.state];
        return (
          <li
            className={this.getCollapseInitialState(prop.views) ? "active" : ""}
            key={key}
          >
            <a
              href="#"
              onClick={
                prop.name !== "Mensagens"
                  ? (e) => {
                      e.preventDefault();
                      this.setState(st);
                    }
                  : ""
              }
            >
              <i className={prop.icon} />
              <p>
                {prop.name}
                {prop.name !== "Mensagens" ? (
                  <b
                    className={
                      this.state[prop.state] ? "caret rotate-180" : "caret"
                    }
                  />
                ) : (
                  ""
                )}
              </p>
            </a>
            <Collapse in={this.state[prop.state]}>
              <ul className={`nav ${prop.name === "Mensagens" ? "in" : ""}`}>
                {this.createLinks(prop.views)}
              </ul>
            </Collapse>
          </li>
        );
      }
      return (
        <li className={this.activeRoute(prop.layout + prop.path)} key={key}>
          <Link
            to={prop.layout + prop.path}
            exact
            className="nav-link"
            activeClassName="active"
          >
            {prop.icon ? (
              <>
                <i className={prop.icon} />
                <p>{prop.name}</p>
              </>
            ) : (
              <>
                <span className="sidebar-mini">{prop.mini}</span>
                <span className="sidebar-normal">{prop.name}</span>
              </>
            )}
          </Link>
        </li>
      );
    });
  };
  // verifies if routeName is the one active (in browser input)
  activeRoute = (routeName) => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  // if the windows width changes CSS has to make some changes
  // this functions tell react what width is the window
  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }

  componentDidUpdate() {
    if (navigator.platform.indexOf("Win") > -1) {
      setTimeout(() => {
        ps.update();
      }, 350);
    }
  }
  componentDidMount() {
    this.updateDimensions();
    // add event listener for windows resize
    window.addEventListener("resize", this.updateDimensions.bind(this));
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }
  componentWillUnmount() {
    // we need to destroy the false scrollbar when we navigate
    // to a page that doesn't have this component rendered
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  render() {
    return (
      <div className="sidebar" data-color={this.props.color}>
        <div className="logo">
          <NavLink
            exact
            to="/admin/painel-de-controle"
            className="simple-text logo-mini"
          >
            <div className="logo-img">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/locutores-comerciais.appspot.com/o/logo-modified.png?alt=media&token=b5e25424-659e-4f92-b1a9-218a14465e54"
                alt="react-logo"
              />
            </div>
          </NavLink>
          {/* <NavLink
            exact
            to="/admin/painel-de-controle"
            className="simple-text logo-normal"
          >
            CMS
          </NavLink> */}
        </div>
        <div className="sidebar-wrapper" ref="sidebarWrapper">
          <User />
          <ul className="nav">
            {/*
              If we are on responsive, we want both links from navbar and sidebar
              to appear in sidebar, so we render here HeaderLinks
            */}
            {this.state.width <= 992 ? <AdminNavbarLinks /> : null}
            {/*
              here we render the links in the sidebar if the link is simple,
              we make a simple link, if not, we have to create a collapsible group,
              with the speciffic parent button and with it's children which are the links
            */}
            {this.createLinks(routes)}
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userTypeProp: state.userType,
  userChargeProp: state.userCharge,
  userPermissionsProp: state.userPermissions,
});

export default connect(mapStateToProps, null)(Sidebar);
