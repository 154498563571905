// eslint-disable-next-line react-hooks/exhaustive-deps
import * as React from "react";
import Timer from "./Timer";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import Slider from "react-rangeslider";
import SliderStyle from 'assets/css/components/SliderAudio';
import 'react-rangeslider/lib/index.css'
import MoonLoader from "react-spinners/MoonLoader";

const convertDataURIToBinary = (dataURI) => {
  const BASE64_MARKER = ";base64,";
  const base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
  const base64 = dataURI.substring(base64Index);
  const raw = window.atob(base64);
  const rawLength = raw.length;
  const array = new Uint8Array(new ArrayBuffer(rawLength));

  for (let i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i);
  }
  return array;
};

const PreviewRecord = (props) => {
  const { item, handleClick, audioInfo, view, downloadAudio, autoPlay } = props;
  const [active, setActive] = React.useState(false);
  const [playerVal, setPlayerVal] = React.useState(0);
  const [playerValNotFormated, setPlayerValNotFormated] = React.useState(0);
  const [player, setPlayer] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [duration, setDuration] = React.useState(0);
  const remove = <Tooltip id="remove">Excluir</Tooltip>;

  React.useEffect(() => {
    if (player) return;

    let blobUrl;
    if (!view) {
      const binary = convertDataURIToBinary(item);
      const blob = new Blob([binary], { type: "audio/ogg" });
      blobUrl = URL.createObjectURL(blob);
    }

    const temp = new Audio();
    temp.src = blobUrl ? blobUrl : item;
    temp.onended = (_ev) => {
      setActive(false);
      temp.currentTime = 0;
      setPlayerVal(0);
      setPlayerValNotFormated(0)
    };
    temp.onloadstart = (_ev) => setLoading(true);
    temp.oncanplaythrough = (_ev) => {
      setLoading(false);
      setDuration(temp.duration);
    };
    temp.ontimeupdate = (_ev) => {
      const progress = (temp.currentTime / temp.duration) * 100;
      if (temp.currentTime && temp.duration > 0) {
        setPlayerVal(progress);
        setPlayerValNotFormated(temp.currentTime);
      }
    };

    setPlayer(temp);
    return () => {
      temp.onended = null;
      temp.onloadstart = null;
      temp.oncanplaythrough = null;
      temp.ontimeupdate = null;
      temp.onerror = null;
      temp.src = null;
      setPlayer(null);
    };
  }, []); // eslint-disable-next-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (autoPlay) {
      if (!loading) {
        if (player) {
          setActive(!active);
          if (player.paused || player.ended) player.play();
        }
      }
    }
  }, [loading]);

  const _toggleActive = () => setActive(!active);

  const _handlePlayPause = (_ev) => {
    _toggleActive();
    if (player.paused || player.ended) player.play();
    else player.pause();
  };

  const _handleSliderChange = (_ev, value) => player.currentTime = _ev;

  return (
    <div
      className="ml-16"
      style={{
        display: "flex",
        alignItems: "center",
        borderRadius: "5px",
        padding: "4px 0",
        width: "200px"
      }}
    >
      {handleClick && (
        <OverlayTrigger placement="top" overlay={remove}>
          <i
            onClick={handleClick(false)}
            style={{ fontSize: "22px", color: "red", margin: "5px 15px 5px 0", cursor: "pointer" }}
            className="pe-7s-close-circle"
          />
        </OverlayTrigger>
      )}
      <div style={{ display: "flex", alignItems: "center" }}>
        {loading ? (
          <div style={{ margin: "5px 18px 5px 0" }}>
            <MoonLoader size={20} />
          </div>
        ) : active ? (
          <i
            onClick={_handlePlayPause}
            className="fa fa-pause"
            style={{ fontSize: 23, margin: "5px 18px 5px 0", cursor: "pointer" }}
          />
        ) : (
          <i
            className="fa fa-play"
            onClick={_handlePlayPause}
            style={{ fontSize: 23, margin: "5px 18px 5px 0", cursor: "pointer" }}
          />
        )}
        <SliderStyle>
          <Slider
            value={playerValNotFormated}
            orientation='horizontal'
            max={duration}
            min={0}
            onChange={_handleSliderChange}
          />
        </SliderStyle>
        <Timer
          duration={duration}
          currentTime={playerValNotFormated}
          active={active}
        />
      </div>
      {view === "Message" && (
        <i
          style={{
            fontSize: "18px",
            marginLeft: "5px",
            cursor: "pointer"
          }}
          className="fa fa-download"
          onClick={downloadAudio(audioInfo.filename)}
        />
      )}
      <i className="fa fa-check text-success" style={{ fontSize: '20px', marginLeft: '5px' }}/>
    </div>
  );
};

export default PreviewRecord;
