import React from "react";
import { Table } from "react-bootstrap";
import { Redirect } from 'react-router-dom';

const RecentMessages = ({messages}) => {
  const [idMessage, getIdMessage] = React.useState();
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    if (!messages) return;
    setData(messages);
  }, [messages, data]);
  return (
    <Table striped hover responsive>
        {idMessage && <Redirect to={`/pedidos/enviar-mensagem/${idMessage}/resp`}/>}
        <thead>
          <tr>
            <th>Nome</th>
            <th>Assunto</th>
          </tr>
        </thead>
        <tbody>
          {
            data.length > 0 &&
              data.map((message) => {
                return (
                <tr onClick={() => getIdMessage(message.id)} role="button" key={message.id}>
                  <td>{message.de.nome}</td>
                  <td>{message.assunto}</td>
                </tr>
              )})
          }

        </tbody>
      </Table>
  )
}

export default RecentMessages;